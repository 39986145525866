import axios from "axios";
import { TOKEN_KEY } from "src/consts";

export type ApiResponse<T> = {
  code: number;
  msg: string;
  data: T;
  requestId: string;
};

const API_URL = process.env.REACT_APP_API_URL!;

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem(TOKEN_KEY),
  },
});

api.interceptors.request.use(
  function (config) {
    // go to login page if token is not set and the current page is not login
    if (!localStorage.getItem(TOKEN_KEY) && !window.location.href.includes("/login")) {
      window.location.href = "/login";
    }
    // if (config.method === "get" || config.method === "post") {
    //   config.params = {
    //     ...config.params,
    //     take: 1000,
    //     skip: 0,
    //   }
    // }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const NOT_LOGIN_REASON1 = "登录过期or未登录";
const NOT_LOGIN_REASON2 = "AUTH_INVALID_TOKEN";
api.interceptors.response.use(
  function (config) {
    console.log("interceptors response config is: ", config);

    const code = config.data.code;
    const reason = config.data.reason;
    console.log("interceptors response reason is: ", reason);

    if (reason === NOT_LOGIN_REASON1 || reason === NOT_LOGIN_REASON2) {
      window.location.href = "/login";
    }
    if (code !== undefined && Number(code) !== 0) {
      return Promise.reject({
        code: config.data.code,
        message: config.data.msg,
        reason: config.data.reason,
      });
    }
    return config;
  },
  function (error) {
    console.error("interceptors ERROR response  is: ", error);
    const reason = error.response.data?.error?.reason;
    console.error("interceptors ERROR reason  is: ", reason);

    // Do something with 403/400/401 error
    if (reason === NOT_LOGIN_REASON1 || reason === NOT_LOGIN_REASON2) {
      window.location.href = "/login";
    }

    // Optionally, you can reject the request here so it does not continue
    return Promise.reject(error);
  }
);
