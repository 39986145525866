import { Flex } from "@chakra-ui/react";
import CInput from "../CInput/CInput";

export default function CommonDepthBidAndOfferForm(props: { styles: any }) {
  const { styles } = props;

  return (
    <>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput classname={styles.inputField} label="策略名称" name="strategyName" />
      </Flex>
      <Flex>
        <CInput
          classname={styles.inputField}
          label="价1的价差比例最小值"
          name="minDiffInPrice9"
          type="number"
          description="20%"
          rightIndicator="%"
        />
      </Flex>
      <Flex>
        <CInput
          classname={styles.inputField}
          label="价1的价差比例最大值"
          name="maxDiffInPrice9"
          type="number"
          description="30%，那么摆盘会在这个价格范围内随机定价"
          rightIndicator="%"
        />
      </Flex>
      <Flex>
        <CInput
          classname={styles.inputField}
          label="币下单总数量"
          name="totalBaseAmount9"
          type="number"
          description="币下单总数量"
        />
      </Flex>
      <Flex>
        <CInput
          classname={styles.inputField}
          label="挂单数量"
          name="orderNum9"
          type="number"
          description="每个挂单的数量会随机，但是加起来等于（币下单总数量）"
        />
      </Flex>
    </>
  );
}
