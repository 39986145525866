import { setToken } from "src/store/utils";
import { api, ApiResponse } from "./base";
import { AuthResponse } from "./types";

export const login = async (username: string, password: string) => {
  const res = await api.post<ApiResponse<AuthResponse>>("/auth/login", {
    username,
    password,
  });
  return res.data.data;
};

export const requestToken = async () => {
  const res = await api.get<ApiResponse<AuthResponse>>("/auth/token");
  const newToken = res.data.data.token;
  if (!newToken) {
    console.log("Token is empty");
  }
  console.log("New token", newToken);
  setToken(newToken);
  return newToken;
};
