import { Flex, Icon, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { VscSearch } from "react-icons/vsc";
import styles from "./index.module.scss";
import CButton from "src/component/CButton/CButton";
import CTable from "src/component/CTable/Ctable";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { accountApi, adminApi } from "src/api";
import { useExchangeAccounts } from "src/hook/useExchangeAccounts";
import { useExchangeInfoStore } from "src/store";
import { getPairName } from "src/store/utils";

const cols = [
  { key: "id", title: "ID" },
  { key: "accountName", title: "账户名称" },
  { key: "tradingExchange", title: "交易所" },
  { key: "defaultPair", title: "默认交易对" },
  { key: "status", title: "状态" },
];

export default function Accounts() {
  const navigate = useNavigate();
  const store = useExchangeInfoStore();
  const EXCHANGES = store.exchanges;

  const userExhangeAccountsQuery = useQuery({
    queryKey: ["userExhangeAccounts", "all"],
    queryFn: async () => {
      const exchangeAccounts = await adminApi.fetchExchangeAccounts();
      const exchangeAccountIds = exchangeAccounts.map((exchangeAccount) => exchangeAccount.id);
      const pairQueries = exchangeAccountIds.map((exchangeAccountId) => {
        return adminApi.fetchExchangeAccountPairs(exchangeAccountId);
      });
      const pairResults = await Promise.all(pairQueries);
      return exchangeAccounts.map((exchangeAccount, index) => {
        return {
          ...exchangeAccount,
          pairs: pairResults[index].map((pair) => getPairName(pair.base, pair.quote)),
        };
      });
    },
  });

  const items = (userExhangeAccountsQuery.data || []).map((item) => ({
    id: item.id,
    accountName: item.alias,
    accountType: "",
    tradingExchange: EXCHANGES.find((exchange) => exchange.id === item.exchangeId)?.name || "",
    defaultPair: item.pairs.join(","),
    status: item.isActive ? "active" : "inactive",
  }));

  const handleSearch = () => {
    console.log("search");
  };
  const handleAdd = () => {
    navigate("/exchange-accounts/create");
  };

  const operationCol = (item) => {
    return (
      <Flex>
        <CButton
          colorScheme="red"
          name={"删除"}
          onClick={async () => {
            console.log("delete", item);
            await accountApi.deleteExchangeAccount({ id: item.id });
            userExhangeAccountsQuery.refetch();
          }}
        />
      </Flex>
    );
  };
  return (
    <Flex width="100%" flexDirection="column">
      <Flex width="100%" justifyContent="space-between" height="40px" alignItems="end">
        <Flex className={styles.header}>
          <InputGroup mt={4}>
            <InputLeftElement color="gray.300" fontSize="1.2em" pl={0} onClick={handleSearch}>
              <Icon as={VscSearch} boxSize="30px" cursor="pointer" />
            </InputLeftElement>
            <Input border="none" _hover={{ border: "none" }} pl={12} placeholder="搜索账户" />
          </InputGroup>
          <CButton name={"搜索"} />
        </Flex>
        <CButton name={"添加交易账户"} onClick={handleAdd} />
      </Flex>

      <Flex width="100%" height="100%" px={4} py={4}>
        <CTable classname={styles.tableContainer} items={items} cols={cols} showHeader operationCol={operationCol} />
      </Flex>
    </Flex>
  );
}
