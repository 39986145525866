import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Input,
  Flex,
  Text,
  Box,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import { useMessage } from "src/hook/useMessage";
import { useExchangeInfoStore } from "src/store";
import { ubPerpApi } from "src/api";
import { FetchPositionResponse } from "src/api/types";
import Slider from "rc-slider"; // 使用 rc-slider 实现
import "rc-slider/assets/index.css"; // 引入 rc-slider 样式

type ClosePositionAlertProps = {
  positionItem: FetchPositionResponse | null;
  isOpen: boolean;
  onClose: () => void;
  submitForm: (payload: { amount: number }) => void;
};

export default function ClosePositionAlert(props: ClosePositionAlertProps) {
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const message = useMessage();
  const store = useExchangeInfoStore();
  const { selectedExchangeKey } = store;
  const selectedExchangeAccountId = useExchangeInfoStore((state) => state.selectedExchangeAccountId);
  const { isOpen, onClose, positionItem } = props;
  const positionSize = positionItem?.size || 0;
  const [amount, setAmount] = useState<string>((positionSize / 2).toString());
  const [price, setPrice] = useState<string>("");
  const [isMarketPrice, setIsMarketPrice] = useState(false);

  // 获取 position.size

  // 监听 amount 变化，确保输入框和 slider 同步
  useEffect(() => {
    if (positionSize && parseFloat(amount) > positionSize) {
      setAmount(positionSize.toString());
    }
  }, [amount, positionSize]);

  const handleClose = () => {
    setAmount("");
    setPrice("");
    setIsMarketPrice(false);
    onClose();
  };

  const handleClosePosition = async () => {
    try {
      if (!positionItem) {
        message.detailedError(<span>Position item is null</span>);
        return;
      }

      const parsedAmount = parseFloat(amount);
      const parsedPrice = isMarketPrice ? 0 : parseFloat(price);

      if (isNaN(parsedAmount) || (parsedPrice === 0 && !isMarketPrice && price !== "")) {
        message.detailedError(<span>请输入有效的数量和价格</span>);
        return;
      }

      const param = {
        exchangeAccountId: selectedExchangeAccountId,
        exchangeName: selectedExchangeKey,
        symbol: positionItem.symbol,
        closePrice: parsedPrice,
        amount: parsedAmount,
        side: positionItem.side,
      };
      console.log("handleClosePosition:", param);
      await ubPerpApi.requestClosePosition(param);
      message.success("平仓成功");
      handleClose();
    } catch (error) {
      message.detailedError(<span>{error instanceof Error ? error.message : "An error occurred"}</span>);
    }
  };

  // 当 slider 变化时，直接更新 amount
  const handleSliderChange = (value: number) => {
    const numericValue = typeof value === "number" ? value : parseFloat(value);

    // 如果转换后的值无效（NaN），则不进行更新
    if (isNaN(numericValue)) return;

    setAmount(numericValue.toFixed(0)); // 保持两位小数
  };

  // 计算百分比的 marks
  const marks = {
    [positionSize / 4]: "25%",
    [positionSize / 2]: "50%",
    [(positionSize * 3) / 4]: "75%",
  };

  return (
    <>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={handleClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              平仓
            </AlertDialogHeader>

            <AlertDialogBody>
              <Flex flexDir="column" gap={4} width={"100%"}>
                <Flex justifyContent={"center"}>仓位: {positionSize}</Flex>
                <Flex alignItems="flex-start" flexDir={"column"} width={"400px"}>
                  <Flex justifyContent={"space-between"} gap={2} width={"400px"}>
                    <Slider
                      min={0}
                      max={positionSize} // max 为 position.size
                      value={parseFloat(amount)}
                      onChange={handleSliderChange} // 更新 amount
                      step={1}
                      marks={marks} // 显示百分比标记
                      defaultValue={positionSize}
                    />
                    <Input
                      placeholder="张数"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      type="number"
                      step="any"
                      width={"30%"}
                    />
                  </Flex>
                </Flex>

                <Flex alignItems="center">
                  <Text width="180px">请输入价格</Text>
                  <Input
                    placeholder="请输入价格, 不输入代表市价平仓"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    type="number"
                    step="any"
                    disabled={isMarketPrice}
                  />
                </Flex>
              </Flex>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={handleClose}>
                取消
              </Button>
              <Button onClick={handleClosePosition} ml={3} colorScheme="red">
                确定
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
