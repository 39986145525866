import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Input,
  InputGroup,
  InputRightAddon,
  Flex,
  Text,
  FormControl,
  FormErrorMessage,
  VStack,
  Tooltip,
  InputLeftAddon,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ubPerpApi } from "src/api";
import { FetchPositionResponse } from "src/api/types";
import { useMessage } from "src/hook/useMessage";
import { useExchangeInfoStore } from "src/store";

type ClosePositionAlertProps = {
  positionItem: FetchPositionResponse | null;
  isOpen: boolean;
  onClose: () => void;
  submitForm: (payload: { amount: number }) => void;
  marketPrice: string;
};

type PriceAndProfitType = {
  price: string;
  percentage: string;
};

export default function UpdateTPAndSL(props: ClosePositionAlertProps) {
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const message = useMessage();
  const store = useExchangeInfoStore();
  const { selectedExchangeKey, selectedUbPerpPair: selectedPair } = store;
  const selectedExchangeAccountId = useExchangeInfoStore((state) => state.selectedExchangeAccountId);

  const defaultPrice: PriceAndProfitType = {
    price: "",
    percentage: "",
  };

  const { isOpen, onClose, positionItem, marketPrice } = props;
  const [tpPrice, setTpPrice] = useState<PriceAndProfitType>(defaultPrice);
  const [slPrice, setSlPrice] = useState<PriceAndProfitType>(defaultPrice);
  const side = positionItem?.side?.toLowerCase();
  const positionSide = side === "buy" ? "多头" : "空头";

  // **Error States**
  const [tpError, setTpError] = useState<string | null>(null);
  const [slError, setSlError] = useState<string | null>(null);

  // **Loading State**
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClose = () => {
    setTpPrice(defaultPrice);
    setSlPrice(defaultPrice);
    setTpError(null);
    setSlError(null);
    onClose();
  };

  const handleUpdateTPAndSL = async (): Promise<boolean> => {
    if (isLoading) return false; // 防止重复提交
    setIsLoading(true); // 开始加载
    try {
      if (!positionItem) {
        message.error("Position item is not available.");
        return false;
      }

      const marketPriceNum = Number(marketPrice);
      const isLong = positionSide === "多头";

      // 构建参数，只包含用户填写的字段
      const param: any = {
        exchangeAccountId: selectedExchangeAccountId,
        exchangeName: selectedExchangeKey,
        symbol: positionItem.symbol,
        side: positionItem.side, // 新增的 side 属性
      };

      // 如果 TP 填写了，添加到参数中
      if (tpPrice.price.trim()) {
        const tpPriceNum = parseFloat(tpPrice.price);
        if (isNaN(tpPriceNum) || tpPriceNum <= 0) {
          message.error("请输入有效的止盈价格。"); // "Please enter a valid take profit price."
          setTpError("请输入有效的止盈价格。");
          return false;
        }

        if (isLong && tpPriceNum <= marketPriceNum) {
          setTpError("止盈价格必须大于市场价格。");
          return false;
        } else if (!isLong && tpPriceNum >= marketPriceNum) {
          setTpError("止盈价格必须小于市场价格。");
          return false;
        }

        param.tpPrice = tpPriceNum;
      }

      // 如果 SL 填写了，添加到参数中
      if (slPrice.price.trim()) {
        const slPriceNum = parseFloat(slPrice.price);
        if (isNaN(slPriceNum) || slPriceNum <= 0) {
          message.error("请输入有效的止损价格。"); // "Please enter a valid stop loss price."
          setSlError("请输入有效的止损价格。");
          return false;
        }

        if (isLong && slPriceNum >= marketPriceNum) {
          setSlError("止损价格必须小于市场价格。");
          return false;
        } else if (!isLong && slPriceNum <= marketPriceNum) {
          setSlError("止损价格必须大于市场价格。");
          return false;
        }

        param.slPrice = slPriceNum;
      }

      if (!param.tpPrice && !param.slPrice) {
        message.error("请至少填写止盈或止损中的一个。"); // "Please fill in at least one of take profit or stop loss."
        return false;
      }

      await ubPerpApi.requestSetTpSl(param); // 更新的 API 请求方法
      message.success("止盈止损更新成功"); // "Take Profit and Stop Loss updated successfully."
      return true; // 表示成功
    } catch (error: any) {
      // 使用 'any' 以适应不同的错误类型
      // **提取有意义的错误消息**
      let errorMsg = "发生未知错误。"; // "An unknown error occurred."

      if (error.response && error.response.data && typeof error.response.data.message === "string") {
        errorMsg = error.response.data.message;
      } else if (typeof error.message === "string") {
        errorMsg = error.message;
      } else {
        // 如果 errorMsg 仍然是对象，则字符串化它
        errorMsg = JSON.stringify(error);
      }

      console.log("Extracted error message type:", typeof errorMsg);
      console.log("Extracted error message content:", errorMsg);

      // **确保 errorMsg 是字符串**
      if (typeof errorMsg !== "string") {
        errorMsg = "发生未知错误。"; // 备用消息
      }

      // **显示错误消息**
      message.error(errorMsg);
      return false; // 表示失败
    } finally {
      setIsLoading(false); // 结束加载
    }
  };

  const handleSetPrice = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    let rawValue = e.target.value;

    // **移除前导零，除非以 "0." 开头**
    if (rawValue.startsWith("0") && !rawValue.startsWith("0.")) {
      rawValue = rawValue.replace(/^0+/, "");
      // 如果全部移除，则设置为 "0"
      if (rawValue === "") {
        rawValue = "0";
      }
    }

    // **允许输入为空字符串**
    if (rawValue === "") {
      if (type.includes("tp")) {
        setTpPrice({ price: "", percentage: "" });
        setTpError(null);
      } else {
        setSlPrice({ price: "", percentage: "" });
        setSlError(null);
      }
      return;
    }

    // **验证输入是否为有效数字**
    const val = Number(rawValue);
    if (isNaN(val) && rawValue !== "0") {
      if (type.includes("tp")) {
        setTpError("请输入有效的数字。"); // "Please enter a valid number."
      } else {
        setSlError("请输入有效的数字。");
      }
      return;
    } else {
      if (type.includes("tp")) {
        setTpError(null);
      } else {
        setSlError(null);
      }
    }

    if (!positionItem) {
      message.error("Position item is not available.");
      return;
    }

    let temp: PriceAndProfitType = {
      price: "",
      percentage: "",
    };

    const setFunc = type.includes("tp") ? setTpPrice : setSlPrice;
    const setErrorFunc = type.includes("tp") ? setTpError : setSlError;
    let error: string | null = null;

    const marketPriceNum = Number(marketPrice);
    const leverage = Number(positionItem.leverage);
    const isLong = positionSide === "多头";
    const openPrice = Number(positionItem.entry_price);

    switch (type) {
      case "tpPrice":
        temp.price = rawValue;
        temp.percentage = isLong
          ? (((val - openPrice) * 100 * leverage) / openPrice).toFixed(2)
          : (((openPrice - val) * 100 * leverage) / openPrice).toFixed(2);

        if (isLong && val <= marketPriceNum) {
          error = "止盈价格必须大于市场价格。"; // "Take Profit price must be greater than the market price."
        } else if (!isLong && val >= marketPriceNum) {
          error = "止盈价格必须小于市场价格。"; // "Take Profit price must be less than the market price."
        }
        break;

      case "tpPercent":
        temp.percentage = rawValue;
        temp.price = isLong
          ? (openPrice * (1 + val / (100 * leverage))).toFixed(2)
          : (openPrice * (1 - val / (100 * leverage))).toFixed(2);

        // **Validation for TP Percentage**
        const tpCalculatedPrice = isLong
          ? openPrice * (1 + val / (100 * leverage))
          : openPrice * (1 - val / (100 * leverage));

        if (isLong && tpCalculatedPrice <= marketPriceNum) {
          error = "止盈价格必须大于市场价格。";
        } else if (!isLong && tpCalculatedPrice >= marketPriceNum) {
          error = "止盈价格必须小于市场价格。";
        }
        break;

      case "slPrice":
        temp.price = rawValue;
        temp.percentage = isLong
          ? (((openPrice - val) * 100 * leverage) / openPrice).toFixed(2)
          : (((val - openPrice) * 100 * leverage) / openPrice).toFixed(2);

        // **Validation for SL Price**
        if (isLong) {
          if (val <= Number(positionItem.liq_price)) {
            error = "止损价格必须大于强平价格。"; // "Stop Loss price must be greater than the liquidation price."
          } else if (val >= marketPriceNum) {
            error = "止损价格必须小于市场价格。"; // "Stop Loss price must be less than the market price."
          }
        } else {
          if (val >= Number(positionItem.liq_price)) {
            error = "止损价格必须小于强平价格。"; // "Stop Loss price must be less than the liquidation price."
          } else if (val <= marketPriceNum) {
            error = "止损价格必须大于市场价格。"; // "Stop Loss price must be greater than the market price."
          }
        }
        break;

      case "slPercent":
        temp.percentage = rawValue;
        temp.price = isLong
          ? (openPrice * (1 - val / (100 * leverage))).toFixed(2)
          : (openPrice * (1 + val / (100 * leverage))).toFixed(2);

        // **Validation for SL Percentage**
        const slCalculatedPrice = isLong
          ? openPrice * (1 - val / (100 * leverage))
          : openPrice * (1 + val / (100 * leverage));

        if (isLong && slCalculatedPrice >= marketPriceNum) {
          error = "止损价格必须小于市场价格。";
        } else if (!isLong && slCalculatedPrice <= marketPriceNum) {
          error = "止损价格必须大于市场价格。";
        }
        break;

      default:
        console.warn(`Unhandled type: ${type}`);
        return;
    }

    // **防止输入负数或零**
    if (val < 0) {
      error = "价格和百分比必须大于零。"; // "Price and percentage must be greater than zero."
    }

    // **设置错误状态**
    setErrorFunc(error);

    // **设置价格和百分比**
    setFunc(temp);
  };

  // **计算是否禁用提交按钮**
  const isSubmitDisabled = (!tpPrice.price.trim() && !slPrice.price.trim()) || !!tpError || !!slError || isLoading;

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={handleClose} isCentered size="2xl">
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            止盈止损
          </AlertDialogHeader>

          <AlertDialogBody>
            <VStack spacing={4}>
              {/* 显示持仓方向和开仓价格 */}
              {positionItem && (
                <>
                  <Text fontSize="md" color="gray.500">
                    当前持仓: {positionSide}
                  </Text>
                  <Text fontSize="sm" color="gray.500">
                    开仓价格: {positionItem.entry_price}
                  </Text>
                </>
              )}

              {/* 止盈部分 */}
              <FormControl isInvalid={!!tpError}>
                <Flex alignItems="center" gap={2}>
                  <Text width="180px">止盈价格</Text>
                  <InputGroup size="sm">
                    <Input
                      placeholder="止盈价格"
                      value={tpPrice.price}
                      onChange={(e) => handleSetPrice(e, "tpPrice")}
                      type="text" // 改为 text
                      aria-label="Take Profit Price"
                    />
                    <InputRightAddon>U</InputRightAddon>
                  </InputGroup>

                  <InputGroup size="sm">
                    <InputLeftAddon>+</InputLeftAddon>
                    <Input
                      placeholder="止盈百分比"
                      value={tpPrice.percentage}
                      onChange={(e) => handleSetPrice(e, "tpPercent")}
                      type="text" // 改为 text
                      aria-label="Take Profit Percentage"
                    />
                    <InputRightAddon>%</InputRightAddon>
                  </InputGroup>
                </Flex>
                {tpError && <FormErrorMessage>{tpError}</FormErrorMessage>}
              </FormControl>

              {/* 止损部分 */}
              <FormControl isInvalid={!!slError}>
                <Flex alignItems="center" gap={2}>
                  <Text width="180px">止损价格</Text>
                  <InputGroup size="sm">
                    <Input
                      placeholder="止损价格"
                      value={slPrice.price}
                      onChange={(e) => handleSetPrice(e, "slPrice")}
                      type="text" // 改为 text
                      aria-label="Stop Loss Price"
                    />
                    <InputRightAddon>U</InputRightAddon>
                  </InputGroup>

                  <InputGroup size="sm">
                    <InputLeftAddon>-</InputLeftAddon>
                    <Input
                      placeholder="止损百分比"
                      value={slPrice.percentage}
                      onChange={(e) => handleSetPrice(e, "slPercent")}
                      type="text" // 改为 text
                      aria-label="Stop Loss Percentage"
                    />
                    <InputRightAddon>%</InputRightAddon>
                  </InputGroup>
                </Flex>
                {slError && <FormErrorMessage>{slError}</FormErrorMessage>}
              </FormControl>
            </VStack>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={handleClose}>
              取消
            </Button>
            <Tooltip
              label={isSubmitDisabled ? "请确保至少填写一个字段且符合验证规则。" : ""}
              isDisabled={!isSubmitDisabled}
            >
              <span>
                <Button
                  onClick={async () => {
                    const success = await handleUpdateTPAndSL();
                    if (success) {
                      handleClose();
                    }
                  }}
                  ml={3}
                  colorScheme="blue"
                  isDisabled={isSubmitDisabled} // 禁用按钮如果加载中或有错误
                  isLoading={isLoading} // 显示加载指示器
                >
                  确定
                </Button>
              </span>
            </Tooltip>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
