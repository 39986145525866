import { api, ApiResponse } from "./base";
import { CreateExchangeAccountParams, CreateExchangeAccountResponse, ExchangeResponse, PairResponse, UpdateExchangeAccountParams } from "./types";

const fetchExchanges = async () => {
  const res = await api.get<ApiResponse<ExchangeResponse>>("/exchanges");
  return res.data.data;
};

const fetchPairs = async () => {
  const res = await api.get<ApiResponse<PairResponse>>("/pairs");
  return res.data.data;
};

const createExchangeAccount = async (payload: CreateExchangeAccountParams) => {
  const res = await api.post<ApiResponse<CreateExchangeAccountResponse>>("/exchangeAccounts", payload);
  return res.data.data;
};

const updateExchangeAccount = async (payload: UpdateExchangeAccountParams) => {
  const res = await api.post<ApiResponse<CreateExchangeAccountResponse>>("/exchangeAccounts", payload);
  return res.data.data;
};

export { fetchExchanges, fetchPairs, createExchangeAccount, updateExchangeAccount };
