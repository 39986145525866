import { Flex } from "@chakra-ui/react";
import CInput from "../CInput/CInput";

export default function CommonLowBuyHighSellForm(props: { styles: any }) {
  const { styles } = props;

  return (
    <>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput classname={styles.inputField} label="策略名称" name="strategyName" />
      </Flex>
      {/* <Flex alignItems="center" gap={2} mt={4}>
        <CInput
          classname={styles.inputField}
          label="间隔"
          name="interval"
          description={`k线数据，选"1m 5m 15m 30m" 其中一个`}
        />
      </Flex> */}
      {/* buyAmount: number;
  buyCount: number;
  dropPerOfBenefits: number;
  perOfBenefits: number; */}
      <Flex>
        <CInput classname={styles.inputField} label="购买总金额" name="buyAmount" type="number" />
      </Flex>
      {/* <Flex>
        <CInput
          classname={styles.inputField}
          label="购买次数"
          name="buyCount"
          type="number"
          description="每一单的金额是购买总金额的平均"
        />
      </Flex> */}
      <Flex>
        <CInput
          classname={styles.inputField}
          label="下跌百分比"
          name="dropPerOfBenefits"
          type="number"
          description="当前价格低于 上一个蜡烛图的最低价格*下跌百分比，执行买入"
          rightIndicator="%"
        />
      </Flex>
      <Flex>
        <CInput
          classname={styles.inputField}
          label="收益百分比"
          name="perOfBenefits"
          type="number"
          description="当前价格 > (平均成本价格 * 收益百分比) 时 执行卖出"
          rightIndicator="%"
        />
      </Flex>
      <Flex>
        <CInput
          classname={styles.inputField}
          label="执行轮次"
          name="round4"
          type="number"
          description="就是一共执行多少次买卖周期，执行完后停止"
        />
      </Flex>

      <Flex>
        <CInput
          classname={styles.inputField}
          label="触发价格(低值)"
          name="priceLow4"
          type="number"
          description="处于价格区间，才会执行提交买入挂单"
        />
      </Flex>
      <Flex>
        <CInput
          classname={styles.inputField}
          label="触发价格(高值)"
          name="priceHigh4"
          type="number"
          description="处于价格区间，才会执行提交买入挂单"
        />
      </Flex>

      <Flex>
        <CInput classname={styles.inputField} label="重复间隔(最小值)" name="repeatIntervalMsMin" type="number" />
      </Flex>

      <Flex>
        <CInput classname={styles.inputField} label="重复间隔(最大值)" name="repeatIntervalMsMax" type="number" />
      </Flex>
    </>
  );
}
