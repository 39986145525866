import { Flex } from "@chakra-ui/react";
import CInput from "../CInput/CInput";

export default function CommonEatOrderForm(props: { styles: any }) {
  const { styles } = props;
  return (
    <>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput classname={styles.inputField} label="策略名称" name="strategyName" type="text" />
      </Flex>
      <Flex>
        <CInput classname={styles.inputField} label="吃前N个单" name="topN" type="number" />
      </Flex>
      <Flex>
        <CInput
          classname={styles.inputField}
          label="吃前N个单金额总和百分比(1~100)"
          name="topNEatRatio"
          rightIndicator="%"
          type="number"
        />
      </Flex>
      <Flex>
        <CInput classname={styles.inputField} label="吃单策略任务总金额上限" name="eatTotalAmount" type="number" />
      </Flex>
      <Flex>
        <CInput
          classname={styles.inputField}
          label="单次吃单任务触发的总额度范围成交范围（最小值）（单位USDT）"
          name="rangeAmountMinPer"
          type="number"
        />
      </Flex>
      <Flex>
        <CInput
          classname={styles.inputField}
          label="单次吃单任务触发的总额度范围成交范围（最大值）（单位USDT）"
          name="rangeAmountMaxPer"
          type="number"
        />
      </Flex>
      <Flex>
        <CInput classname={styles.inputField} label="每次吃单分几笔成交" name="orderNumberPer" type="number" />
      </Flex>
      <Flex>
        <CInput classname={styles.inputField} label="分笔吃单间隔(ms)" name="orderIntervalMsPer" type="number" />
      </Flex>
      <Flex>
        <CInput classname={styles.inputField} label="交易价格线" name="eatPriceTrigger" type="number" />
      </Flex>
        <Flex>
        <CInput classname={styles.inputField} label="重复间隔(最小值)" name="repeatIntervalMsMin" type="number" />
      </Flex>

      <Flex>
        <CInput classname={styles.inputField} label="重复间隔(最大值)" name="repeatIntervalMsMax" type="number" />
      </Flex>
    </>
  );
}
