import { Divider, Flex, Text } from "@chakra-ui/react";
import CTable from "../CTable/Ctable";
import styles from "./OrderBook.module.scss";
import { WsResponseOrderBook } from "src/api/ws";
import { pro } from "ccxt";
type OrderBookItem = {
  price: number;
  amount: number;
};
const cols = [
  {
    key: "price",
    title: "价格",
  },
  {
    key: "amount",
    title: "数量",
  },
  {
    key: "sum",
    title: "金额",
  },
];

const OrderBook = (props: { info: WsResponseOrderBook }) => {
  console.log("props", props);

  const asks: OrderBookItem[] = props.info.asks.map((item) => {
    return {
      price: item[0],
      amount: item[1],
      sum: item[2].toFixed(2),
    };
  });
  const bids: OrderBookItem[] = props.info.bids.map((item) => {
    return {
      price: item[0],
      amount: item[1],
      sum: item[2].toFixed(2),
    };
  });
  return (
    <Flex flexWrap="wrap">
      <Flex justifyContent="space-around" my={4} width="100%">
        <CTable items={bids} cols={cols} showHeader classname={styles.ask} />
        <Divider orientation="vertical" height="100%" mx={4} />
        <CTable items={asks} cols={cols} showHeader classname={styles.bid} />
      </Flex>
    </Flex>
  );
};

export default OrderBook;
