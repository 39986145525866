import { format } from "date-fns";

export const timestampToFmt = (
  timestamp: number | string,
  formatStr = "MM/dd HH:mm:ss"
) => {
  const ts = Number(timestamp);
  if (isNaN(ts)) return String(timestamp);

  // use date-fns to format the date yyyy-mm-dd
  return format(ts, formatStr);
};

export const formatDate = (date: string | number | Date) => {
  if(!date) return ''
  return format(new Date(date), "yyyy-MM-dd HH:mm:ss");
}