import { Box, Button, Flex, Input, useDisclosure } from "@chakra-ui/react";
import { getUser, logout } from "src/store/utils";
import CTable from "src/component/CTable/Ctable";
import { useExchangeInfoStore } from "src/store";
import CModal from "src/component/CModal/CModal";
import { useState } from "react";
import { changePassword } from "src/api/accounts";
import CInput from "src/component/CInput/CInput";
import { ErrorMessage } from "src/utils/ErrorMessage";
import { useMessage } from "src/hook/useMessage";
import { resetLocalStorageExceptForToken } from "src/utils/states";

export default function Settings() {
  const userInfo = getUser();
  const message = useMessage();
  const passwordDisclosure = useDisclosure();
  const [newPassword, setNewPassword] = useState("");
  const store = useExchangeInfoStore();
  if (!userInfo) {
    return <Box>"请登录后访问"</Box>;
  }

  const items = [
    { label: "名字", value: userInfo.username },
    { label: "ID", value: userInfo.accountId },
  ];

  const cols = [{ key: "label" }, { key: "value" }];
  const handleLogout = () => {
    logout();
  };
  const handleUpdatePassword = async () => {
    try {
      await changePassword({ newPassword });
      message.success("修改成功");
    } catch (error) {
      message.detailedError(<ErrorMessage error={error} />);
    } finally {
      passwordDisclosure.onClose();
    }
  };
  return (
    <>
      <Flex width="100%" height="100%" flexDirection="column" alignItems="start">
        <CTable items={items} cols={cols} />
        <Button
          my={4}
          mx={4}
          onClick={() => {
            setNewPassword("");
            passwordDisclosure.onOpen();
          }}
        >
          修改密码
        </Button>
        <Button my={4} mx={4} onClick={handleLogout}>
          退出登录
        </Button>
      </Flex>
      <CModal isOpen={passwordDisclosure.isOpen} onClose={passwordDisclosure.onClose} title="修改密码">
        <Flex alignItems="center">
          <Box width={100}>新密码：</Box>
          <Input value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
        </Flex>
        <Button my={4} mx={4} onClick={handleUpdatePassword}>
          确认修改
        </Button>
      </CModal>
    </>
  );
}
