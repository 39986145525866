import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  InputGroup,
  InputLeftElement,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import CInput from "../CInput/CInput";
import { useExchangeInfoStore } from "src/store";
import { traderApi } from "src/api";
import { PairItem, TradeDirection } from "src/api/types";
import { useCallback, useRef, useState, useEffect } from "react";
import { useMessage } from "src/hook/useMessage";
import { ErrorMessage } from "src/utils/ErrorMessage";
import styles from "./Limit.module.scss";
import React from "react";

type OrderFormProps = {
  pair: PairItem;
  tradeDirection: TradeDirection;
};

type FormValues = {
  totalToken: string;
  totalU: string;
  startPrice: string;
  endPrice: string;
  orderCount: string;
};

const OrderForm: React.FC<OrderFormProps> = ({ pair, tradeDirection }) => {
  const store = useExchangeInfoStore();
  const message = useMessage();
  const [isLoading, setIsLoading] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  // 使用 ref 来跟踪最后更新的字段
  const lastUpdatedFieldRef = useRef<keyof FormValues | null>(null);

  // 定义表单
  const formik = useFormik<FormValues>({
    initialValues: {
      totalToken: "",
      totalU: "",
      startPrice: "",
      endPrice: "",
      orderCount: "", // 初始值为空字符串
    },
    validationSchema: Yup.object({
      totalToken: Yup.string()
        .matches(/^\d*\.?\d*$/, "必须为数字")
        .required("必填字段"),
      totalU: Yup.string().matches(/^\d*\.?\d*$/, "必须为数字"),
      orderCount: Yup.string()
        .matches(/^\d+$/, "必须为整数")
        .required("必填字段")
        .test("is-positive", "必须至少下1单", (value) => Number(value) >= 1),
      startPrice: Yup.string()
        .matches(/^\d*\.?\d*$/, "必须为数字")
        .required("必填字段"),
      endPrice: Yup.string()
        .matches(/^\d*\.?\d*$/, "必须为数字")
        .required("必填字段")
        .test("is-greater-than-start", "最高价格必须大于或等于最低价格", function (value) {
          const { startPrice } = this.parent;
          return Number(value) >= Number(startPrice);
        }),
    }),
    onSubmit: onOpen, // 打开确认对话框
  });

  // 计算价格的函数
  const calculatePrice = useCallback((values: FormValues) => {
    const count = parseInt(values.orderCount, 10);
    const start = parseFloat(values.startPrice);
    const end = parseFloat(values.endPrice);

    if (isNaN(count) || count <= 0 || isNaN(start) || isNaN(end)) {
      return NaN;
    }

    if (count > 1) {
      return (start + end) / 2;
    } else {
      return start;
    }
  }, []);

  // 同步 totalToken 和 totalU
  useEffect(() => {
    const lastUpdated = lastUpdatedFieldRef.current;
    if (!lastUpdated) return;

    const price = calculatePrice(formik.values);
    if (isNaN(price) || price <= 0) return;

    if (lastUpdated === "totalToken") {
      const tokenNumber = parseFloat(formik.values.totalToken);
      if (!isNaN(tokenNumber)) {
        const newTotalU = (tokenNumber * price).toFixed(2);
        if (newTotalU !== formik.values.totalU) {
          // 设置 newTotalU，并记录更新来源
          formik.setFieldValue("totalU", newTotalU, false);
        }
      }
    } else if (lastUpdated === "totalU") {
      const uNumber = parseFloat(formik.values.totalU);
      if (!isNaN(uNumber)) {
        const newTotalToken = (uNumber / price).toFixed(2);
        if (newTotalToken !== formik.values.totalToken) {
          // 设置 newTotalToken，并记录更新来源
          formik.setFieldValue("totalToken", newTotalToken, false);
        }
      }
    } else if (lastUpdated === "startPrice" || lastUpdated === "endPrice" || lastUpdated === "orderCount") {
      // 价格相关字段变化后，更新 totalToken 或 totalU
      const uNumber = parseFloat(formik.values.totalU);
      const tokenNumber = parseFloat(formik.values.totalToken);

      if (!isNaN(tokenNumber) && tokenNumber > 0) {
        // 如果币的数量有效，更新 totalU, 优先根据币的数量更新 U 的价值
        const newTotalU = (tokenNumber * price).toFixed(2);
        if (newTotalU !== formik.values.totalU) {
          formik.setFieldValue("totalU", newTotalU, false);
        }
      } else if (!isNaN(uNumber) && uNumber > 0) {
        // 如果 U 有效，更新 totalToken
        const newTotalToken = (uNumber / price).toFixed(2);
        if (newTotalToken !== formik.values.totalToken) {
          formik.setFieldValue("totalToken", newTotalToken, false);
        }
      }
    }

    // 重置 lastUpdatedFieldRef
    lastUpdatedFieldRef.current = null;
  }, [formik.values, calculatePrice, formik]);

  const handleActualSubmit = async (values: FormValues) => {
    if (isLoading) return;
    if (!store.selectedExchangeAccountId || store.selectedExchangeAccountId === -1) {
      alert("请选择交易账户");
      return;
    }
    const payload = {
      exchangeAccountId: Number(store.selectedExchangeAccountId),
      pairId: Number(pair.id),
      totalAmount: Number(values.totalToken),
      side: tradeDirection,
      orderNum: Number(values.orderCount),
      startPrice: Number(values.startPrice),
      endPrice: Number(values.endPrice),
    };
    console.log("tradeTokenV2 payload", payload);
    try {
      setIsLoading(true);
      const result = await traderApi.tradeTokenV2(payload);
      console.log("result", result);
      message.success("下单成功");
    } catch (error) {
      message.detailedError(<ErrorMessage error={error} />);
      console.error("tradeTokenV2 error", error);
      console.error("tradeTokenV2 params: ", payload);
    } finally {
      setIsLoading(false);
    }
  };

  // 处理输入变化，只设置 lastUpdatedFieldRef.current，不调用 setFieldValue
  const handleInputChange = (field: keyof FormValues) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    // 去除多余的前导零，但保留单个零和以 "0." 开头的数字
    if (field !== "orderCount") {
      value = value.replace(/^0+(\d)/, "$1");
      if (!/^\d*\.?\d*$/.test(value)) {
        return;
      }
    } else {
      // 对于 orderCount，只允许整数
      value = value.replace(/\D/g, "");
      // 去除多余的前导零
      value = value.replace(/^0+(\d)/, "$1");
    }

    formik.setFieldValue(field, value);
    lastUpdatedFieldRef.current = field;
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Flex flexWrap="wrap" gap={1} justifyContent="space-between">
          <CInput
            classname={styles.input}
            label="下单数量"
            name="totalToken"
            type="text"
            placeholder="币数量"
            onChange={handleInputChange("totalToken")}
            rightIndicator="币"
          />
          <CInput
            classname={styles.input}
            label="下单数量"
            name="totalU"
            type="text"
            placeholder="U数量"
            onChange={handleInputChange("totalU")}
            rightIndicator="U"
          />
          <CInput
            classname={styles.input}
            label="最低价格"
            name="startPrice"
            type="text"
            placeholder="最低价格"
            onChange={handleInputChange("startPrice")}
          />
          <CInput
            classname={styles.input}
            label="最高价格"
            name="endPrice"
            type="text"
            placeholder="最高价格"
            onChange={handleInputChange("endPrice")}
          />
          <CInput
            classname={styles.input}
            label="订单数量"
            name="orderCount"
            type="text"
            placeholder="订单数量"
            onChange={handleInputChange("orderCount")}
          />
        </Flex>

        <Flex justifyContent="center" mt={4}>
          <Flex width="auto">
            <InputGroup>
              {isLoading && (
                <InputLeftElement pointerEvents="none">
                  <Spinner size="sm" color="gray.300" />
                </InputLeftElement>
              )}
              <Button
                type="submit"
                width="200px"
                bgColor={tradeDirection === TradeDirection.Buy ? "teal" : "#f08080"}
                color="white"
                cursor="pointer"
                disabled={!formik.isValid || isLoading}
              >
                {tradeDirection === TradeDirection.Buy ? "买入" : "卖出"}
              </Button>
            </InputGroup>
          </Flex>
        </Flex>
      </form>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              确认交易
            </AlertDialogHeader>

            <AlertDialogBody>确定立即批量下{tradeDirection === TradeDirection.Buy ? "买" : "卖"}单吗？</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                取消
              </Button>
              <Button
                onClick={() => {
                  onClose();
                  handleActualSubmit(formik.values);
                }}
                ml={3}
              >
                确定
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </FormikProvider>
  );
};

export default OrderForm;
