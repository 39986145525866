import { api, ApiResponse } from "./base";
import {
  AccountResponse,
  CreateAccountParam,
  CreateAccountResponse,
  CreateExchangeAccountResponse,
  ExchangeAccount,
  PairItem,
  RoleResponse,
  SelfExchangeAccount,
} from "./types";

export const fetchAccountById = async (id: string | number) => {
  const res = await api.get<ApiResponse<AccountResponse>>(`/accounts/${id}`);
  return res.data.data;
};

export const fetchAccounts = async () => {
  const res = await api.get<ApiResponse<AccountResponse[]>>("/accounts");
  return res.data.data;
};
export const fetchExchangeAccounts = async () => {
  const res = await api.get<ApiResponse<ExchangeAccount[]>>("/exchangeAccounts", {
    params: {
      take: 10000,
      skip: 0,
    },
  });
  return res.data.data;
};

export const createAccount = async (payload: CreateAccountParam) => {
  const res = await api.post<ApiResponse<CreateAccountResponse>>("/accounts", payload);
  return res.data.data;
};

export const fetchUserExhangeAccounts = async (id: string | number) => {
  const res = await api.get<ApiResponse<SelfExchangeAccount[]>>(`/exchangeAccounts/ofAccount/${id}`);
  return res.data.data;
};

export const fetchExchangeAccountPairs = async (id: string | number) => {
  const res = await api.get<ApiResponse<PairItem[]>>(`/exchangeAccounts/${id}/pairs`);
  return res.data.data;
};

export const addUserExchangeAccount = async (accountId: string | number, exchangeAccountId: string | number) => {
  const res = await api.put<ApiResponse<any>>(`/exchangeAccounts/${exchangeAccountId}/accounts/${accountId}`);
  return res.data.data;
};

export const assignPairToExchangeAccount = async (exchangeAccountId: string | number, pairId: string | number) => {
  const res = await api.put<ApiResponse<any>>(`/exchangeAccounts/${exchangeAccountId}/pairs/${pairId}`);
  return res.data.data;
};
