import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import AccountSelect from "src/component/AccountSelect/AccountSelect";
import ExchangeSelect from "src/component/ExchangeSelect/ExchangeSelect";
import PairSelect from "src/component/PairSelect/PairSelect";
import TradingViewWidget from "src/component/TradingViewWidget";
import { useExchangeInfoStore } from "src/store";
import { getPairSymbol, getTradingviewCexName } from "src/store/utils";
import { isSelectedEmpty } from "src/utils/states";
import { cbPerpApi } from "src/api";
import { useQuery } from "@tanstack/react-query";
import MarketOpenPosition from "./MarketOpenPosition";
import CTable from "src/component/CTable/Ctable";
import { orderCols, positionCols, REFETCH_INTERVAL, historyPositionCols } from "./perpConfigs";
import CButton from "src/component/CButton/CButton";
import { FetchOrderResponse, FetchPositionResponse, MarginType, TradeType } from "src/api/types";
import ClosePositionAlert from "./ClosePositionAlert";
import { useMessage } from "src/hook/useMessage";
import { ErrorMessage } from "src/utils/ErrorMessage";
import UpdateTPAndSL from "./UpdateTPAndSL";
import AdjustMargin from "./AddMargin";

export default function CbPerpeptual() {
  const message = useMessage();
  const store = useExchangeInfoStore();
  const { selectedExchangeKey, selectedCbPerpPair: selectedPair } = store;
  const selectedExchangeAccountId = useExchangeInfoStore((state) => state.selectedExchangeAccountId);
  const [selectedPosition, setSelectedPosition] = useState<FetchPositionResponse | null>(null);
  const enableQuery = useMemo(() => {
    return !isSelectedEmpty(selectedExchangeAccountId) && !!selectedExchangeKey && !!selectedPair;
  }, [selectedExchangeAccountId, selectedExchangeKey, selectedPair]);
  const [marginType, setMarginType] = useState<MarginType | null>(null);
  const [positionSide, setPositionSide] = useState(false);

  // 查询配置
  useQuery({
    queryKey: ["cb-config", selectedPair?.id, selectedExchangeKey, selectedExchangeAccountId],
    queryFn: async () => {
      const res = await cbPerpApi.fetchPositionConfig({
        exchangeAccountId: selectedExchangeAccountId,
        exchangeName: selectedExchangeKey,
      });
      const config = res.find((item) => item.symbol === selectedPair?.symbol);
      if (config) {
        setLeverage(Number(config.leverage));
        setMarginType(config.isolated ? MarginType.Isolated : MarginType.Crossed);
        setPositionSide(config.positionSide.toLowerCase() === "both" ? true : false);
      }
      return res;
    },
    enabled: !!selectedPair && !!selectedExchangeKey && !!selectedExchangeAccountId,
    refetchInterval: REFETCH_INTERVAL,
  });

  const balanceQuery = useQuery({
    enabled: enableQuery && !!selectedPair,
    queryKey: ["balance", selectedExchangeAccountId],
    queryFn: () =>
      cbPerpApi
        .fetchPerpBalance({
          exchangeAccountId: selectedExchangeAccountId,
          exchangeName: selectedExchangeKey,
        })
        .then((data) => {
          const filteredData = data.filter((oneData) => {
            return oneData.asset === selectedPair?.base;
          });
          return filteredData;
        })
        .catch((error) => {
          // 处理错误，例如记录日志或返回默认值
          console.error("获取余额时发生错误：", error);
          // 可以选择抛出错误，以便 useQuery 知道请求失败
          throw error;
        }),
    refetchInterval: REFETCH_INTERVAL,
  });

  const marketPriceQuery = useQuery({
    enabled: enableQuery,
    queryKey: ["symbol", selectedPair?.symbol],
    queryFn: () =>
      cbPerpApi.requestMarketPrice({
        exchangeAccountId: selectedExchangeAccountId,
        exchangeName: selectedExchangeKey,
        symbol: selectedPair?.symbol,
      }),
    refetchInterval: REFETCH_INTERVAL,
  });

  const ordersQuery = useQuery({
    enabled: enableQuery && !!selectedPair,
    queryKey: ["orders", selectedExchangeAccountId],
    queryFn: async () => {
      try {
        // 获取订单数据
        const result = await cbPerpApi.fetchPerpOrders({
          exchangeAccountId: selectedExchangeAccountId,
          exchangeName: selectedExchangeKey,
        });

        if (!result || !Array.isArray(result)) {
          return [];
        }

        const filteredOrders = result.filter((order) => {
          return order.symbol.includes(selectedPair.base);
        });

        return filteredOrders;
      } catch (error) {
        // 处理异常
        return []; // 如果请求失败，返回空数组
      }
    },
    refetchInterval: REFETCH_INTERVAL,
  });

  const positionQuery = useQuery({
    enabled: enableQuery,
    queryKey: ["position", selectedExchangeAccountId],
    queryFn: async () => {
      try {
        const data = await cbPerpApi.fetchPosition({
          exchangeAccountId: selectedExchangeAccountId,
          exchangeName: selectedExchangeKey,
        });

        // 确保 data 是一个数组
        if (!Array.isArray(data)) {
          throw new Error("Fetched data is not an array");
        }

        const filteredPositions = data.filter((position) => {
          return position.symbol.includes(selectedPair.base);
        });

        const processedData = filteredPositions.map((order) => {
          const posSize = Number(order.position_size) || 0;
          const pnl = Number(order.unrealised_pnl) || 0;
          const pnlPercent = posSize !== 0 ? (pnl * 100) / posSize : 0;

          return { ...order, pnl_percent: pnlPercent.toFixed(2) };
        });

        return processedData;
      } catch (error) {
        console.error("Failed to fetch position data:", error);
        throw error;
      }
    },
    refetchInterval: REFETCH_INTERVAL,
  });

  const currentPosition = useMemo(() => {
    const positions = positionQuery.data || [];
    const currentPositions = positions.filter((item) => {
      if (!selectedPair) return false;
      return item.symbol === selectedPair?.symbol;
    });
    return currentPositions[0];
  }, [positionQuery.data, selectedPair]);

  const historyPositionQuery = useQuery({
    enabled: enableQuery,
    queryKey: ["historyPosition", selectedExchangeAccountId],
    queryFn: async () => {
      try {
        const data = await cbPerpApi.fetchHistoryPosition({
          exchangeAccountId: selectedExchangeAccountId,
          exchangeName: selectedExchangeKey,
          symbol: selectedPair?.symbol,
        });

        // 确保 data 是一个数组
        if (!Array.isArray(data) || !data) {
          return [];
        }
        const filteredHistory = data.filter((position) => {
          return position.symbol.includes(selectedPair.base);
        });

        return filteredHistory;
      } catch (error) {
        console.error("Failed to fetch position data:", error);
        throw error;
      }
    },
    refetchInterval: REFETCH_INTERVAL,
  });

  const {
    isOpen: isClosePositionAlertOpen,
    onClose: onCloseClosePositionAlert,
    onOpen: onOpenClosePositionAlert,
  } = useDisclosure();

  const { isOpen: isUpdateTPAndSLOpen, onClose: onCloseUpdateTPAndSL, onOpen: onOpenUpdateTPAndSL } = useDisclosure();

  const { isOpen: isAdjustMarginOpen, onClose: onCloseAdjustMargin, onOpen: onOpenAdjustMargin } = useDisclosure();

  const handleSetMargin = () => {
    setSelectedPosition(currentPosition);
    onOpenAdjustMargin();
  };

  const [leverage, setLeverage] = useState(Number(currentPosition?.leverage) ?? 1);

  // 币本位开仓也不会减少余额，需要手动统计，然后减去给开仓组件使用
  const totalPositionSize =
    positionQuery.data?.reduce((acc, position) => acc + Number(position.position_size), 0) / leverage;

  const positionOperationCol = (item: any) => {
    return (
      <Flex gap={"2px"}>
        <CButton
          colorScheme={"red"}
          name={"平仓"}
          onClick={() => {
            setSelectedPosition(item);
            onOpenClosePositionAlert();
          }}
        />
        <CButton
          colorScheme={"green"}
          name={"止盈止损"}
          onClick={() => {
            setSelectedPosition(item);
            onOpenUpdateTPAndSL();
          }}
        />
      </Flex>
    );
  };

  const orderOperationCol = (item: FetchOrderResponse) => {
    return (
      <Flex>
        <CButton
          colorScheme={"red"}
          name={"取消"}
          onClick={async () => {
            try {
              await cbPerpApi.requestCancelOrder({
                exchangeName: selectedExchangeKey,
                exchangeAccountId: selectedExchangeAccountId,
                orderId: item.id,
                type: item.type,
                symbol: item.symbol,
              });
            } catch (error) {
              // show error
              message.detailedError(<ErrorMessage error={error as any} />);
            }
          }}
        />
      </Flex>
    );
  };

  return (
    <>
      <Flex
        width="100%"
        maxH="calc(100vh - 140px)"
        px={2}
        justifyContent="start"
        gap={6}
        flexDir={"column"}
        marginY={"auto"}
      >
        <Flex width={"100%"} height={"auto"} justifyContent={"space-between"} alignItems={"stretch"}>
          <Flex justifyContent={"space-between"} gap={12} width={"100%"}>
            <Flex
              width="calc(50% - 20px)"
              maxW="600px"
              flexDirection="column"
              overflow="scroll"
              gap={2}
              style={{ scrollbarWidth: "none" }}
            >
              <Box fontSize={"32"} color={"blue"}>
                币本位合约
              </Box>
              <Flex alignItems="center" gap={2}>
                <PairSelect tradeType={TradeType.CB_PERP} />
              </Flex>
              <Flex alignItems="center" gap={2}>
                <ExchangeSelect tradeType={TradeType.CB_PERP} />
              </Flex>
              <Flex alignItems="center" gap={2}>
                <AccountSelect />
              </Flex>
              <Flex height={"40px"}>
                余额：{" "}
                <Flex justifyContent={"space-between"}>
                  <Text>
                    {balanceQuery.data?.[0]?.balance || 0} {selectedPair?.base}
                  </Text>
                </Flex>
              </Flex>
              <Flex alignItems="center" gap={2} border="1px solid gray" borderRadius="10px">
                <MarketOpenPosition
                  hasPosition={!!currentPosition}
                  defaultLeverage={leverage}
                  marketPrice={marketPriceQuery.data}
                  marginType={marginType}
                  setLeverage={setLeverage}
                  setMarginType={setMarginType}
                  positionSide={positionSide}
                  balance={Number(balanceQuery.data?.[0]?.balance) - totalPositionSize || 0}
                />
              </Flex>
            </Flex>
            <Flex width="calc(50% - 20px)" flexGrow={1} flexDirection="column">
              <Flex height="100%">
                <TradingViewWidget
                  pairName={getPairSymbol(selectedPair?.base, selectedPair?.quote)}
                  cexName={getTradingviewCexName(selectedExchangeKey)}
                  isPerpetual={true}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex width="100%" flexDirection="column" gap={2} border="1px solid gray" borderRadius="10px">
          <Tabs>
            <TabList>
              <Tab>当前委托 {ordersQuery.data?.length ?? 0}</Tab>
              <Tab>当前持仓 {positionQuery.data?.length ?? 0}</Tab>
              <Tab>交易历史 {historyPositionQuery.data?.length ?? 0}</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <CTable
                  items={ordersQuery.data || []}
                  cols={orderCols}
                  showHeader
                  operationCol={orderOperationCol}
                  operationSide="left"
                />
              </TabPanel>
              <TabPanel>
                <CTable
                  items={positionQuery.data || []}
                  cols={positionCols}
                  showHeader
                  operationSide="left"
                  operationCol={positionOperationCol}
                  clickableKey="margin"
                  clickCallback={handleSetMargin}
                />
              </TabPanel>
              <TabPanel>
                <CTable
                  items={historyPositionQuery.data || []}
                  cols={historyPositionCols}
                  showHeader
                  // operationSide="left"
                  // operationCol={positionOperationCol}
                  clickableKey="margin"
                  clickCallback={handleSetMargin}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
      <ClosePositionAlert
        positionItem={selectedPosition}
        isOpen={isClosePositionAlertOpen}
        onClose={onCloseClosePositionAlert}
        submitForm={function (payload: { amount: number }): void {
          throw new Error("Function not implemented.");
        }}
      />
      <UpdateTPAndSL
        positionItem={selectedPosition}
        isOpen={isUpdateTPAndSLOpen}
        onClose={onCloseUpdateTPAndSL}
        submitForm={() => {}}
        marketPrice={marketPriceQuery.data}
      />

      <AdjustMargin
        positionItem={selectedPosition}
        isOpen={isAdjustMarginOpen}
        onClose={onCloseAdjustMargin}
        marginType={marginType}
      />
    </>
  );
}
