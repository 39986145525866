import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  HStack,
  Text,
  useDisclosure,
  useRadioGroup,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { strategiesApi } from "src/api";
import { CreateStrategyParams, SingleSideExampleType, StrategyKey, StrategyType, TradeDirection, VolumeExampleType } from "src/api/types";
import CButton from "src/component/CButton/CButton";
import CRadioCard from "src/component/CRadioCard";
import CSelect from "src/component/CSelect";
import { useExchangeInfoStore } from "src/store";
import { getPairName } from "src/store/utils";
import * as Yup from "yup";
import styles from "./index.module.scss";
import CInput from "src/component/CInput/CInput";
import { useState } from "react";
import { useExchangeAccounts } from "src/hook/useExchangeAccounts";
import React from "react";
import CommonSingleSideForm from "src/component/StrategyForms/CommonSingleSideForm";

export default function SingleSideForm() {
  const store = useExchangeInfoStore();
  const exchanges = useExchangeAccounts();
  const [selectedExchangeAccountId, setSelectedExchangeAccountId] = useState<number | null>(null);

  const exchangeAccountOptions = exchanges.myExchangeAccounts.map((account) => {
    const exchange = store.exchanges.find((exchange) => exchange.id === account.exchangeId);
    const displayName = `${exchange ? exchange.name : "unknown"}: ${account.alias}`;
    return { value: account.id, label: displayName };
  });

  const pairOptions = store.spotPairs.map((pair) => ({
    value: pair.id,
    label: getPairName(pair.base, pair.quote),
  }));
  const sideOptions = [
    { value: TradeDirection.Buy, label: "买入" },
    { value: TradeDirection.Sell, label: "卖出" },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  return (
    <Formik
      initialValues={{
        strategyName: "",
        pair: "",
        exchangeAccount: "",
        repeatIntervalMsMin: "10000",
        repeatIntervalMsMax: "20000",

        totalAmount: "",
        priceTriggerMin: "",
        priceTriggerMax: "",
        minQuantity: "",
        maxQuantity: "",
        side: "",
      }}
      validationSchema={Yup.object({
        pair: Yup.object().required("Required"),
        exchangeAccount: Yup.object().required("Required"),
        

        totalAmount: Yup.number().required("Required"),
        priceTriggerMin: Yup.number().required("Required"),
        priceTriggerMax: Yup.number().required("Required"),
        minQuantity: Yup.number().required("Required"),
        maxQuantity: Yup.number().required("Required"),
        side: Yup.object().required("Required"),
      })}
      onSubmit={async (values) => {
        const payload: CreateStrategyParams<SingleSideExampleType> = {
          strategyName: values.strategyName,
          pairId: Number((values.pair as any).value),
          exchangeAccountId: Number((values.exchangeAccount as any).value),
          type: StrategyType.SingleSide,
          repeatIntervalMsMin: Number(values.repeatIntervalMsMin),
          repeatIntervalMsMax: Number(values.repeatIntervalMsMax),
          preference: {
            totalAmount: Number(values.totalAmount),
            priceTriggerMin: Number(values.priceTriggerMin),
            priceTriggerMax: Number(values.priceTriggerMax),
            minQuantity: Number(values.minQuantity),
            maxQuantity: Number(values.maxQuantity),
            side: (values.side as any).value,
          },
        };
        try {
          const result = await strategiesApi.createStrategyV2(payload);
          window.location.href = `/strategies?tab=${StrategyKey.OneSide}`;
        } catch (error) {
          alert("创建失败");
        }
      }}
    >
      {({ values, setFieldValue, submitForm }) => {
        return (
          <>
            <Form className={styles.form}>
              <Flex width="calc(100% - 20px)" flexDirection="column" gap={2}>
                <Flex alignItems="center" gap={2} mt={4}>
                  <CSelect
                    classname={styles.select}
                    label={"交易所账户"}
                    placeholder="交易所账户"
                    options={exchangeAccountOptions}
                    onChange={(value) => {
                      setFieldValue("exchangeAccount", value);
                      setSelectedExchangeAccountId((value as any).value);
                    }}
                  />
                </Flex>
                <Flex alignItems="center" gap={2} mt={2}>
                  <CSelect
                    classname={styles.select}
                    label={"交易对"}
                    placeholder="交易对"
                    options={pairOptions}
                    onChange={(value) => {
                      setFieldValue("pair", value);
                    }}
                  />
                </Flex>
                <Flex alignItems="center" gap={2} mt={2}>
                  <CSelect
                    classname={styles.select}
                    label={"交易方向"}
                    placeholder="交易方向"
                    options={sideOptions}
                    onChange={(value) => {
                      setFieldValue("side", value);
                    }}
                  />
                </Flex>
                <CommonSingleSideForm styles={styles} />
                
                <Flex alignItems="center" gap={2} mt={4}>
                  <CButton name="确认创建" onClick={onOpen} />
                </Flex>
              </Flex>
            </Form>

            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    确认创建
                  </AlertDialogHeader>

                  <AlertDialogBody>确定立即创建机器人吗？</AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      取消
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        submitForm();
                      }}
                      ml={3}
                    >
                      确定
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </>
        );
      }}
    </Formik>
  );
}
