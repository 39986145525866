import { useParams } from "react-router-dom";
import styles from "./UserDetail.module.scss";
import { adminApi } from "src/api";
import { useQuery } from "@tanstack/react-query";
import { getPairName } from "src/store/utils";
import { Flex, Text } from "@chakra-ui/react";
import CTable from "../CTable/Ctable";
import { useExchangeInfoStore } from "src/store";
import CButton from "../CButton/CButton";
import { useState } from "react";
import CModal from "../CModal/CModal";
import CSelect from "../CSelect";
import { useMessage } from "src/hook/useMessage";
import { useExchangeAccounts } from "src/hook/useExchangeAccounts";
import { EXCHANGE_ACCOUNTS_KEY } from "src/consts";
import { revokeExchangeAccount } from "src/api/accounts";

export default function UserDetail() {
  const { id } = useParams();
  const message = useMessage();
  const store = useExchangeInfoStore();
  const exchangeAccountsQuery = useQuery({
    queryKey: ["exchangeAccounts", id],
    queryFn: async () => {
      const allExchangeAccountsResult = await adminApi.fetchExchangeAccounts();
      store.setExchangeAccounts(allExchangeAccountsResult);
      localStorage.setItem(EXCHANGE_ACCOUNTS_KEY, JSON.stringify(allExchangeAccountsResult));
      return allExchangeAccountsResult;
    },
  });
  const EXCHANGES = store.exchanges;
  const allExchangeAccounts = store.exchangeAccounts;
  const allExchangeAccountsOptions = exchangeAccountsQuery.isSuccess
    ? exchangeAccountsQuery.data.map((exchangeAccount) => {
        return {
          label: exchangeAccount.alias,
          value: exchangeAccount.id,
        };
      })
    : [];
  // const userInfoQuery = useQuery({
  //   queryKey: ["userInfo", id],
  //   queryFn: async () => {
  //     return await adminApi.fetchAccountById(id);
  //   },
  // });

  const userExhangeAccountsQuery = useQuery({
    queryKey: ["userExhangeAccounts", id],
    queryFn: async () => {
      const exchangeAccounts = await adminApi.fetchUserExhangeAccounts(id);
      const exchangeAccountIds = exchangeAccounts.map((exchangeAccount) => exchangeAccount.id);
      const pairQueries = exchangeAccountIds.map((exchangeAccountId) => {
        return adminApi.fetchExchangeAccountPairs(exchangeAccountId);
      });
      const pairResults = await Promise.all(pairQueries);
      return exchangeAccounts.map((exchangeAccount, index) => {
        return {
          ...exchangeAccount,
          pairs: pairResults[index].map((pair) => getPairName(pair.base, pair.quote)),
        };
      });
    },
  });

  console.log("userExhangeAccountsQuery", userExhangeAccountsQuery.data);

  const items = userExhangeAccountsQuery.data || [];
  const cols = [
    { key: "id", title: "ID" },
    { key: "alias", title: "别名" },
    {
      key: "exchangeId",
      title: "交易所",
      render: (value) => <span>{EXCHANGES.find((exchange) => exchange.id === value.exchangeId)?.name}</span>,
    },
    { key: "pairs", title: "交易对", render: (value) => <span>{value.pairs.join(",")}</span> },
    { key: "createdAt", title: "创建时间" },
  ];

  const [isAddExchangeAccountModalOpen, setIsAddExchangeAccountModalOpen] = useState(false);
  const [selectedExchangeAccountId, setSelectedExchangeAccountId] = useState<{ label: string; value: string } | null>(
    null
  );

  const handleAddExchangeAccount = async () => {
    if (!selectedExchangeAccountId) {
      return;
    }
    try {
      await adminApi.addUserExchangeAccount(id, selectedExchangeAccountId?.value);
      message.success("添加成功");
      setIsAddExchangeAccountModalOpen(false);
      userExhangeAccountsQuery.refetch();
    } catch (error) {
      message.error("添加失败");
    }
  };

  const operationCol = (item: any) => {
    return (
      <Flex>
        <CButton
          colorScheme="red"
          type="reset"
          name={"回收权限"}
          onClick={async () => {
            console.log("回收权限", item);
            await revokeExchangeAccount({ perId: item.perId });
            userExhangeAccountsQuery.refetch();
          }}
        />
      </Flex>
    );
  };
  return (
    <Flex width="100%" flexDirection="column" px={4} py={4}>
      <Text fontSize="20px" fontWeight={600} mb={4}>
        平台账户ID：{id}
      </Text>

      <Text fontSize="18px" fontWeight={600} mb={4}>
        拥有交易所账号：
      </Text>
      <CTable classname={styles.tableContainer} items={items} cols={cols} showHeader operationCol={operationCol} />
      <Flex mt={4}>
        <CButton name="分配交易所账号" onClick={() => setIsAddExchangeAccountModalOpen(true)} />
      </Flex>
      <CModal
        isOpen={isAddExchangeAccountModalOpen}
        onClose={() => setIsAddExchangeAccountModalOpen(false)}
        title={"添加交易所账号"}
      >
        <CSelect
          label={"选择交易所账号"}
          options={allExchangeAccountsOptions}
          onChange={(value: any) => setSelectedExchangeAccountId(value)}
        />
        <CButton name="确认添加" onClick={handleAddExchangeAccount} />
      </CModal>
    </Flex>
  );
}
