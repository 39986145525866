import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import CTable from "../CTable/Ctable";
import { useQuery } from "@tanstack/react-query";
import { orderApi } from "src/api";
import { useExchangeInfoStore } from "src/store";
import { useRef, useState } from "react";
import { TradeDirection, TradeType } from "src/api/types";
import { formatDate } from "src/utils/date";
import CButton from "../CButton/CButton";
import { useMessage } from "src/hook/useMessage";
import { ErrorMessage } from "src/utils/ErrorMessage";
import { pro } from "ccxt";
import CModal from "../CModal/CModal";
import { VscScreenFull } from "react-icons/vsc";

type Props = {
  handleCancelAll: () => void;
  hideFullScreenButton?: boolean;
};

export default function OpenListing(props: Props) {
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const store = useExchangeInfoStore();
  const selectedPairId = store.selectedSpotPair.id;
  const message = useMessage();
  console.log("selectedPairId", selectedPairId);
  const exchangeAccountId = store.selectedExchangeAccountId;
  console.log("exchangeAccountId", exchangeAccountId);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const cols = [
    {
      key: "pair",
      title: "交易对",
    },
    {
      key: "type",
      title: "类型",
    },
    {
      key: "side",
      title: "方向",
    },
    {
      key: "price",
      title: "价格",
    },
    {
      key: "baseAmount",
      title: "币数量",
    },
    {
      key: "executedAmount",
      title: "已成交币数量",
    },
    {
      key: "date",
      title: "时间",
      render: (item: any) => {
        return <span>{formatDate(item.date)}</span>;
      },
    },
    {
      key: "orderId",
      title: "订单ID",
    },
  ];
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);
  const openListingQuery = useQuery({
    queryKey: ["openListingQuery", exchangeAccountId, pageSize, page],
    queryFn: () => {
      return orderApi.fetchListings({
        exchangeAccountId: Number(exchangeAccountId),
        pairId: selectedPairId,
      });
    },
    enabled: !!exchangeAccountId && exchangeAccountId !== -1 && !!selectedPairId,
    refetchInterval: 5000,
  });
  let listing = openListingQuery.data || [];
  //  make mock listing data by duplicating the first item 31 times
  // if (listing.length === 1) {
  //   for (let i = 0; i < 30; i++) {
  //     listing.push({ ...listing[0], orderId: String(i) });
  //   }
  // }
  listing = listing.map((item) => ({
    ...item,
    isGreen: item.side.toLowerCase() === TradeDirection.Buy,
    isRed: item.side.toLowerCase() === TradeDirection.Sell,
  }));
  console.log("listing", listing);

  const operationCol = (item: any) => {
    return (
      <Flex>
        <CButton
          name={"取消"}
          onClick={async () => {
            const orderId = item.orderId;
            try {
              await orderApi.requestCancelAllOrders({
                exchangeAccountId: Number(exchangeAccountId),
                pairId: selectedPairId,
                orderIds: [String(orderId)],
              });
              message.success("取消成功");
              openListingQuery.refetch();
            } catch (error) {
              message.detailedError(<ErrorMessage error={error} />);
            }
          }}
        />
      </Flex>
    );
  };
  return (
    <>
      <Flex width="100%" flexDirection="column">
        <Flex width="100%" justifyContent="space-between">
          <Text fontSize="20px" fontWeight={600}>
            委托订单
          </Text>
          <Flex gap={2} alignItems={"center"}>
            {!props.hideFullScreenButton && (
              <VscScreenFull size="24px" cursor="pointer" onClick={() => setIsOrderModalOpen(true)} />
            )}
            <Button size="sm" onClick={onOpen}>
              取消所有挂单
            </Button>
            <Button size="sm" onClick={() => openListingQuery.refetch()}>
              刷新
            </Button>
          </Flex>
        </Flex>
        {!exchangeAccountId || exchangeAccountId === -1 ? (
          <span>请选择交易所账户</span>
        ) : (
          <CTable paginated items={listing} cols={cols} operationCol={operationCol} showHeader />
        )}
      </Flex>
      <CModal size="6xl" title="订单详情" isOpen={isOrderModalOpen} onClose={() => setIsOrderModalOpen(false)}>
        <OpenListing hideFullScreenButton handleCancelAll={props.handleCancelAll} />
      </CModal>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              取消所有挂单
            </AlertDialogHeader>
            <AlertDialogBody>确定取消所有挂单</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                取消
              </Button>
              <Button
                onClick={() => {
                  onClose();
                  props.handleCancelAll();
                }}
                ml={3}
              >
                确定
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
