export type ExchangeType = {
  key: string;
  name: string;
  id: number;
};

export const EXCHANGES: ExchangeType[] = [
  {
    id: 1,
    name: "binance",
    key: "binance",
  },
];
