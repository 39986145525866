import { Box, Divider, Flex, Icon, Text } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { menuRoutes } from "../../routes";
import { useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import classnames from "classnames";
import { getUser } from "src/store/utils";
import styles from "./Menu.module.scss";
import {
  STRATEGY_LIST_ACTIVE_FILTER_KEY,
  STRATEGY_LIST_PAIR_FILTER_KEY,
  STRATEGY_LIST_SYMBOL_FILTER_KEY,
  STRATEGY_LIST_TYPE_FILTER_KEY,
} from "src/consts";
import { StrategyKey } from "src/api/types";

export default function Menu() {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const pathnameArr = pathname.split("/");
  const mainPath = `/${pathnameArr[1]}`;
  const preOpenPathItem = menuRoutes.find((item) => !!item.children?.find((subItem) => subItem.path === mainPath));
  const [expandedMenus, setExpandedMenus] = useState<string[]>(preOpenPathItem ? [preOpenPathItem.name] : []);
  const handleNavigate = (item: { name: string; path?: string }) => {
    if (item.path) {
      if (item.path === "/strategies") {
        const tab = localStorage.getItem(STRATEGY_LIST_TYPE_FILTER_KEY) || StrategyKey.OneSide;
        const pairId = localStorage.getItem(STRATEGY_LIST_PAIR_FILTER_KEY);
        const onlyActive = localStorage.getItem(STRATEGY_LIST_ACTIVE_FILTER_KEY) === "true";
        const symbolFilter = localStorage.getItem(STRATEGY_LIST_SYMBOL_FILTER_KEY);
        navigate(`/strategies?tab=${tab}&pairId=${pairId}&onlyActive=${onlyActive}&symbolFilter=${symbolFilter}`);
      } else {
        navigate(item.path);
      }
    } else {
      const isExpandex = expandedMenus.includes(item.name);
      if (isExpandex) {
        setExpandedMenus(expandedMenus.filter((name) => name !== item.name));
      } else {
        setExpandedMenus([...expandedMenus, item.name]);
      }
    }
  };

  const [hoverPath, setHoverPath] = useState("");
  const onMouseEnterPath = (path: string) => () => {
    setHoverPath(path);
  };
  const onMouseLeavePath = () => {
    setHoverPath("");
  };

  const userInfo = getUser();

  return (
    <div className={styles.container}>
      <Flex width="100%" flexDirection="column" alignItems="center" gap={2}>
        <Flex width="100%" flexDirection="column" alignItems="center" gap={2}>
          <Text className={styles.title}>系统后台</Text>
          <Divider width="90%" />
        </Flex>
        <Flex alignItems="center" gap={2} flexDirection="column" width="100%" mt={2}>
          {menuRoutes.map((item, index) => {
            const isPageOncCurrentPath = item.path === mainPath;
            // eslint-disable-next-line
            const [selected, setSelected] = useState(false);
            const onMouseEnter = () => {
              setSelected(true);
            };
            const onMouseLeave = () => {
              setSelected(false);
            };
            const shouldHighlight = isPageOncCurrentPath || selected;
            const icon = shouldHighlight ? item.iconSelected : item.icon;
            return (
              <Box key={`menu-${index}`} width="100%" userSelect="none">
                <Flex
                  key={index}
                  className={classnames(styles.item, shouldHighlight && styles.selectedItem)}
                  alignItems="center"
                  justifyContent="start"
                  ml={4}
                  w="100%"
                  height="40px"
                  py={6}
                  cursor="pointer"
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  onClick={() => handleNavigate(item)}
                >
                  {icon}
                  <Text fontWeight="bold" ml={2} color={shouldHighlight ? "#3a56ff" : "blackAlpha.700"}>
                    {item.name}
                  </Text>
                  {item.children &&
                    (expandedMenus.includes(item.name) ? (
                      <Icon as={HiChevronUp} boxSize="30px" />
                    ) : (
                      <Icon as={HiChevronDown} boxSize="30px" />
                    ))}
                </Flex>
                {item.children && expandedMenus.includes(item.name) && (
                  <Flex
                    className={classnames(styles.subMenu, expandedMenus.includes(item.name) && styles.expanded)}
                    flexDirection="column"
                    alignItems="center"
                    // bgColor="gray.100"
                    justifyContent="center"
                    w="100%"
                    cursor="pointer"
                  >
                    {item.children.map((subItem, subIndex) => {
                      const isPageOncCurrentPath = subItem.path === mainPath;
                      const isHovering = hoverPath === subItem.path;
                      const shouldHighlight = isPageOncCurrentPath || isHovering;
                      return (
                        <Flex
                          key={subIndex}
                          className={classnames(styles.item, shouldHighlight && styles.selectedItem)}
                          alignItems="center"
                          justifyContent="start"
                          pl="30px"
                          w="100%"
                          h="40px"
                          cursor="pointer"
                          onMouseEnter={onMouseEnterPath(subItem.path)}
                          onMouseLeave={onMouseLeavePath}
                          onClick={() => handleNavigate(subItem)}
                        >
                          <Text fontWeight="bold" color={shouldHighlight ? "#3a56ff" : "blackAlpha.700"}>
                            {subItem.name}
                          </Text>
                        </Flex>
                      );
                    })}
                  </Flex>
                )}
              </Box>
            );
          })}
        </Flex>
      </Flex>
      <Flex width="100%" flexDirection="column" alignItems="center" gap={2}>
        <Text fontSize="18px" fontWeight={600}>
          账户ID：{userInfo.username}
        </Text>
        <Divider width="90%" />
      </Flex>
    </div>
  );
}
