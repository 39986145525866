import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import styles from "./index.module.scss";
import CButton from "src/component/CButton/CButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { strategiesApi } from "src/api";
import { StrategyResponse, StrategyType, VolumeExampleType } from "src/api/types";
import { STRATEGY_LIST_SYMBOL_FILTER_KEY, STRATEGY_LIST_TYPE_FILTER_KEY } from "src/consts";
import { useExchangeInfoStore } from "src/store";
import { getPairName } from "src/store/utils";
import { useMessage } from "src/hook/useMessage";
import { useEffect, useMemo, useRef, useState } from "react";
import CModal from "src/component/CModal/CModal";
import StrategyDetail from "src/component/StrategyDetail/StrategyDetail";
import TradeOrder from "src/component/TradeOrder/TradeOrder";
import React from "react";
import { ErrorMessage } from "src/utils/ErrorMessage";
import CSelect from "src/component/CSelect";
import StatusPanel from "src/component/StatusPanel/StatusPanel";
import { ExchangeConfig } from "./ExchangeConfig";

const typeOptions = [
  {
    label: "单边",
    value: StrategyType.SingleSide,
  },
  {
    label: "吃单",
    value: StrategyType.EatOrder,
  },
  {
    label: "刷量",
    value: StrategyType.VolumeMaking,
  },
  {
    label: "低买高卖",
    value: StrategyType.LowBuyHighSell,
  },
  {
    label: "自动卖出",
    value: StrategyType.AutoSell,
  },
  {
    label: "摆盘",
    value: StrategyType.DepthBidAndOffer,
  },
  {
    label: "Dca",
    value: StrategyType.Dca,
  },
  {
    label: "ReduceGap",
    value: StrategyType.ReduceGap,
  },
];
type AccountOption = {
  value: number;
  label: string;
};
const defaultAccountOption = {
  value: -1,
  label: "全部",
};
export default function StrategyList() {
  const listRef = useRef(null);
  const prevScrollTop = useRef(0);
  const {
    isOpen: isBatchCloseConfirmOpen,
    onOpen: onBatchCloseConfirmOpen,
    onClose: onBatchCloseConfirmClose,
  } = useDisclosure();
  const store = useExchangeInfoStore();
  const pairs = store.spotPairs;
  const pairOptions = useMemo(() => {
    const options = pairs.map((pair) => {
      return {
        label: getPairName(pair.base, pair.quote),
        value: pair.id,
      };
    });
    return [{ label: "全部", value: null }, ...options];
  }, [pairs]);

  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const tab = query.get("tab");
  const urlSymbolFilter = query.get("symbolFilter");
  const cancelRef = React.useRef();
  const message = useMessage();
  const defaultType = typeOptions.find((item) => item.value.toLowerCase() === tab?.toLowerCase()) || typeOptions[0];
  const defaultSymbolFilter = urlSymbolFilter || "BTC";

  const [selectedTypeOption, setSelectedTypeOption] = useState<{ label: string; value: StrategyType }>(defaultType);
  const [symbolFilter, setSymbolFilter] = useState<string>(defaultSymbolFilter);

  const [type, setType] = useState<StrategyType>(defaultType.value);

  const [accountOptions, setAccountOptions] = useState<AccountOption[]>([defaultAccountOption]);
  const [selectedAccountId, setSelectedAccountId] = useState<number>(-1);

  const handleSetType = (type: StrategyType) => {
    navigate(`?tab=${type}&symbolFilter=${symbolFilter}`);
    localStorage.setItem(STRATEGY_LIST_TYPE_FILTER_KEY, type);
    setSelectedTypeOption(typeOptions.find((item) => item.value === type));
    setType(type);
  };
  const handleSetSymbolFilter = (symbol: string) => {
    navigate(`?tab=${type}&symbolFilter=${symbol}`);
    localStorage.setItem(STRATEGY_LIST_SYMBOL_FILTER_KEY, symbol);
    setSymbolFilter(symbol);
  };

  const [strategyList, setStrategyList] = useState<StrategyResponse<VolumeExampleType>[]>([]);
  const displayStrategyList = strategyList.filter((strategy) => {
    // return true
    const strategyPairId = strategy.pairId;
    const strategyPair = pairs.find((pair) => pair.id === strategyPairId);
    if (type === StrategyType.LowBuyHighSell && symbolFilter && !strategyPair?.base.includes(symbolFilter)) {
      return false;
    }
    if (selectedAccountId !== -1 && strategy.accountId !== selectedAccountId) {
      return false;
    }

    return true;
  });

  const strategyListQuery = useQuery({
    queryKey: ["displayStrategyList", type],
    queryFn: async () => {
      const result = await strategiesApi.fetchStrategies({ type });
      const stategiesAccountOptions: AccountOption[] = result.map((strategy) => {
        return {
          value: strategy.accountId,
          label: strategy.accountName || strategy.accountId.toString(),
        };
      });
      // get uniq account options
      let allAccountIdSet = new Set();
      stategiesAccountOptions.forEach((item) => {
        allAccountIdSet.add(item.value);
      });
      const accountOptions = Array.from(allAccountIdSet).map((accountId) => {
        return stategiesAccountOptions.find((item) => item.value === accountId);
      });

      setAccountOptions([defaultAccountOption, ...accountOptions]);
      console.log("accountOptions", accountOptions);

      // sort by id desc
      result.sort((a, b) => {
        return b.id - a.id;
      });

      // sort by last modified time, put latest first
      result.sort((a, b) => {
        return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
      });

      // sort by isActive, put active strategy first
      result.sort((a, b) => {
        if (a.isActive === b.isActive) {
          return 0;
        }
        return a.isActive ? -1 : 1;
      });

      // return new Array(10).fill(result).flat();
      setStrategyList(result);
      if (result) {
        const listElement = listRef.current;
        prevScrollTop.current = listElement.scrollTop; // Save scroll position
      }
      return result;
    },
    refetchInterval: 10 * 1000,
  });
  useEffect(() => {
    const listElement = listRef.current;

    if (listElement) {
      listElement.scrollTop = prevScrollTop.current; // Restore previous scroll position
    }
  }, [strategyListQuery.data]);

  const ids = displayStrategyList.map((item) => item.id);

  const handleSwtichStrategy = async (id: number, isActive: boolean) => {
    try {
      await strategiesApi.switchStrategy(id, isActive);
      message.success("保存成功");
    } catch (error) {
      message.detailedError(<ErrorMessage error={error} />);
    }
  };
  // batch close
  const onBatchClose = async () => {
    try {
      await strategiesApi.batchCloseStrategies(ids);
      message.success("关闭成功");
      strategyListQuery.refetch();
    } catch (error) {
      message.detailedError(<ErrorMessage error={error} />);
    }
  };

  const [selectedStrategy, setSelectedStrategy] = useState<StrategyResponse<VolumeExampleType> | null>(null);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  console.log("displayStrategyList", displayStrategyList);
  return (
    <>
      <Formik
        initialValues={{
          binanceEnabled: false,
          okexEnabled: false,
        }}
        validationSchema={Yup.object({})}
        onSubmit={(values) => {
          alert(JSON.stringify(values, null, 2));
        }}
      >
        {({ setFieldValue }) => {
          return (
            <Form className={styles.form}>
              <Flex flexWrap="wrap" width="100%">
                <Flex width="100%" justifyContent="space-between">
                  <Text my={10} fontSize="xl" fontWeight={600} textAlign="center" mt={2}>
                    机器人设置
                  </Text>
                </Flex>
                <Flex flexDir={"column"} gap={2} width={"100%"}>
                  <Flex alignItems="center" gap={2} mb={2} justifyContent="space-between" width={"100%"}>
                    <Flex gap={4} alignItems={"center"}>
                      <CSelect
                        classname={styles.select}
                        label={"策略类型"}
                        placeholder="策略类型"
                        options={typeOptions}
                        defaultValue={selectedTypeOption}
                        onChange={(value) => {
                          const selectedType = (value as any).value as StrategyType;
                          console.log("selectedType", selectedType);
                          setFieldValue("type", selectedType);
                          handleSetType(selectedType);
                        }}
                      />
                      {type === StrategyType.LowBuyHighSell && (
                        <RadioGroup onChange={handleSetSymbolFilter} value={symbolFilter}>
                          <Stack direction="row">
                            <Radio value="BTC">BTC</Radio>
                            <Radio value="ETH">ETH</Radio>
                            <Radio value="SOL">SOL</Radio>
                            {/* <Radio value="BNB">BNB</Radio> */}
                            <Radio value="DOGE">DOGE</Radio>
                            {/* <Radio value="PEPE">PEPE</Radio> */}
                          </Stack>
                        </RadioGroup>
                      )}
                      <CSelect
                        classname={styles.select}
                        label={"创建人"}
                        placeholder="创建人"
                        options={accountOptions}
                        defaultValue={accountOptions[0]}
                        onChange={(value) => {
                          const selectedAccountId = (value as any).value as number;
                          setSelectedAccountId(selectedAccountId);
                        }}
                      />
                    </Flex>

                    <CButton name="关闭所有" onClick={onBatchCloseConfirmOpen} />
                  </Flex>

                  <Flex></Flex>
                </Flex>
                <Flex className={styles.strategiesContainer} ref={listRef}>
                  {strategyListQuery.isLoading && (
                    <Flex justifyContent="center" alignItems="center" width="100%" height="100px">
                      <Spinner />
                    </Flex>
                  )}
                  {displayStrategyList?.length === 0 && (
                    <Flex justifyContent="center" alignItems="center" width="100%" height="100px">
                      暂无数据
                    </Flex>
                  )}
                  {displayStrategyList?.map((strategy) => {
                    let isEnabled = strategy.isActive;
                    console.log(isEnabled, strategy.validAfter);
                    const handleToggle = async () => {
                      await handleSwtichStrategy(strategy.id, !isEnabled);
                      // toggleActiveById(strategy.id);
                      strategyListQuery.refetch();
                    };
                    return (
                      <Box key={strategy.id}>
                        <ExchangeConfig
                          onDelete={strategyListQuery.refetch}
                          isEnabled={isEnabled}
                          toggleEnabled={handleToggle}
                          onOpenEdit={() => {
                            setSelectedStrategy(strategy);
                            setIsDetailModalOpen(true);
                          }}
                          onOpenOrder={() => {
                            setSelectedStrategy(strategy);
                            setIsOrderModalOpen(true);
                          }}
                          onOpenStatus={() => {
                            setSelectedStrategy(strategy);
                            setIsStatusModalOpen(true);
                          }}
                          {...strategy}
                        />
                      </Box>
                    );
                  })}
                </Flex>
              </Flex>
            </Form>
          );
        }}
      </Formik>
      <CModal size="3xl" title="策略详情" isOpen={isDetailModalOpen} onClose={() => setIsDetailModalOpen(false)}>
        <StrategyDetail
          strategy={selectedStrategy}
          onUpdated={() => {
            strategyListQuery.refetch();
            setIsDetailModalOpen(false);
          }}
        />
      </CModal>
      <CModal size="full" title="订单详情" isOpen={isOrderModalOpen} onClose={() => setIsOrderModalOpen(false)}>
        <Text>订单详情</Text>
        <TradeOrder hideFullScreenButton stragegyId={selectedStrategy?.id} paginated />
      </CModal>
      <CModal size="6xl" title="状态面板" isOpen={isStatusModalOpen} onClose={() => setIsStatusModalOpen(false)}>
        <StatusPanel stragegyId={selectedStrategy?.id} />
      </CModal>

      <AlertDialog isOpen={isBatchCloseConfirmOpen} leastDestructiveRef={cancelRef} onClose={onBatchCloseConfirmClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              确认关闭
            </AlertDialogHeader>

            <AlertDialogBody>
              <Flex>确定立即关闭以下机器人吗？</Flex>
              <Flex direction={"column"} maxHeight={400} overflowY={"auto"}>
                {displayStrategyList.map((item, index) => {
                  return (
                    <Flex key={index}>
                      ID: {item.id} -- {item.strategyName}
                    </Flex>
                  );
                })}
              </Flex>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onBatchCloseConfirmClose}>
                取消
              </Button>
              <Button
                onClick={() => {
                  onBatchCloseConfirmClose();
                  onBatchClose();
                }}
                ml={3}
              >
                确定
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
