import { Flex, StyleProps, Text } from "@chakra-ui/react";

const CField = (props: { label: string | number; value: string | number; classname?: string } & StyleProps) => {
  const { label, value, classname, ...rest } = props;
  return (
    <Flex gap={2} alignItems="center" className={classname} {...rest}>
      <Text width="max-content">{label}:</Text>
      <Text width="max-content">{value}</Text>
    </Flex>
  );
};

export default CField;
