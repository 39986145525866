import { Flex, Input, InputGroup, InputLeftElement, Spinner } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import CInput from "../CInput/CInput";
import { useExchangeInfoStore } from "src/store";
import { traderApi } from "src/api";
import { PairItem } from "src/api/types";
import { useState } from "react";
import { useMessage } from "src/hook/useMessage";
import { ErrorMessage } from "src/utils/ErrorMessage";
import styles from "./Limit.module.scss";

export default function SingleVolumeForm(props: { pair: PairItem; exchangeAccountId: number }) {
  const store = useExchangeInfoStore();
  const message = useMessage();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Formik
      initialValues={{
        totalAmount: "",
        targetPrice: "",
      }}
      validationSchema={Yup.object({
        totalAmount: Yup.string()
          .matches(/^\d*\.?\d*$/, "必须为数字")
          .required("必填字段"),
      })}
      onSubmit={async (values) => {
        if (isLoading) return;
        if (!store.selectedExchangeAccountId || store.selectedExchangeAccountId === -1) {
          alert("请选择交易账户");
          return;
        }
        const payload = {
          exchangeAccountId: Number(store.selectedExchangeAccountId),
          pairId: Number(store.selectedSpotPair.id),
          totalAmount: Number(values.totalAmount),
          targetPrice: values.targetPrice ? Number(values.targetPrice) : undefined,
        };
        console.log("tradeTokenV2 payload", payload);
        try {
          setIsLoading(true);
          const result = await traderApi.createSingleVolume(payload);
          console.log("result", result);
          message.success("下单成功");
        } catch (error) {
          message.detailedError(<ErrorMessage error={error} />);
          console.error("createSingleVolume error", error);
          console.error("createSingleVolume params: ", payload);
        } finally {
          setIsLoading(false);
        }
      }}
    >
      <Form style={{ width: "100%" }}>
        <Flex
          width="100%"
          flexWrap="wrap"
          alignItems={"center"}
          flexDirection={"column"}
          gap={2}
          rowGap={2}
          justifyContent="space-around"
        >
          <CInput
            classname={styles.input}
            label="刷量价格(可选)"
            name="targetPrice"
            type="text"
            placeholder="请输入刷量价格"
          />
          <CInput
            classname={styles.input}
            label="刷量数量(币)"
            name="totalAmount"
            type="text"
            placeholder="请输入刷量数量"
          />
        </Flex>
        <Flex justifyContent="center" mt={4}>
          <Flex width="auto">
            <InputGroup>
              {isLoading && (
                <InputLeftElement pointerEvents="none">
                  <Spinner size="sm" color="gray.300" />
                </InputLeftElement>
              )}
              <Input type="submit" width="200px" value={"下单"} cursor="pointer" disabled={isLoading} />
            </InputGroup>
          </Flex>
        </Flex>
      </Form>
    </Formik>
  );
}
