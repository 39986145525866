import { Flex } from "@chakra-ui/react";
import { Formik } from "formik";
import { Form } from "react-router-dom";
import { strategiesApi } from "src/api";
import { SingleSideExampleType, StrategyResponse, UpdateStrategyParams } from "src/api/types";
import { useExchangeInfoStore } from "src/store";
import CButton from "../CButton/CButton";
import CInput from "../CInput/CInput";
import styles from "./StrategyDetail.module.scss";
import * as Yup from "yup";
import { useExchangeAccounts } from "src/hook/useExchangeAccounts";
import CField from "src/component/CField/CField";
import { useMessage } from "src/hook/useMessage";
import CommonSingleSideForm from "../StrategyForms/CommonSingleSideForm";
import { ErrorMessage } from "src/utils/ErrorMessage";

type Props = {
  strategy: StrategyResponse<SingleSideExampleType>;
  onUpdated?: () => void;
};

export default function SingleSideDetail(props: Props) {
  const { strategy, onUpdated } = props;
  const message = useMessage();
  const accounts = useExchangeAccounts();
  const pairs = useExchangeInfoStore((state) => state.spotPairs);
  const pair = pairs.find((pair) => pair.id === strategy.pairId);
  const exchangeAccount = accounts.allExchangeAccounts.find((account) => account.id === strategy.exchangeAccountId);

  return (
    <Formik
      initialValues={{
        strategyName: strategy.strategyName,
        repeatIntervalMsMin: strategy.repeatIntervalMsMin,
        repeatIntervalMsMax: strategy.repeatIntervalMsMax,

        totalAmount: strategy.preference.totalAmount,
        priceTriggerMin: strategy.preference.priceTriggerMin,
        priceTriggerMax: strategy.preference.priceTriggerMax,
        minQuantity: strategy.preference.minQuantity,
        maxQuantity: strategy.preference.maxQuantity,
        side: strategy.preference.side,
      }}
      validationSchema={Yup.object({
        strategyName: Yup.string().required("Required"),
        
      })}
      onSubmit={async (values) => {
        console.log("====================================");
        console.log("values", values);
        console.log("====================================");
        alert(JSON.stringify(values, null, 2));
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form className={styles.form}>
            <Flex width="calc(100% - 20px)" flexDirection="column" gap={2}>
              <Flex alignItems="center" gap={2} mt={2}>
                <CField label={"交易方向"} value={values.side} />
              </Flex>
              <CommonSingleSideForm styles={styles} />
              <Flex alignItems="center" gap={2} mt={4}>
                <CButton
                  name="保存"
                  type="submit"
                  onClick={async () => {
                    const payload: UpdateStrategyParams<SingleSideExampleType> = {
                      strategyId: strategy.id,
                      strategyName: values.strategyName,
                      repeatIntervalMsMin: values.repeatIntervalMsMin,
                      repeatIntervalMsMax: values.repeatIntervalMsMax,
                      preference: {
                        totalAmount: Number(values.totalAmount),
                        priceTriggerMin: Number(values.priceTriggerMin),
                        priceTriggerMax: Number(values.priceTriggerMax),
                        minQuantity: Number(values.minQuantity),
                        maxQuantity: Number(values.maxQuantity),
                        side: (values.side as any).value,
                      },
                    };
                    try {
                      const result = await strategiesApi.updateStrategyV2(payload);
                      message.success("更新成功");
                      onUpdated && onUpdated();
                    } catch (error) {
                      message.detailedError(<ErrorMessage error={error} />);
                    }
                  }}
                />
              </Flex>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
}
