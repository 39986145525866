// Exchange info contains supported exchanges/pairs

import { useEffect } from "react";
import { cbPerpApi, exchangeInfoApi, ubPerpApi } from "src/api";
import { ExchangeType } from "src/config/exchanges";
import { PairType } from "src/config/pairs";
import { useExchangeInfoStore } from "src/store";

// infos are loaded in the following order:
// 1. get from localStorage
// 2.1 if step 1 succeeds, and sync with store
// 2.2 if step 1 is empty, get from config/exchanges.ts, and sync with store and localStorage
// 3. call api to update the info in store and localStorage

// why do we need to store exchange info in localStorage?
// to provide a better user experience, these infomation do not change frequently

export const useExchangeInfo = () => {
  const store = useExchangeInfoStore();

  useEffect(() => {
    // fetch exchanges and pairs
    const fetchExchanges = async () => {
      const exchangesResp = await exchangeInfoApi.fetchExchanges();
      const pairsResp = await exchangeInfoApi.fetchPairs();
      const ubPairsResp = await ubPerpApi.fetchPairs();
      const cbPairsResp = await cbPerpApi.fetchPairs();
      console.log("exchanges", exchangesResp);
      console.log("pairs", pairsResp);
      console.log("ubPairs", ubPairsResp);
      if (exchangesResp) {
        const newExchanges: ExchangeType[] = exchangesResp.map((item) => ({
          key: item.name,
          name: item.name,
          id: item.id,
        }));
        store.setExchanges(newExchanges);
      }
      if (pairsResp) {
        const newPairs: PairType[] = pairsResp.map((item) => ({
          quote: item.quote,
          base: item.base,
          id: item.id,
          symbol: item.symbol,
          exchanges: item.exchanges,
        }));
        store.setSpotPairs(newPairs);
      }
      if (ubPairsResp) {
        const newPairs: PairType[] = ubPairsResp.map((item) => ({
          quote: item.quote,
          base: item.base,
          id: item.id,
          exchanges: item.exchanges,
          symbol: item.symbol,
        }));
        store.setUbPerpPairs(newPairs);
      }
      if (cbPairsResp) {
        const newPairs: PairType[] = cbPairsResp.map((item) => ({
          quote: item.quote,
          base: item.base,
          id: item.id,
          exchanges: item.exchanges,
          symbol: item.symbol,
        }));
        store.setCbPerpPairs(newPairs);
      }
    };
    fetchExchanges();
  }, []);

  return {
    exchanges: store.exchanges,
    pairs: store.spotPairs,
    selectedExchangeKey: store.selectedExchangeKey,
  };
};
