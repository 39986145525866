import { Button, ButtonProps, StyleProps, ThemingProps } from "@chakra-ui/react";

type Props = {
  name: string;
  onClick?: () => void;
  classname?: string;
  size?: "sm" | "md" | "lg";
  type?: "submit" | "reset" | "button";
} & StyleProps &
  ThemingProps &
  ButtonProps;

const CButton = (props: Props) => {
  const { name, onClick, classname, size, type, ...rest } = props;
  return (
    <Button onClick={onClick} size={size || "sm"} className={classname} type={type || "button"} {...rest}>
      {name}
    </Button>
  );
};
export default CButton;
