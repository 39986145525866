import { useToast } from "@chakra-ui/react";
import React from "react";

export const useMessage = () => {
  const toast = useToast();

  return {
    info: (message: string) => {
      toast({
        title: message,
        status: "info",
        duration: 2000,
        isClosable: true,
      });
    },
    success: (message: string) => {
      toast({
        title: message,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    },
    warning: (message: string) => {
      toast({
        title: message,
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
    },
    error: (message: string) => {
      toast({
        title: message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    },
    detailedError: (message: React.ReactElement) => {
      toast({
        title: message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    },
  };
};
