import { Button, Flex } from "@chakra-ui/react";
import CField from "../CField/CField";
import { balanceToNumber, formatBalance } from "src/utils/string";

export default function BalanceInfo(props: {
  base: string;
  quote: string;
  frozenBase: string | number;
  frozenQuote: string | number;
  baseBalance: string | number;
  quoteBalance: string | number;
  refetch: () => void;
}) {
  const { base, quote, frozenBase, frozenQuote, baseBalance, quoteBalance, refetch } = props;
  return (
    <Flex flexDirection="column" gap={4} my={6}>
      <Flex flexWrap="wrap" gap={2} rowGap={2} justifyContent="end">
        <Button size="sm" onClick={refetch}>
          刷新
        </Button>
      </Flex>
      <Flex justifyContent={"space-between"}>
        <Flex flexWrap="wrap" gap={2} rowGap={2} justifyContent="space-between" flexDir={"column"}>
          <CField label={`${base} 可用余额`} value={baseBalance} />
          <CField label={`${base} 冻结余额`} value={frozenBase} />
          <CField label={`${base} 总余额`} value={balanceToNumber(baseBalance) + balanceToNumber(frozenBase)} />
        </Flex>
        <Flex flexWrap="wrap" gap={2} rowGap={2} justifyContent="space-between" flexDir={"column"}>
          <CField label={`${quote} 可用余额`} value={quoteBalance} />
          <CField label={`${quote} 冻结余额`} value={frozenQuote} />
          <CField label={`${quote} 总余额`} value={balanceToNumber(quoteBalance) + balanceToNumber(frozenQuote)} />
        </Flex>
      </Flex>
    </Flex>
  );
}
