import { Flex } from "@chakra-ui/react";
import CInput from "../CInput/CInput";

export default function CommonVolumeForm(props: { styles: any }) {
  const { styles } = props;
  return (
    <>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput classname={styles.inputField} label="策略名称" name="strategyName" />
      </Flex>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput classname={styles.inputField} label="最小币数量" name="minBaseAmount" type="number" />
      </Flex>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput classname={styles.inputField} label="最大币数量" name="maxBaseAmount" type="number" />
      </Flex>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput classname={styles.inputField} label="每小时刷量上限（USDT）" name="hourlyAmountLimit" type="number" />
      </Flex>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput
          classname={styles.inputField}
          label="补买单价格波动(推荐 1%)"
          name="refillBuyPert"
          type="number"
          rightIndicator="%"
        />
      </Flex>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput
          classname={styles.inputField}
          label="补卖单价格波动(推荐 1%)"
          name="refillSellPert"
          type="number"
          rightIndicator="%"
        />
      </Flex>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput classname={styles.inputField} label="每次刷量补单次数限制" name="refillLimit" type="number" />
      </Flex>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput classname={styles.inputField} label="每小时补单次数上限" name="refillHourlyLimit" type="number" />
      </Flex>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput
          classname={styles.inputField}
          label="买卖比例"
          description="默认50%，也就是随机一半买单一半卖单。如果是70%，那么就是70%的买单和30%的卖单"
          name="buySellRand"
          rightIndicator="%"
          type="number"
        />
      </Flex>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput
          classname={styles.inputField}
          label="随机翻倍"
          description="单次刷量金额翻倍的概率（取倍数范围最小值和倍数范围最大值）。默认0，也就是不开启"
          name="execRand"
          type="number"
          rightIndicator="%"
        />
      </Flex>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput
          classname={styles.inputField}
          label="倍数范围最小值"
          description="倍数范围最小值。默认1（1倍，等于没开启）"
          name="rangeMin"
          type="number"
        />
      </Flex>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput
          classname={styles.inputField}
          label="倍数范围最大值"
          description="倍数范围最大值。默认1（1倍，等于没开启）"
          name="rangeMax"
          type="number"
        />
      </Flex>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput
          classname={styles.inputField}
          label="价差空间"
          name="priceGap1"
          type="number"
          description="买1和卖1之间的价差空间，低于这个值 不会进行刷量"
        />
      </Flex>
      <Flex>
        <CInput classname={styles.inputField} label="重复间隔(最小值)" name="repeatIntervalMsMin" type="number" />
      </Flex>

      <Flex>
        <CInput classname={styles.inputField} label="重复间隔(最大值)" name="repeatIntervalMsMax" type="number" />
      </Flex>
    </>
  );
}
