import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import AccountSelect from "src/component/AccountSelect/AccountSelect";
import ExchangeSelect from "src/component/ExchangeSelect/ExchangeSelect";
import PairSelect from "src/component/PairSelect/PairSelect";
import TradingViewWidget from "src/component/TradingViewWidget";
import { useExchangeInfoStore } from "src/store";
import { getPairSymbol, getTradingviewCexName } from "src/store/utils";
import { isSelectedEmpty } from "src/utils/states";
import { ubPerpApi } from "src/api";
import { useQuery } from "@tanstack/react-query";
import MarketOpenPosition from "./MarketOpenPosition";
import CTable from "src/component/CTable/Ctable";
import { orderCols, REFETCH_INTERVAL } from "./perpConfigs";
import CButton from "src/component/CButton/CButton";
import { FetchOrderResponse, FetchPositionResponse, MarginType, TradeType, UbPositionConfig } from "src/api/types";
import ClosePositionAlert from "./ClosePositionAlert";
import { useMessage } from "src/hook/useMessage";
import { ErrorMessage } from "src/utils/ErrorMessage";
import UpdateTPAndSL from "./UpdateTPAndSL";
import AdjustMargin from "./AddMargin";

const positionCols = [
  {
    key: "contract",
    title: "币对",
  },
  {
    key: "size",
    title: "大小",
  },
  {
    key: "leverage",
    title: "杠杆",
  },
  {
    key: "entry_price",
    title: "开仓价格",
  },
  {
    key: "liq_price",
    title: "强平价格",
  },
  {
    key: "mark_price",
    title: "标记价格",
  },
  {
    key: "unrealised_pnl",
    title: "未实现盈亏",
  },
  {
    key: "open_time",
    title: "开仓时间",
  },
  {
    key: "side",
    title: "方向",
    render: (item: any) => {
      const displayTitle = item?.side.toLowerCase() === "buy" ? "多头" : "空头";
      return <span>{displayTitle}</span>;
    },
  },
];

export default function UbPerpetual() {
  const message = useMessage();
  const { selectedExchangeKey, selectedUbPerpPair: selectedPair, selectedExchangeAccountId } = useExchangeInfoStore();
  const [selectedPosition, setSelectedPosition] = useState<FetchPositionResponse | null>(null);
  const [marginType, setMarginType] = useState<MarginType | null>(null);
  const [positionSide, setPositionSide] = useState(false);
  const [leverage, setLeverage] = useState(0);

  const enableQuery = useMemo(() => {
    return !isSelectedEmpty(selectedExchangeAccountId) && !!selectedExchangeKey && !!selectedPair;
  }, [selectedExchangeAccountId, selectedExchangeKey, selectedPair]);

  useQuery({
    queryKey: ["ub-config", selectedPair?.id, selectedExchangeKey, selectedExchangeAccountId],
    queryFn: async () => {
      try {
        const res = await ubPerpApi.fetchPositionConfig({
          exchangeAccountId: selectedExchangeAccountId,
          exchangeName: selectedExchangeKey,
          symbol: selectedPair?.symbol,
        });
        if (res) {
          setLeverage(Number(res.leverage));
        }
      } catch (error) {
        message.detailedError(<ErrorMessage error={error as any} />);
        return {};
      }
    },
    enabled: !!selectedPair && !!selectedExchangeKey && !!selectedExchangeAccountId,
    refetchInterval: REFETCH_INTERVAL,
  });

  // Query for fetching balance
  const balanceQuery = useQuery<number>({
    enabled: enableQuery,
    queryKey: ["ub-balance", selectedExchangeAccountId],
    queryFn: async () => {
      try {
        return await ubPerpApi.fetchPerpBalance({
          exchangeAccountId: selectedExchangeAccountId,
          exchangeName: selectedExchangeKey,
        });
      } catch (error) {
        message.detailedError(<ErrorMessage error={error as any} />);
        return 0; // Return default balance
      }
    },
    refetchInterval: REFETCH_INTERVAL,
  });

  // Query for fetching market price
  const marketPriceQuery = useQuery({
    enabled: enableQuery,
    queryKey: ["ub-symbol", selectedPair?.symbol],
    queryFn: async () => {
      try {
        return await ubPerpApi.requestMarketPrice({
          exchangeAccountId: selectedExchangeAccountId,
          exchangeName: selectedExchangeKey,
          symbol: selectedPair?.symbol,
        });
      } catch (error) {
        message.detailedError(<ErrorMessage error={error as any} />);
        return ""; // Return default value
      }
    },
    refetchInterval: REFETCH_INTERVAL,
  });

  // Query for fetching orders
  const ordersQuery = useQuery<FetchOrderResponse[]>({
    enabled: enableQuery,
    queryKey: ["ub-orders", selectedExchangeAccountId],
    queryFn: async () => {
      try {
        let result = await ubPerpApi.fetchPerpOrders({
          exchangeAccountId: selectedExchangeAccountId,
          exchangeName: selectedExchangeKey,
        });

        if (!result) {
          return [];
        }

        const filteredData = result.filter((position) => {
          return position.symbol.includes(selectedPair.base);
        });

        return filteredData;
      } catch (error) {
        message.detailedError(<ErrorMessage error={error as any} />);
        return []; // Return empty array
      }
    },
    refetchInterval: REFETCH_INTERVAL,
  });

  // Query for fetching positions
  const positionQuery = useQuery<FetchPositionResponse[]>({
    enabled: enableQuery,
    queryKey: ["ub-position", selectedExchangeAccountId],
    queryFn: async () => {
      try {
        const data = await ubPerpApi.fetchPosition({
          exchangeAccountId: selectedExchangeAccountId,
          exchangeName: selectedExchangeKey,
        });

        // 如果数据为空或无效，直接返回空数组
        if (!data) {
          return [];
        }

        // 过滤符合条件的仓位数据
        return data.filter((oneData) => oneData.symbol.includes(selectedPair.base));
      } catch (error) {
        // 处理异常并返回空数组
        message.detailedError(<ErrorMessage error={error as any} />);
        return []; // Return empty array in case of error
      }
    },
    refetchInterval: REFETCH_INTERVAL,
  });

  const currentPosition = useMemo(() => {
    const positions = positionQuery.data || [];
    const currentPositions = positions.filter((item) => {
      if (!selectedPair) return false;
      return item.symbol === selectedPair?.symbol;
    });
    return currentPositions[0];
  }, [positionQuery.data, selectedPair]);

  const {
    isOpen: isClosePositionAlertOpen,
    onClose: onCloseClosePositionAlert,
    onOpen: onOpenClosePositionAlert,
  } = useDisclosure();

  const { isOpen: isUpdateTPAndSLOpen, onClose: onCloseUpdateTPAndSL, onOpen: onOpenUpdateTPAndSL } = useDisclosure();

  const { isOpen: isAdjustMarginOpen, onClose: onCloseAdjustMargin, onOpen: onOpenAdjustMargin } = useDisclosure();

  const handleSetMargin = () => {
    setSelectedPosition(currentPosition);
    onOpenAdjustMargin();
  };

  const positionOperationCol = (item: any) => {
    return (
      <Flex gap={"2px"}>
        <CButton
          colorScheme={"red"}
          name={"平仓"}
          onClick={() => {
            setSelectedPosition(item);
            onOpenClosePositionAlert();
          }}
        />
        <CButton
          colorScheme={"green"}
          name={"止盈止损"}
          onClick={() => {
            setSelectedPosition(item);
            onOpenUpdateTPAndSL();
          }}
        />
      </Flex>
    );
  };

  const orderOperationCol = (item: FetchOrderResponse) => {
    return (
      <Flex>
        <CButton
          colorScheme={"red"}
          name={"取消"}
          onClick={async () => {
            try {
              await ubPerpApi.requestCancelOrder({
                exchangeName: selectedExchangeKey,
                exchangeAccountId: selectedExchangeAccountId,
                orderId: item.id,
                type: item.type,
                symbol: item.symbol,
              });
            } catch (error) {
              message.detailedError(<ErrorMessage error={error as any} />);
            }
          }}
        />
      </Flex>
    );
  };

  return (
    <>
      <Flex
        width="100%"
        maxH="calc(100vh - 140px)"
        px={2}
        justifyContent="start"
        gap={6}
        flexDir={"column"}
        marginY={"auto"}
      >
        <Flex width={"100%"} height={"auto"} justifyContent={"space-between"} alignItems={"stretch"}>
          <Flex justifyContent={"space-between"} gap={12} width={"100%"}>
            <Flex
              width="calc(50% - 20px)"
              maxW="600px"
              flexDirection="column"
              overflow="scroll"
              gap={2}
              style={{ scrollbarWidth: "none" }}
            >
              <Box fontSize={"32"} color={"#FF6F61"}>
                U本位合约
              </Box>
              <Flex alignItems="center" gap={2}>
                <PairSelect tradeType={TradeType.UB_PERP} />
              </Flex>
              <Flex alignItems="center" gap={2}>
                <ExchangeSelect tradeType={TradeType.UB_PERP} />
              </Flex>
              <Flex alignItems="center" gap={2}>
                <AccountSelect />
              </Flex>
              <Flex height={"40px"}>
                余额：{" "}
                <Flex justifyContent={"space-between"}>
                  <Text>{balanceQuery?.data || 0} USDT</Text>
                </Flex>
              </Flex>
              <Flex alignItems="center" gap={2} border="1px solid gray" borderRadius="10px">
                <MarketOpenPosition
                  hasPosition={!!currentPosition}
                  defaultLeverage={leverage}
                  marketPrice={marketPriceQuery.data}
                  marginType={marginType}
                  setLeverage={setLeverage}
                  setMarginType={setMarginType}
                  positionSide={positionSide}
                  balance={Number(balanceQuery.data)}
                />
              </Flex>
            </Flex>
            <Flex width="calc(50% - 20px)" flexGrow={1} flexDirection="column">
              <Flex height="100%">
                <TradingViewWidget
                  pairName={getPairSymbol(selectedPair?.base, selectedPair?.quote)}
                  cexName={getTradingviewCexName(selectedExchangeKey)}
                  isPerpetual={true}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex width="100%" flexDirection="column" gap={2} border="1px solid gray" borderRadius="10px">
          <Tabs>
            <TabList>
              <Tab>当前委托 {ordersQuery.data?.length ?? 0}</Tab>
              <Tab>当前持仓 {positionQuery.data?.length ?? 0}</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <CTable
                  items={ordersQuery.data || []}
                  cols={orderCols}
                  showHeader
                  operationCol={orderOperationCol}
                  operationSide="left"
                />
              </TabPanel>
              <TabPanel>
                <CTable
                  items={positionQuery.data || []}
                  cols={positionCols}
                  showHeader
                  operationSide="left"
                  operationCol={positionOperationCol}
                  clickableKey="margin"
                  clickCallback={handleSetMargin}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
      <ClosePositionAlert
        positionItem={selectedPosition}
        isOpen={isClosePositionAlertOpen}
        onClose={onCloseClosePositionAlert}
        submitForm={function (payload: { amount: number }): void {
          throw new Error("Function not implemented.");
        }}
      />
      <UpdateTPAndSL
        positionItem={selectedPosition}
        isOpen={isUpdateTPAndSLOpen}
        onClose={onCloseUpdateTPAndSL}
        submitForm={() => {}}
        marketPrice={marketPriceQuery.data}
      />

      <AdjustMargin
        positionItem={selectedPosition}
        isOpen={isAdjustMarginOpen}
        onClose={onCloseAdjustMargin}
        marginType={marginType}
      />
    </>
  );
}
