// auth
type AuthResponse = {
  id: number;
  accountId: number;
  token: string;
  validUntil: string;
  createdAt: string;
  updatedAt: string;
};

// accounts
type MyInfoResponse = {
  id: number;
  username: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
};

type AccountResponse = {
  id: number;
  username: string;
  roles: RoleResponse[];

  createdAt: string;
  updatedAt: string;
};

type CreateAccountParam = {
  username: string;
  password: string;
  roleTypes: RoleType[];
};
type CreateAccountResponse = {
  id: number;
  username: string;
  isActive: boolean;
  roleTypes: RoleResponse[];

  createdAt: string;
  updatedAt: string;
};

type ExchangeAccount = {
  id: number;
  exchangeId: number;
  alias: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
};

type SelfExchangeAccount = {
  perId: number;
} & ExchangeAccount;

export enum RoleType {
  Admin = "Admin",
  User = "Trader",
  Manager = "Manager",
}

type RoleResponse = {
  id: number;
  accountId: number;
  type: RoleType;
  createdAt: string;
  updatedAt: string;
};

type accountBalanceHistoryType = {
  name:string;
  free:number;
  lock:number;
  precision:number;
}

type AccountBalanceHistoryResponseType = {
  exchangeAccountId:number;
  createdAtHour:string;
  balances:accountBalanceHistoryType[];
}

// exchange info, exchanges/accounts/pairs
type ExchangeItem = {
  id: number;
  name: string;
  isSandbox: boolean;
};
type ExchangeResponse = ExchangeItem[];

type ExchangeAccountResponse = ExchangeAccount[];

type PairItem = {
  id: number;
  base: string;
  quote: string;
  symbol?: string;
  exchanges?: string[];
};
type PairResponse = PairItem[];

// trade order
type TradeOrderType = {
  executedAmount:number;  
  id: number;
  uniqueId: string;
  accountId: number;
  exchangeAccountId: number;
  pairId: number;
  remoteOrderId: string;
  remoteOrderRaw?: RemoteOrder;
  type: string;
  side: string;
  baseAmount: string;
  expectedPrice: string;
  expectedCost: string;
  source?: string;
  sourceId?: number;
  createdAt: string;
  updatedAt: string;

  fee?: number;
  feeCurrency?: string;
  price?: string;
};

type RemoteOrder = {
  id: string;
  clientOrderId: string;
  datetime: string;
  timestamp: number;
  lastTradeTimestamp: number;
  status: string;
  symbol: string;
  type: string;
  timeInForce: string;
  side: string;
  price: number;
  average: number;
  amount: number;
  filled: number;
  remaining: number;
  cost: number;
  trades: any[];
  fee: {
    currency: string;
    cost: number;
    rate: number;
  };
  info: any;
};

type CreateExchangeAccountParams = {
  exchangeId: number;
  alias: string;
  apiKey: string;
  secret: string;
  pem?: string;
};

type UpdateExchangeAccountParams = {
  id: number;
} & CreateExchangeAccountParams;

type CreateExchangeAccountResponse = {
  id: number;
  exchangeId: number;
  alias: string;

  createdAt: string;
  updatedAt: string;
};

export enum StrategyType {
  VolumeMaking = "VolumeMaking",
  SingleSide = "SingleSide",
  EatOrder = "EatOrder",
  LowBuyHighSell = "LowBuyHighSell",
  AutoSell = "AutoSell",
  BigOrderAlarm = "BigOrderAlarm",
  Dca = "Dca",
  ReduceGap = "ReduceGap",
  DepthBidAndOffer = "AddDepthV1",
}
export enum StrategyKey {
  Volume = "VolumeMaking",
  OneSide = "SingleSide",
  Eat = "EatOrder",
  LowBuyHighSell = "LowBuyHighSell",
  AutoSell = "AutoSell",
  Dca = "Dca",
  Stuck = "BigOrderAlarm",
  BidAndOffer = "DepthBidAndOffer",
}

export enum MarginType {
  // 填ISOLATED或者CROSSED，CROSSED=全仓
  Isolated = "ISOLATED",
  Crossed = "CROSSED",
}

type CreateStrategyParams<T> = {
  pairId: number;
  exchangeAccountId: number;
  type: StrategyType;

  strategyName: string;
  repeatIntervalMsMin?: number;
  repeatIntervalMsMax?: number;
  validAfter?: string;

  preference: T;
};

type UpdateStrategyParams<T> = {
  strategyId: number;
  strategyName: string;
  validAfter?: string;
  repeatIntervalMsMin?: number;
  repeatIntervalMsMax?: number;
  preference: T;
};

type ExampleType = {
  type: StrategyType;
};

type VolumeExampleType = {
  minBaseAmount: number;
  maxBaseAmount: number;
  hourlyAmountLimit: number;
  refillBuyPert: number;
  refillSellPert: number;
  refillLimit: number;
  refillHourlyLimit: number;
  buySellRand: number; // default 0.5
  execRand: number; // default 0
  rangeMin: number; // default 1
  rangeMax: number; // default 1
  priceGap1: number;
  priceRand1?: boolean;
} & ExampleType;
type Intervals = "5m" | "15m" | "30m" | "1h";
type LowBuyHighSellExampleType = {
  type: StrategyType.LowBuyHighSell;
  interval: Intervals;
  buyAmount: number;
  buyCount: number;
  dropPerOfBenefits: number;
  perOfBenefits: number;
  round4: number; //就是一共执行多少次买卖周期，执行完后停止
  // 触发价格(低值):
  // 处于价格区间，才会执行提交买入挂单
  priceLow4: number;
  // 触发价格(高值):
  // 处于价格区间，才会执行提交买入挂单
  priceHigh4: number;
  // 是否自动复利，默认否，基本上测试服专用
  isAutoCompound: boolean
};

type AutoSellExampleType = {
  type: StrategyType.AutoSell;
  timeWindows5: number;
  upPer5: number;
  down5Market: number;
  sellAmount5: number;
};

type StrategyResponse<T> = {
  id: number;
  strategyName: string;
  accountId: number;
  accountName: string;
  exchangeAccountId: number;
  pairId: number;
  type: StrategyType;
  validAfter?: string;
  repeatIntervalMsMin: number;
  repeatIntervalMsMax: number;
  preference: T;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
};

type GetBalanceItem = {
  name: string;
  free: number;
  lock: number;
  precision: number;
};

type MultiGetBalanceResponse = {
  id: number;
  exchangeName: string;
  alias: string;
  balances: GetBalanceItem[];
};

export enum TradeDirection {
  Buy = "buy",
  Sell = "sell",
}
export enum TradeType {
  SPOT = "spot",
  UB_PERP = "ub_perp",
  CB_PERP = "cb_perp",
}
export enum OrderType {
  LIMIT = "limit",
  MARKET = "market",
}

type TradeTokenParams = {
  pairId: number;
  exchangeAccountId: number;
  side: TradeDirection;
  totalAmount: number;
  orderNum: number;
  startPrice: number;
  endPrice: number;
};
type PumpOrSmashParams = {
  pairId: number;
  side: TradeDirection;
  totalAmount: number;
  price: number;
};

type OpenListingType = {
  date: string;
  orderId: string;
  pair: string;
  type: string;
  side: string;
  price: number;
  baseAmount: number;
  executedAmount: number;
};

type SingleSideExampleType = {
  totalAmount: number;
  priceTriggerMin: number;
  priceTriggerMax: number;
  minQuantity: number;
  maxQuantity: number;
  side: TradeDirection;
};

type EatOrderExampleType = {
  eatSide: TradeDirection;
  topN: number;
  topNEatRatio: number;
  eatTotalAmount: number;
  rangeAmountMinPer: number;
  rangeAmountMaxPer: number;
  orderNumberPer: number;
  orderIntervalMsPer: number;
  eatPriceTrigger: number;
};
// {
//   "exchangeName": "gateio",
//   "exchangeAccounts": [
//     {
//       "exchangeAccountId": 5,
//       "alias": "gate_panda"
//     }
//   ]
// },
type MuitiCreateSupportExResp = {
  exchangeName: string;
  exchangeAccounts: {
    exchangeAccountId: number;
    alias: string;
  }[];
}[];
type MuitiCreateEstimateResp = {
  exchangeName: string;
  baseAmount: number;
  pricePercentage: number;
  lastUpdated: string;
  red: boolean;
  targetPrice: number;
  marketPrice: number;
  // pricePercentage
  // number
  // 价差波动比例
  // 必需
  // 如果展示百分比%的话，需要X100

  // lastUpdated
  // string
  // 价格最新时间
}[];

type DcaExampleType = {
  priceLow7: number;
  priceHigh7: number;
  firstPrice7: number;
  priceGap7: number;
  orderNum7: number;
  totalAmount7: number;
  profitGap7: number;
  stopAndSell7: boolean;
  sellOrderNum7: number;
  sellInterval7: number;

  profitAlone: boolean;
};

type ReduceGapExampleType = {
  type: StrategyType.ReduceGap;
  diffInPrice8: number;
};

type DepthBidAndOfferExampleType = {
  minDiffInPrice9: number;
  maxDiffInPrice9: number;
  totalBaseAmount9: number;
  orderNum9: number;
};

type OpenPositionRequest = {
  exchangeName: string;
  exchangeAccountId: number;
  symbol: string;
  side: TradeDirection;
  amount: number;
  openPrice: number;
};
type OpenPositionResponse = any;
type ClosePositionRequest = {
  exchangeName: string;
  exchangeAccountId: number;
  symbol: string;

  // "closePrice": 0.3,
  // "amount": "1",
  // "side": "Sell"
  closePrice: number;
  amount: number;
  side: any;
};
type ClosePositionResponse = any;

type PerpBaseRequest = {
  exchangeName: string;
  exchangeAccountId: number;
};
type FetchPositionResponse = {
  contract: string;
  symbol: string;
  size: number;
  leverage: string;
  entry_price: string;
  position_size:string;
  liq_price: string;
  mark_price: string;
  unrealised_pnl: string;
  open_time: number;
  side: string;
  margin:string
};
type FetchOrderResponse = {
  id: string;
  orderId: string;
  create_time: string;
  contract: string;
  symbol: string;
  size: string;
  side: string;
  price: string;
  type: string;
  unrealised_pnl:string;
  position_size:string;
};

type CbPerpBalanceResponse = {
  asset: string;
  balance: string;
  withdrawAvailable: string;
  crossWalletBalance: string;
  crossUnPnl: string;
  availableBalance: string;
  updateTime: number;
};

type CbPositionConfig = {
  symbol: string;
  positionAmt: string;
  initialMargin: string;
  maintMargin: string;
  unrealizedProfit: string;
  positionInitialMargin: string;
  openOrderInitialMargin: string;
  leverage: string;
  isolated: boolean;
  positionSide: string;
  entryPrice: string;
  breakEvenPrice: string;
  updateTime: number;
  maxQty: string;
};

type UbPositionConfig = {
  symbol: string;
  leverage: string;
  isolated: boolean;
  positionSide: string;
};

type CBHistoryPositionResponse = {
  symbol:string;
  id:string;
  orderId:string;
  pair:string;
  side:string;
  price:string;
  qty:string;
  realizedPnl:string;
  marginAsset:string;
  baseQty:string;
  timeStr:string;
  positionSide:string;
}

export type {
  CbPositionConfig,
  UbPositionConfig,
  CbPerpBalanceResponse,
  OpenPositionRequest,
  OpenPositionResponse,
  ClosePositionRequest,
  ClosePositionResponse,
  PerpBaseRequest,
  FetchPositionResponse,
  AuthResponse,
  AccountResponse,
  MyInfoResponse,
  ExchangeItem,
  ExchangeResponse,
  ExchangeAccount,
  ExchangeAccountResponse,
  PairItem,
  PairResponse,
  RoleResponse,
  TradeOrderType,
  RemoteOrder,
  CreateExchangeAccountParams,
  UpdateExchangeAccountParams,
  CreateExchangeAccountResponse,
  CreateAccountParam,
  CreateAccountResponse,
  CreateStrategyParams,
  ExampleType,
  VolumeExampleType,
  UpdateStrategyParams,
  StrategyResponse,
  GetBalanceItem,
  TradeTokenParams,
  OpenListingType,
  SingleSideExampleType,
  EatOrderExampleType,
  PumpOrSmashParams,
  MuitiCreateSupportExResp,
  MuitiCreateEstimateResp,
  MultiGetBalanceResponse,
  LowBuyHighSellExampleType,
  Intervals,
  AutoSellExampleType,
  DcaExampleType,
  SelfExchangeAccount,
  ReduceGapExampleType,
  DepthBidAndOfferExampleType,
  FetchOrderResponse,
  CBHistoryPositionResponse,
  AccountBalanceHistoryResponseType
};
