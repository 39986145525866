import { TOKEN_KEY } from "src/consts";

export const isSelectedEmpty = (selected: any) => {
  return !selected || selected === -1;
};

export const resetLocalStorageExceptForToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  localStorage.clear();
  localStorage.setItem(TOKEN_KEY, token);
};
