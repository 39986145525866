import { api, ApiResponse } from "./base";
import {
  AutoSellExampleType,
  CreateStrategyParams,
  DcaExampleType,
  DepthBidAndOfferExampleType,
  EatOrderExampleType,
  LowBuyHighSellExampleType,
  ReduceGapExampleType,
  SingleSideExampleType,
  StrategyResponse,
  StrategyType,
  TradeOrderType,
  UpdateStrategyParams,
  VolumeExampleType,
} from "./types";

export const fetchStrategyStatus = async (id: number) => {
  // /v2/strategies/status
  const res = await api.post<ApiResponse<any>>(`/v2/strategies/status`, {
    strategyId: id,
  });
  return res.data.data;
};

export const createVolumeStrategy = async (payload: CreateStrategyParams<VolumeExampleType>) => {
  const res = await api.post<ApiResponse<any>>("/v2/strategies/create", payload);
  return res.data.data;
};

export const updateVolumeStrategyV2 = async (payload: UpdateStrategyParams<VolumeExampleType>) => {
  const res = await api.post<ApiResponse<any>>(`/v2/strategies/update`, payload);
  return res.data.data;
};

export const createStrategyV2 = async (
  payload: CreateStrategyParams<
    | SingleSideExampleType
    | EatOrderExampleType
    | LowBuyHighSellExampleType
    | AutoSellExampleType
    | DcaExampleType
    | ReduceGapExampleType
    | DepthBidAndOfferExampleType
  >
) => {
  const res = await api.post<ApiResponse<any>>("/v2/strategies/create", payload);
  return res.data.data;
};

export const updateStrategyV2 = async (
  payload: UpdateStrategyParams<
    | SingleSideExampleType
    | EatOrderExampleType
    | LowBuyHighSellExampleType
    | AutoSellExampleType
    | DcaExampleType
    | ReduceGapExampleType
    | DepthBidAndOfferExampleType
  >
) => {
  const res = await api.post<ApiResponse<any>>(`/v2/strategies/update`, payload);
  return res.data.data;
};

export const switchStrategy = async (id: number, enabled: boolean) => {
  const res = await api.post<ApiResponse<any>>(`/v2/strategies/switch`, {
    strategyId: id,
    isActive: enabled ? 1 : 0,
  });
  return res.data.data;
};

export const batchCloseStrategies = async (ids: number[]) => {
  const res = await api.post<ApiResponse<any>>(`/v2/strategies/batchClose`, {
    strategyIds: ids,
  });
  return res.data.data;
};

export const fetchStrategies = async (payload: { type: StrategyType; pairId?: number }) => {
  const { type, pairId } = payload;
  const res = await api.post<ApiResponse<StrategyResponse<VolumeExampleType>[]>>("/v2/strategies/list", {
    take: 1000,
    skip: 0,
    type,
    pairId: pairId ? pairId : undefined,
  });
  return res.data.data;
};

export const fetchOrdersOfStrategy = async (strategyId: number, isStrategy?: boolean) => {
  const body = {
    strategyId,
  };
  if (!isStrategy) {
    body["source"] = "Manual";
  }
  // const res = await api.get<ApiResponse<TradeOrderType[]>>(`/orders/self`, {
  const res = await api.post<ApiResponse<TradeOrderType[]>>(`/v2/orders/list`, body);
  return res.data.data;
};

export const deleteStrategy = async (id: number) => {
  const res = await api.post<ApiResponse<any>>(`/v2/strategies/del`, {
    strategyId: id,
  });
  return res.data.data;
};
