import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { ActionMeta, OnChangeValue, Select } from "chakra-react-select";
import styles from "./index.module.scss";
import classNames from "classnames";
import { QuestionIcon } from "@chakra-ui/icons";

type Option = { label: string | number; value: string | number };
type Prop = {
  label: string | number;
  options: Option[];
  defaultValue?: Option;
  isMulti?: boolean;
  value?: Option;
  onChange?: (selected: OnChangeValue<Option, boolean>, actionMeta: ActionMeta<Option>) => void;
  required?: boolean;
  classname?: string;
  children?: React.ReactNode;
  hideLabel?: boolean;
  placeholder?: string;
  id?: string;
  name?: string;
  description?: string;
};

const CSelect = ({
  label,
  placeholder,
  hideLabel,
  required,
  onChange,
  options,
  isMulti,
  value,
  defaultValue,
  classname,
  id,
  name,
  description,
}: Prop) => {
  const formId = id || name;
  return (
    <Flex
      width="100%"
      maxWidth="800px"
      justifyContent="space-between"
      className={classNames(styles.container, classname)}
    >
      {!hideLabel && (
        <Flex alignItems="center">
          <Text as="label" htmlFor={formId} className={styles.label}>
            {label}
            {required && (
              <Box color="red" as="span">
                *
              </Box>
            )}
            ：
          </Text>
          {description && (
            <Tooltip label={description} aria-label="A tooltip">
              <QuestionIcon cursor="pointer" />
            </Tooltip>
          )}
        </Flex>
      )}
      <Box flexGrow={1} maxWidth="400px">
        <Select
          className={styles.select}
          defaultValue={defaultValue}
          isMulti={isMulti}
          options={options}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          noOptionsMessage={() => <span>无数据</span>}
          selectedOptionStyle="check"
          chakraStyles={{
            dropdownIndicator: (provided) => ({
              ...provided,
              bg: "transparent",
              p: 0,
              w: 6,
              mx: 2,
              cursor: "inherit",
            }),
            indicatorSeparator: () => ({
              display: "none",
            }),
          }}
        />
      </Box>
    </Flex>
  );
};

export default CSelect;
