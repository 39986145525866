import { QuestionIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Input,
  InputGroup,
  Flex,
  Text,
  FormControl,
  FormErrorMessage,
  VStack,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ubPerpApi } from "src/api";
import { FetchPositionResponse, MarginType } from "src/api/types";
import { useMessage } from "src/hook/useMessage";
import { useExchangeInfoStore } from "src/store";

type AdjustMarginAlertProps = {
  positionItem: FetchPositionResponse | null;
  isOpen: boolean;
  onClose: () => void;
  marginType: MarginType;
};

export default function AdjustMargin(props: AdjustMarginAlertProps) {
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const message = useMessage();
  const store = useExchangeInfoStore();
  const { selectedExchangeKey, selectedUbPerpPair: selectedPair } = store;
  const selectedExchangeAccountId = useExchangeInfoStore((state) => state.selectedExchangeAccountId);

  const { isOpen, onClose, positionItem, marginType } = props;
  const [newMargin, setNewMargin] = useState<string>(""); // 使用字符串处理输入
  const [marginError, setMarginError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClose = () => {
    setNewMargin("");
    setMarginError(null);
    onClose();
  };

  const handleAdjustMargin = async (): Promise<boolean> => {
    if (isLoading) return false; // 防止重复提交
    setIsLoading(true); // 开始加载
    try {
      if (!positionItem) {
        message.error("Position item is not available.");
        return false;
      }

      const marginValue = parseFloat(newMargin);

      const param = {
        exchangeAccountId: selectedExchangeAccountId,
        exchangeName: selectedExchangeKey,
        symbol: positionItem.symbol,
        side: positionItem.side,
        amount: marginValue,
      };
      console.log("handleAdjustMargin:", param);
      await ubPerpApi.requestAdjustMargin(param); // 调整保证金 API 请求
      message.success("保证金调整成功"); // "Margin adjusted successfully."
      return true; // 表示成功
    } catch (error: any) {
      // 提取有意义的错误信息
      let errorMsg = "发生未知错误。"; // "An unknown error occurred."

      if (error.response && error.response.data && typeof error.response.data.message === "string") {
        errorMsg = error.response.data.message;
      } else if (typeof error.message === "string") {
        errorMsg = error.message;
      } else {
        // 如果 errorMsg 仍然是对象，进行字符串化
        errorMsg = JSON.stringify(error);
      }

      console.log("Extracted error message type:", typeof errorMsg);
      console.log("Extracted error message content:", errorMsg);

      // 确保 errorMsg 是字符串
      if (typeof errorMsg !== "string") {
        errorMsg = "发生未知错误。"; // 备用消息
      }

      // 显示错误消息
      message.error(errorMsg);
      return false; // 表示失败
    } finally {
      setIsLoading(false); // 结束加载
    }
  };

  const handleSetMargin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.trim();
    console.log(`handleSetMargin - Value: ${val}`);

    // 允许输入正负小数，包括整数（如 -1、0.5、1.25、-10.75 等）
    const regex = /^-?\d+(\.\d+)?$/;
    if (val === "") {
      setNewMargin(val);
      setMarginError(null);
      return;
    }

    if (!regex.test(val)) {
      setMarginError("请输入有效的非零小数，例如 -0.5 或 1.25。"); // "Please enter a valid non-zero decimal, e.g., -0.5 or 1.25."
    } else {
      // 进一步检查是否为零
      const marginValue = parseFloat(val);
      if (marginValue === 0) {
        setMarginError("保证金不能为零。"); // "Margin cannot be zero."
      } else {
        setMarginError(null);
      }
    }

    setNewMargin(val);
  };

  // **计算变量以禁用提交按钮**
  const isSubmitDisabled = !newMargin || !!marginError;

  // 根据 marginType 进行条件渲染
  if (marginType === MarginType.Crossed) {
    return (
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={handleClose} isCentered size="md">
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              提示
            </AlertDialogHeader>

            <AlertDialogBody>
              <VStack spacing={4}>
                <Text fontSize="md" color="red.500" fontWeight={700}>
                  全仓不允许修改保证金
                </Text>
              </VStack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={handleClose} colorScheme="blue">
                确定
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  }

  // 如果 marginType 不是 Crossed，显示原有的调整保证金对话框
  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={handleClose} isCentered size="md">
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            调整保证金
          </AlertDialogHeader>

          <AlertDialogBody>
            <VStack spacing={4}>
              {/* 显示当前保证金 */}
              {positionItem && (
                <Text fontSize="md" color="gray.500">
                  当前保证金: {positionItem.margin}
                </Text>
              )}

              {/* 调整保证金部分 */}
              <FormControl isInvalid={!!marginError}>
                <Flex alignItems="center" gap={2}>
                  <Text width="180px">修改保证金</Text>
                  <Tooltip label="正数表示增加，负数表示减少" aria-label="A tooltip">
                    <QuestionIcon cursor="pointer" />
                  </Tooltip>
                  <InputGroup size="sm">
                    <Input
                      placeholder="添加/减少保证金"
                      value={newMargin}
                      onChange={handleSetMargin}
                      type="text"
                      step={0.01}
                      aria-label="New Margin"
                    />
                  </InputGroup>
                </Flex>
                {marginError && <FormErrorMessage>{marginError}</FormErrorMessage>}
              </FormControl>
            </VStack>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={handleClose}>
              取消
            </Button>
            <Tooltip label={isSubmitDisabled ? "请确保输入的保证金有效。" : ""} isDisabled={!isSubmitDisabled}>
              <span>
                <Button
                  onClick={async () => {
                    const success = await handleAdjustMargin();
                    if (success) {
                      handleClose();
                    }
                  }}
                  ml={3}
                  colorScheme="blue"
                  isDisabled={isSubmitDisabled || isLoading} // 如果加载中或输入无效则禁用按钮
                  isLoading={isLoading} // 显示加载指示器
                >
                  确定
                </Button>
              </span>
            </Tooltip>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
