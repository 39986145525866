import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightAddon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import { useExchangeInfoStore } from "src/store";
import { accountApi } from "src/api";
import { useQuery } from "@tanstack/react-query";
import { PairItem, TradeDirection } from "src/api/types";
import { useState } from "react";
import { PumpForm, SmashForm } from "./PumpOrSmashForm";
import CButton from "../CButton/CButton";
import CModal from "../CModal/CModal";
import OrderForm from "./OrderForm";
import BalanceInfo from "./BalanceInfo";
import SingleVolumeForm from "./SingleVolumeForm";
import { MultiBalance } from "../MultiBalance/MultiBalance";
import { formatBalance } from "src/utils/string";

type Props = {
  pair: PairItem;
};

const TradeDirections = [TradeDirection.Buy, TradeDirection.Sell];
export default function Limit(props: Props) {
  const [pumpSide, setPumpSide] = useState(TradeDirection.Buy);
  const [tabIndex, setTabIndex] = useState(0);
  const dumpOrSmashDisclosure = useDisclosure();
  const multiBalanceDisclosure = useDisclosure();
  const selectedTradeDirection = TradeDirections[tabIndex];

  // 收益价格计算器
  const [price, setPrice] = useState("");
  const [profitMargin, setProfitMargin] = useState("");
  const priceNum = parseFloat(price) || 0;
  const profitMarginNum = parseFloat(profitMargin) || 0;
  const finalPrice = (priceNum * (1 + profitMarginNum / 100)).toFixed(6);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const { base, quote, id: pairId } = props.pair;
  const selectedExchangeAccountId = useExchangeInfoStore((state) => state.selectedExchangeAccountId);

  const balanceInfoQuery = useQuery({
    queryKey: ["balanceInfo", base, quote, selectedExchangeAccountId],
    queryFn: async () => {
      const result = await accountApi.fetchBalances({
        exchangeAccountId: Number(selectedExchangeAccountId),
        pairId,
      });
      return result;
    },
    enabled: !!selectedExchangeAccountId && selectedExchangeAccountId !== -1,
    refetchInterval: 5000,
  });
  const balanceInfo = balanceInfoQuery.data || [];
  const basePrecision = balanceInfo?.find((item) => item.name === base)?.precision;
  const quotePrecision = balanceInfo?.find((item) => item.name === quote)?.precision;
  const baseFree = balanceInfo?.find((item) => item.name === base)?.free ?? 0;
  const baseLock = balanceInfo?.find((item) => item.name === base)?.lock ?? 0;
  const quoteFree = balanceInfo?.find((item) => item.name === quote)?.free ?? 0;
  const quoteLock = balanceInfo?.find((item) => item.name === quote)?.lock ?? 0;

  const baseFreeBalance = formatBalance(baseFree, basePrecision);
  const baseLockedBalance = formatBalance(baseLock, basePrecision);
  const quoteFreeBalance = formatBalance(quoteFree, quotePrecision);
  const quoteLockedBalance = formatBalance(quoteLock, quotePrecision);

  const balanceInfoComp = (
    <BalanceInfo
      base={base}
      quote={quote}
      frozenBase={baseLockedBalance}
      frozenQuote={quoteLockedBalance}
      baseBalance={baseFreeBalance}
      quoteBalance={quoteFreeBalance}
      refetch={balanceInfoQuery.refetch}
    />
  );

  const handleCalInputChange = (e, setFunction) => {
    let value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      // 去除多余的前导0
      if (value.startsWith("0") && !value.startsWith("0.")) {
        value = value.replace(/^0+/, ""); // 去掉所有前导0
      }
      setFunction(value);
    }
  };

  const profitCalculator = (
    <Flex justifyContent={"space-between"} alignItems={"center"} mt={4}>
      <InputGroup>
        <Input
          className="text-input"
          paddingX={"12px"}
          height={"2rem"}
          borderRadius={"2px"}
          type="text"
          value={price}
          placeholder="价格"
          onChange={(e) => handleCalInputChange(e, setPrice)}
        />
        <InputRightAddon height={"2rem"} borderRadius={"2px"} padding={"4px"}>
          U
        </InputRightAddon>
      </InputGroup>
      <p>*</p>
      <InputGroup>
        <Input
          className="text-input"
          height={"2rem"}
          paddingX={"12px"}
          borderRadius={"2px"}
          type="text"
          value={profitMargin}
          placeholder="利润率"
          onChange={(e) => handleCalInputChange(e, setProfitMargin)}
        />
        <InputRightAddon height={"2rem"} borderRadius={"2px"} padding={"4px"}>
          %
        </InputRightAddon>
      </InputGroup>
      <p>=</p>
      <Box>{finalPrice}</Box>
    </Flex>
  );
  return (
    <>
      <Flex width="100%" border="1px solid gray" borderRadius="10px">
        <Tabs variant="enclosed" index={tabIndex} onChange={handleTabsChange} width={"100%"}>
          <TabList>
            <Tab bgColor="teal" color="white">
              买入
            </Tab>
            <Tab bgColor="#f08080" color="white">
              卖出
            </Tab>
            <Tab>一键刷量</Tab>
            <Tab>常用工具</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {balanceInfoComp}
              <OrderForm {...props} tradeDirection={selectedTradeDirection} />
              {profitCalculator}
            </TabPanel>
            <TabPanel>
              {balanceInfoComp}
              <OrderForm {...props} tradeDirection={selectedTradeDirection} />
              {profitCalculator}
            </TabPanel>
            <TabPanel>
              {balanceInfoComp}
              <SingleVolumeForm pair={props.pair} exchangeAccountId={selectedExchangeAccountId} />
            </TabPanel>
            <TabPanel>
              <Flex height="100px" gap={4}>
                <CButton
                  name="拉盘"
                  onClick={() => {
                    setPumpSide(TradeDirection.Buy);
                    dumpOrSmashDisclosure.onOpen();
                  }}
                />
                <CButton
                  name="砸盘"
                  onClick={() => {
                    setPumpSide(TradeDirection.Sell);
                    dumpOrSmashDisclosure.onOpen();
                  }}
                />
                <CButton
                  name="多账户余额查询"
                  onClick={() => {
                    multiBalanceDisclosure.onOpen();
                  }}
                />
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      <CModal
        size="6xl"
        isOpen={dumpOrSmashDisclosure.isOpen}
        onClose={dumpOrSmashDisclosure.onClose}
        title={pumpSide === TradeDirection.Buy ? "拉盘" : "砸盘"}
      >
        <>
          {pumpSide === TradeDirection.Buy ? (
            <PumpForm pair={props.pair} onClose={dumpOrSmashDisclosure.onClose} />
          ) : (
            <SmashForm pair={props.pair} onClose={dumpOrSmashDisclosure.onClose} />
          )}
        </>
      </CModal>
      <CModal
        size="4xl"
        isOpen={multiBalanceDisclosure.isOpen}
        onClose={multiBalanceDisclosure.onClose}
        title="多账户余额查询"
      >
        <>
          <MultiBalance />
        </>
      </CModal>
    </>
  );
}
