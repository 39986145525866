export const WSS_URL = process.env.REACT_APP_WSS_URL || `wss://d34u75fbfyvkq7.cloudfront.net/v2/ws`;

export type WsEventMessage = {
  channel: "order_book";
  event: "sub" | "unsub";
  pairId: number;
  exchangeName: string;
};
type Price = number;
type Amount = number;
type Sum = number;
type Bid = [Price, Amount, Sum];
type Ask = Bid;
export type WsResponseOrderBook = {
  updateTime: number;
  pair: string;
  bids: Bid[];
  asks: Ask[];
};

//
