import React from "react";
import { accountApi, adminApi } from "src/api";
import { ExchangeAccount, RoleType } from "src/api/types";
import { EXCHANGE_ACCOUNTS_KEY, MY_EXCHANGE_ACCOUNTS_KEY } from "src/consts";
import { useExchangeInfoStore } from "src/store";
import { useQuery } from "@tanstack/react-query";

export const useExchangeAccounts = () => {
  const store = useExchangeInfoStore();
  const defaultExchangeAccounts: ExchangeAccount[] = JSON.parse(localStorage.getItem(EXCHANGE_ACCOUNTS_KEY)) || [];
  const defaultMyExchangeAccounts: ExchangeAccount[] = JSON.parse(localStorage.getItem(MY_EXCHANGE_ACCOUNTS_KEY)) || [];
  const [allExchangeAccounts, setAllExchangeAccounts] = React.useState<ExchangeAccount[]>(defaultExchangeAccounts);
  const [myExchangeAccounts, setMyExchangeAccounts] = React.useState<ExchangeAccount[]>(defaultMyExchangeAccounts);

  const clearExchangeAccounts = () => {
    // store empty array if error
    store.setExchangeAccounts([]);
    localStorage.setItem(EXCHANGE_ACCOUNTS_KEY, JSON.stringify([]));
    setAllExchangeAccounts([]);
  };

  useQuery({
    queryKey: ["exchangeAccounts", "self"],
    queryFn: async () => {
      const myExchangeAccountsResult = await accountApi.fetchMyExchangeAccounts();
      store.setMyExchangeAccounts(myExchangeAccountsResult);
      localStorage.setItem(MY_EXCHANGE_ACCOUNTS_KEY, JSON.stringify(myExchangeAccountsResult));
      setMyExchangeAccounts(myExchangeAccountsResult);
      try {
        const allExchangeAccountsResult = await adminApi.fetchExchangeAccounts();
        store.setExchangeAccounts(allExchangeAccountsResult);
        localStorage.setItem(EXCHANGE_ACCOUNTS_KEY, JSON.stringify(allExchangeAccountsResult));
        setAllExchangeAccounts(allExchangeAccountsResult);
      } catch (error) {
        console.warn("fetchAllExchangeAccounts error", error);
      }
      return null;
    },
    refetchInterval: 10 * 1000,
  });

  return {
    allExchangeAccounts: allExchangeAccounts,
    myExchangeAccounts: myExchangeAccounts,
  };
};
