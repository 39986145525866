import { Button, Divider, Flex, Input, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { Form, Formik, useField, useFormik } from "formik";
import CInput from "src/component/CInput/CInput";
import * as Yup from "yup";
import styles from "./OneSide.module.scss";
import CButton from "src/component/CButton/CButton";
import AccountSelect from "src/component/AccountSelect/AccountSelect";
import CSwitch from "src/component/CSwitch/CSwitch";
import { useParams } from "react-router-dom";

type Props = {
  base: string;
  quote: string;
  onSubmit?: (values: any) => void;
};

export default function OneSideConfigDetails(props: Props) {
  const { id: exchangeId } = useParams();
  if (!exchangeId) return null;
  const { base, quote } = props;
  // const displaySymbol = `${base}/${quote}`;
  const displaySymbol = "";
  return (
    <Formik
      initialValues={{
        oneSideBuyEnabled: false,
        oneSideSellEnabled: false,
      }}
      validationSchema={Yup.object({})}
      onSubmit={(values) => {
        alert(JSON.stringify(values, null, 2));
        props.onSubmit && props.onSubmit(values);
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <Flex flexWrap="wrap">
              <Flex width="100%" justifyContent="space-between">
                <Text my={10} fontSize="xl" fontWeight={600} textAlign="center" mt={2}>
                  {exchangeId} 机器人设置
                </Text>
                <Flex gap={6}>
                  <CButton name="交易详情" />
                  <CButton name="保存配置" type="submit" />
                </Flex>
              </Flex>
              <Flex
                flexWrap="wrap"
                gap={2}
                rowGap={2}
                justifyContent="space-around"
                width="calc(50% - 20px)"
                maxWidth="880px"
              >
                <Flex alignItems="center" gap={2} mt={4} width="100%" justifyContent="space-between">
                  <AccountSelect />
                  <CSwitch
                    label={"买盘单边任务开关"}
                    onChange={setFieldValue}
                    isChecked={values.oneSideBuyEnabled}
                    name="oneSideBuyEnabled"
                  />
                </Flex>
                <CInput
                  classname={styles.inputField}
                  label={`${displaySymbol} 买盘单边成交总${base}数量`}
                  name="oneSideBuyTotalLimit"
                  type="number"
                  placeholder="100000"
                />
                <CInput
                  classname={styles.inputField}
                  label={`${displaySymbol} 买盘单边价格线`}
                  name="oneSideBuyPriceLine"
                  type="number"
                  placeholder="0.016"
                />
                <CInput
                  classname={styles.inputField}
                  label={`${displaySymbol} 每单间隔时间(秒)`}
                  name="oneSideBuyInterval"
                  type="number"
                  placeholder="30"
                />
                <CInput
                  classname={styles.inputField}
                  label={`${displaySymbol} 每单的随机${base}交易数量范围`}
                  name="oneSideBuyAmountRangeMin"
                  type="number"
                  placeholder="200"
                />
                <CInput
                  classname={styles.inputField}
                  label={`${displaySymbol} 每单的随机${base}交易数量范围`}
                  name="oneSideBuyAmountRangeMax"
                  type="number"
                  placeholder="300"
                />
              </Flex>

              <Divider orientation="vertical" height="auto" width="2px" mx={4} />

              <Flex
                flexWrap="wrap"
                gap={2}
                rowGap={2}
                justifyContent="space-around"
                width="calc(50% - 20px)"
                maxWidth="880px"
              >
                <Flex alignItems="center" gap={2} mt={4} width="100%" justifyContent="space-between">
                  <AccountSelect />
                  <CSwitch
                    label={"卖盘单边任务开关"}
                    onChange={setFieldValue}
                    isChecked={values.oneSideSellEnabled}
                    name="oneSideSellEnabled"
                  />
                </Flex>
                <CInput
                  classname={styles.inputField}
                  label={`${displaySymbol} 卖盘单边成交总${base}数量`}
                  name="oneSideSellTotalLimit"
                  type="number"
                  placeholder="100000"
                />
                <CInput
                  classname={styles.inputField}
                  label={`${displaySymbol} 卖盘单边价格线`}
                  name="oneSideSellPriceLine"
                  type="number"
                  placeholder="0.016"
                />
                <CInput
                  classname={styles.inputField}
                  label={`${displaySymbol} 每单间隔时间(秒)`}
                  name="oneSideSellInterval"
                  type="number"
                  placeholder="30"
                />
                <CInput
                  classname={styles.inputField}
                  label={`${displaySymbol} 每单的随机${base}交易数量范围`}
                  name="oneSideSellAmountRangeMin"
                  type="number"
                  placeholder="200"
                />
                <CInput
                  classname={styles.inputField}
                  label={`${displaySymbol} 每单的随机${base}交易数量范围`}
                  name="oneSideSellAmountRangeMax"
                  type="number"
                  placeholder="300"
                />
              </Flex>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
}
