import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import { WsEventMessage, WsResponseOrderBook, WSS_URL } from "src/api/ws";
import OrderBook from "src/component/OrderBook/OrderBook";
import { useExchangeInfoStore } from "src/store";
import { formatDate } from "src/utils/date";
import ConnectionStatus from "./ConnectionStatus";

const WssOrderBook = () => {
  const store = useExchangeInfoStore();
  const { selectedExchangeKey, selectedSpotPair: selectedPair } = store;
  const selectedPairId = selectedPair.id;

  const [message, setMessage] = useState<WsResponseOrderBook | null>(null);
  const [rws, setRws] = useState(null);
  const getSubRequest = (pairId: number, exchangeName: string): WsEventMessage => {
    return {
      channel: "order_book",
      event: "sub",
      pairId,
      exchangeName,
    };
  };

  useEffect(() => {
    const rws = new ReconnectingWebSocket(WSS_URL);
    setRws(rws);
    rws.onopen = () => {
      console.log("Connected to WebSocket");
      rws.send(JSON.stringify(getSubRequest(selectedPairId, selectedExchangeKey)));
    };

    rws.onmessage = (event) => {
      const message = event.data;
      try {
        const formattedMessage = JSON.parse(message) as WsResponseOrderBook;
        // ensure that the message is a valid order book message
        if (formattedMessage.updateTime && formattedMessage.bids && formattedMessage.asks) {
          console.log("WebSocket message:", formattedMessage);
          setMessage(formattedMessage);
        }
      } catch (error) {}
    };

    rws.onerror = (error) => {
      console.error("WebSocket Error:", error);
    };

    rws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      rws.close();
    };
  }, [selectedPairId, selectedExchangeKey]);

  const [isConnected, setIsConnected] = useState(false);
  const lastUpdateTime = message?.updateTime;
  // judge if the websocket is connected by watching lastUpdateTime, if not updated for x seconds, then it's disconnected
  useEffect(() => {
    if (lastUpdateTime) {
      setIsConnected(true);
      const timer = setInterval(() => {
        if (Date.now() - lastUpdateTime * 1000 > 5 * 1000) {
          setIsConnected(false);
        }
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [lastUpdateTime]);

  return (
    <Box
      flexGrow={1}
      maxHeight="400px"
      border="1px solid gray"
      borderRadius="10px"
      p={2}
      overflow="scroll"
      style={{ scrollbarWidth: "none" }}
    >
      <Flex fontWeight={500} fontSize="18px" justifyContent="space-between">
        订单薄
        <Flex fontWeight={400} flexDirection="column" alignItems="end">
          <Flex alignItems="center" gap={2}>
            <Text>WSS 连接状态：{isConnected ? "已连接" : "未连接"}</Text>
            <ConnectionStatus isConnected={isConnected} />
          </Flex>
          {message?.updateTime && (
            <Text ml={4} fontWeight={400}>
              更新时间：{formatDate(message.updateTime * 1000)}
            </Text>
          )}
        </Flex>
      </Flex>

      {message && <OrderBook info={message} />}
    </Box>
  );
};

export default WssOrderBook;
