import {
  Button,
  Flex,
  Input,
  InputGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  Divider,
} from "@chakra-ui/react";
import { useFormik, FormikProvider } from "formik";
import { MarginType, OrderType, TradeDirection } from "src/api/types";
import CButton from "src/component/CButton/CButton";
import styles from "./index.module.scss";
import CInput from "src/component/CInput/CInput";
import { Dispatch, SetStateAction, useState } from "react";
import OpenPositionAlert from "./OpenPositionAlert";
import { useMessage } from "src/hook/useMessage";
import { useExchangeInfoStore } from "src/store";
import { ubPerpApi } from "src/api";
import * as Yup from "yup";
import { ErrorMessage } from "src/utils/ErrorMessage";
import Slider from "rc-slider"; // Import Slider from rc-slider
import "rc-slider/assets/index.css"; // Import rc-slider styles

export default function MarketOpenPosition(props: {
  defaultLeverage: number;
  hasPosition: boolean;
  marketPrice: string;
  marginType: MarginType;
  positionSide: boolean;
  setLeverage: Dispatch<SetStateAction<number>>;
  setMarginType: Dispatch<SetStateAction<MarginType>>;
  balance: number;
}) {
  const { defaultLeverage, marketPrice, setLeverage, balance } = props;

  const message = useMessage();
  const store = useExchangeInfoStore();
  const { selectedExchangeKey, selectedUbPerpPair: selectedPair, selectedExchangeAccountId } = store;

  const [tabIndex, setTabIndex] = useState(0);
  const [tradeDirection, setTradeDirection] = useState<TradeDirection>(TradeDirection.Buy);
  const orderType = tabIndex === 0 ? OrderType.LIMIT : OrderType.MARKET;

  const [isUpdating, setIsUpdating] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null); // 防抖定时器

  const handleSetLeverage = (value: string) => {
    // Allow the input to be empty
    if (value === "") {
      setLeverage(0);
      return;
    }

    // Only allow digits
    if (!/^\d+$/.test(value)) {
      return;
    }

    // Remove leading zeros
    const sanitizedValue = value.replace(/^0+/, "") || "0";

    // Parse the sanitized value to an integer
    const leverageNumber = parseInt(sanitizedValue, 10);

    // Optionally enforce leverage limits
    if (leverageNumber < 1) {
      setLeverage(1);
    } else if (leverageNumber > 20) {
      setLeverage(20);
    } else {
      setLeverage(leverageNumber);
    }
  };

  const handleSetTabIndex = (index: number) => {
    setTabIndex(index);
    // 切换标签时重置 openPrice
    formik.setFieldValue("openPrice", index === 0 ? "" : marketPrice);
  };

  // Open alert dialog
  const {
    isOpen: isOpenPositionAlertOpen,
    onClose: onCloseOpenPositionAlert,
    onOpen: onOpenOpenPositionAlert,
  } = useDisclosure();

  // 定义验证模式
  const validationSchema = Yup.object()
    .shape({
      openPrice:
        tabIndex === 0
          ? Yup.number().typeError("必须为数字").min(0, "必须为正数").required("开仓价格是必填项")
          : Yup.number().typeError("必须为数字").min(0, "必须为正数"),
      amount: Yup.number().typeError("必须为数字").min(0, "必须为正数"),
      uAmount: Yup.number().typeError("必须为数字").min(0, "必须为正数"),
    })
    .test("amount-or-uAmount", "必须填写开仓数量或开仓 U 数量", function (values) {
      const { amount, uAmount } = values;
      // 检查是否至少填写了一个字段
      if ((!amount || amount <= 0) && (!uAmount || uAmount <= 0)) {
        return this.createError({
          path: "amount",
          message: "必须填写开仓数量或仓位大小",
        });
      }

      // 如果填写了 uAmount，需要检查保证金是否至少 5U
      if (uAmount && uAmount > 0) {
        if (defaultLeverage && uAmount / defaultLeverage < 5) {
          return this.createError({
            path: "uAmount",
            message: "保证金至少需要 5U",
          });
        }
      }

      return true;
    });

  const formik = useFormik({
    initialValues: {
      openPrice: tabIndex === 0 ? "" : marketPrice,
      amount: "",
      uAmount: "",
    },
    validationSchema,
    onSubmit: () => {
      onOpenOpenPositionAlert();
    },
    validateOnBlur: true,
    validateOnChange: true,
  });

  const { values, handleChange, handleSubmit, validateForm, setTouched, setFieldValue, errors, touched } = formik;

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isUpdating) return;
    setIsUpdating(true);

    const value = e.target.value;
    setFieldValue("amount", value);

    // 防抖处理
    if (debounceTimeout) clearTimeout(debounceTimeout);
    const timeout = setTimeout(() => {
      if (value === "" || isNaN(Number(value))) {
        setFieldValue("uAmount", "");
        setIsUpdating(false);
        return;
      }

      const amountNum = parseFloat(value);
      const marketPriceNum = parseFloat(marketPrice);
      const leverageNum = defaultLeverage;

      if (!isNaN(amountNum) && marketPriceNum && leverageNum !== 0) {
        const calculatedUAmount = Math.ceil(amountNum * marketPriceNum);
        setFieldValue("uAmount", calculatedUAmount.toFixed(0));
      } else {
        setFieldValue("uAmount", "");
      }

      setIsUpdating(false);
    }, 100); // 延时100ms执行防抖
    setDebounceTimeout(timeout);
  };

  const handleUAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isUpdating) return;
    setIsUpdating(true);

    const value = e.target.value;
    setFieldValue("uAmount", value);

    // 防抖处理
    if (debounceTimeout) clearTimeout(debounceTimeout);
    const timeout = setTimeout(() => {
      if (value === "" || isNaN(Number(value))) {
        setFieldValue("amount", "");
        setIsUpdating(false);
        return;
      }

      const uAmountNum = parseFloat(value);
      const marketPriceNum = parseFloat(marketPrice);
      const leverageNum = defaultLeverage;

      if (!isNaN(uAmountNum) && marketPriceNum && leverageNum !== 0) {
        const calculatedAmount = uAmountNum / marketPriceNum;
        setFieldValue("amount", Math.ceil(calculatedAmount));
      } else {
        setFieldValue("amount", "");
      }

      setIsUpdating(false);
    }, 100); // 延时100ms执行防抖
    setDebounceTimeout(timeout);
  };

  const leveragedBalance = balance * defaultLeverage;
  const marks =
    balance && defaultLeverage
      ? {
          [leveragedBalance / 4]: "25%",
          [leveragedBalance / 2]: "50%",
          [(leveragedBalance * 3) / 4]: "75%",
        }
      : {};

  const handleSliderChange = (value: number) => {
    // 当滑块值变化时，更新 uAmount 和 amount
    setFieldValue("uAmount", Math.ceil(value));

    const uAmountNum = value;
    const marketPriceNum = parseFloat(marketPrice);
    const leverageNum = defaultLeverage;

    if (!isNaN(uAmountNum) && marketPriceNum && leverageNum !== 0) {
      // 根据 uAmount 计算 amount
      const calculatedAmount = uAmountNum / marketPriceNum;
      setFieldValue("amount", Math.ceil(calculatedAmount));
    }
  };

  // 表单字段组件
  const fieldComponent = (openPriceInput: JSX.Element | null) => {
    return (
      <Flex flexDir="column" gap={2} mt={4} width="100%" justifyContent="space-between">
        <Flex justifyContent={"space-between"} width={"100%"} alignItems={"center"} flexDir={"column"} gap={4}>
          <Flex alignItems="center" width="100%" gap={2} justifyContent={"center"}>
            <Text width="fit-content" whiteSpace="nowrap">
              杠杆:
            </Text>
            <InputGroup size={"sm"} gap={2}>
              <Input
                className="text-input"
                onChange={(e) => handleSetLeverage(e.target.value)}
                type="number"
                min={1}
                max={20}
                step={1}
                value={defaultLeverage === 0 ? "" : defaultLeverage}
                placeholder="1-20"
                // maxW={"50%"}
              />
              <Button onClick={handleRequestSetLeverage}>修改杠杆</Button>
            </InputGroup>
          </Flex>
        </Flex>

        <Divider borderStyle="dashed" borderColor="gray.100" borderWidth="1px" my={2}></Divider>
        {openPriceInput}
        <Divider borderStyle="dashed" borderColor="gray.100" borderWidth="1px" my={2}></Divider>

        <Flex alignItems="flex-start" gap={2} justifyContent="flex-start" flexDir={"column"}>
          <CInput
            classname={styles.inputField}
            label="仓位张数"
            name="amount"
            type="number"
            onChange={handleAmountChange}
            value={values.amount}
            rightIndicator="张"
            description="一张=一个币"
          />
          <Flex justifyContent={"space-between"} width={"100%"} gap={2}>
            <CInput
              classname={styles.inputField}
              label="仓位大小"
              name="uAmount"
              type="number"
              onChange={handleUAmountChange}
              value={values.uAmount}
              rightIndicator="U"
              description="仓位，等于保证金*杠杆"
            />
            <Slider
              min={0}
              max={Number(balance) * defaultLeverage} // Slider max is position size
              value={Number(values.uAmount)}
              onChange={handleSliderChange} // Sync slider with amount
              step={(Number(balance) * defaultLeverage) / 100}
              marks={marks} // Percentage marks
            />
          </Flex>
        </Flex>

        <Flex alignItems="center" gap={2} mt={4} width="100%">
          <CButton
            type="button"
            name="开多"
            colorScheme="teal"
            onClick={async () => {
              // 标记所有字段为已触碰，以显示错误
              await setTouched({
                openPrice: true,
                amount: true,
                uAmount: true,
              });

              // 验证表单
              const formErrors = await validateForm();
              if (Object.keys(formErrors).length === 0) {
                // 直接提交表单，打开模态框
                setTradeDirection(TradeDirection.Buy);
                handleSubmit();
              }
            }}
          />
          <CButton
            type="button"
            name="开空"
            colorScheme="red"
            onClick={async () => {
              // 标记所有字段为已触碰，以显示错误
              await setTouched({
                openPrice: true,
                amount: true,
                uAmount: true,
              });

              // 验证表单
              const formErrors = await validateForm();
              if (Object.keys(formErrors).length === 0) {
                // 直接提交表单，打开模态框
                setTradeDirection(TradeDirection.Sell);
                handleSubmit();
              }
            }}
          />
        </Flex>

        <OpenPositionAlert
          isOpen={isOpenPositionAlertOpen}
          onClose={onCloseOpenPositionAlert}
          price={orderType === OrderType.LIMIT ? Number(values.openPrice) : Number(marketPrice)}
          margin={Number(values.amount)}
          leverage={defaultLeverage}
          side={tradeDirection}
          orderType={orderType}
          uAmount={values.uAmount}
        />
      </Flex>
    );
  };

  const handleRequestSetLeverage = async () => {
    try {
      await ubPerpApi.requestSetLeverage({
        exchangeName: selectedExchangeKey,
        exchangeAccountId: selectedExchangeAccountId,
        symbol: selectedPair.symbol,
        leverage: defaultLeverage,
      });
      message.success("修改杠杆成功");
    } catch (error) {
      message.detailedError(<ErrorMessage error={error} />);
    }
  };

  return (
    <FormikProvider value={formik}>
      <form className={styles.openForm} onSubmit={handleSubmit}>
        <Flex flexDirection="column" gap={2}>
          <Tabs index={tabIndex} onChange={handleSetTabIndex}>
            <TabList>
              <Tab>限价开单</Tab>
              <Tab>市价开单</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                {fieldComponent(
                  <>
                    <CInput
                      classname={styles.inputField}
                      label="开仓价格"
                      name="openPrice"
                      type="number"
                      onChange={handleChange}
                      value={values.openPrice}
                      rightIndicator="U"
                      description="设置限价开仓的价格"
                    />
                  </>
                )}
              </TabPanel>
              <TabPanel>
                {fieldComponent(
                  <CInput
                    classname={styles.inputField}
                    label="开仓价格"
                    name="openPrice"
                    type="number"
                    value={marketPrice}
                    disable={true}
                    onChange={() => {}} // 禁用时不允许更改
                    rightIndicator="U"
                    description="市价开单时使用当前市场价格"
                  />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </form>
    </FormikProvider>
  );
}
