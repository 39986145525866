import { Flex } from "@chakra-ui/react";
import { Formik } from "formik";
import { Form } from "react-router-dom";
import { strategiesApi } from "src/api";
import { EatOrderExampleType, StrategyResponse, UpdateStrategyParams } from "src/api/types";
import { useExchangeInfoStore } from "src/store";
import CButton from "../CButton/CButton";
import CInput from "../CInput/CInput";
import styles from "./StrategyDetail.module.scss";
import * as Yup from "yup";
import { useExchangeAccounts } from "src/hook/useExchangeAccounts";
import CField from "src/component/CField/CField";
import { useMessage } from "src/hook/useMessage";
import CommonEatOrderForm from "../StrategyForms/CommonEatOrderForm";
import { ErrorMessage } from "src/utils/ErrorMessage";

type Props = {
  strategy: StrategyResponse<EatOrderExampleType>;
  onUpdated?: () => void;
};

export default function EatOrderDetail(props: Props) {
  const { strategy, onUpdated } = props;
  const message = useMessage();
  const accounts = useExchangeAccounts();
  const pairs = useExchangeInfoStore((state) => state.spotPairs);
  const pair = pairs.find((pair) => pair.id === strategy.pairId);
  const exchangeAccount = accounts.allExchangeAccounts.find((account) => account.id === strategy.exchangeAccountId);

  return (
    <Formik
      initialValues={{
        strategyName: strategy.strategyName,
        repeatIntervalMsMin: strategy.repeatIntervalMsMin,
        repeatIntervalMsMax: strategy.repeatIntervalMsMax,

        eatSide: strategy.preference.eatSide,
        topN: strategy.preference.topN,
        topNEatRatio: strategy.preference.topNEatRatio,
        eatTotalAmount: strategy.preference.eatTotalAmount,
        rangeAmountMinPer: strategy.preference.rangeAmountMinPer,
        rangeAmountMaxPer: strategy.preference.rangeAmountMaxPer,
        orderNumberPer: strategy.preference.orderNumberPer,
        orderIntervalMsPer: strategy.preference.orderIntervalMsPer,
        eatPriceTrigger: strategy.preference.eatPriceTrigger,
      }}
      validationSchema={Yup.object({
        strategyName: Yup.string().required("Required"),
        
      })}
      onSubmit={async (values) => {
        console.log("====================================");
        console.log("values", values);
        console.log("====================================");
        alert(JSON.stringify(values, null, 2));
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form className={styles.form}>
            <Flex width="calc(100% - 20px)" flexDirection="column" gap={2}>
              <Flex alignItems="center" gap={2} mt={2}>
                <CField label={"交易方向"} value={values.eatSide} />
              </Flex>
              <CommonEatOrderForm styles={styles} />
              <Flex alignItems="center" gap={2} mt={4}>
                <CButton
                  name="保存"
                  type="submit"
                  onClick={async () => {
                    const payload: UpdateStrategyParams<EatOrderExampleType> = {
                      strategyId: strategy.id,
                      strategyName: values.strategyName,
                      repeatIntervalMsMin: values.repeatIntervalMsMin,
                      repeatIntervalMsMax: values.repeatIntervalMsMax,
                      preference: {
                        eatSide: strategy.preference.eatSide,
                        topN: Number(values.topN),
                        topNEatRatio: Number(values.topNEatRatio),
                        eatTotalAmount: Number(values.eatTotalAmount),
                        rangeAmountMinPer: Number(values.rangeAmountMinPer),
                        rangeAmountMaxPer: Number(values.rangeAmountMaxPer),
                        orderNumberPer: Number(values.orderNumberPer),
                        orderIntervalMsPer: Number(values.orderIntervalMsPer),
                        eatPriceTrigger: Number(values.eatPriceTrigger),
                      },
                    };
                    try {
                      const result = await strategiesApi.updateStrategyV2(payload);
                      message.success("更新成功");
                      onUpdated && onUpdated();
                    } catch (error) {
                      message.detailedError(<ErrorMessage error={error} />);
                    }
                  }}
                />
              </Flex>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
}
