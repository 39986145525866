import { api } from "src/api/base";
import { AuthResponse, RoleType } from "src/api/types";
import { ROLES_KEY, TOKEN_KEY, TRADINGVIEW_CEX_NAME_MAP, USER_KEY } from "src/consts";

type UserInfo = AuthResponse & { username: string };

export const setToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
  api.defaults.headers.Authorization = token;
};
export const setUser = (user: UserInfo) => {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
};
export const setRoles = (roles: RoleType[]) => {
  localStorage.setItem(ROLES_KEY, JSON.stringify(roles));
};
export const getUser = (): UserInfo | null => {
  const rawUser = localStorage.getItem(USER_KEY);
  if (!rawUser) {
    return null;
  }
  return JSON.parse(rawUser) as UserInfo;
};
export const logout = () => {
  localStorage.removeItem(USER_KEY);
  localStorage.removeItem(TOKEN_KEY);
  window.location.href = "/login";
};

export const getPairName = (base: string, quote: string) => {
  return `${base}-${quote}`;
}
export const getPairSymbol = (base: string, quote: string) => {
  return `${base}${quote}`;
}
export const getTradingviewCexName = (cexName: string) => {
  const key = cexName.toLowerCase() as keyof typeof TRADINGVIEW_CEX_NAME_MAP;
  const value = TRADINGVIEW_CEX_NAME_MAP[key] || cexName.toUpperCase();
  return value;
}