import {
  Button,
  Divider,
  Flex,
  Input,
  Progress,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { Form, Formik, useField, useFormik } from "formik";
import CInput from "src/component/CInput/CInput";
import * as Yup from "yup";
import styles from "./OneSide.module.scss";
import CButton from "src/component/CButton/CButton";
import AccountSelect from "src/component/AccountSelect/AccountSelect";
import CSwitch from "src/component/CSwitch/CSwitch";
import CField from "src/component/CField/CField";
import { useNavigate } from "react-router-dom";

type Props = {
  base: string;
  quote: string;
  onSubmit?: (values: any) => void;
};

export default function OneSide(props: Props) {
  const { base, quote } = props;
  const displaySymbol = `${base}/${quote}`;
  return (
    <Formik
      initialValues={{
        binanceEnabled: false,
        okexEnabled: false
      }}
      validationSchema={Yup.object({})}
      onSubmit={(values) => {
        alert(JSON.stringify(values, null, 2));
        props.onSubmit && props.onSubmit(values);
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form className={styles.form}>
            <Flex flexWrap="wrap" width="100%">
              <Flex width="100%" justifyContent="space-between">
                <Text my={10} fontSize="xl" fontWeight={600} textAlign="center" mt={2}>
                  机器人设置
                </Text>
                <Flex gap={6}>
                  <CButton name="保存配置" type="submit" />
                </Flex>
              </Flex>

              <Flex width="100%" flexDirection="column">
                <ExchangeConfig
                  exchangeId={"binance"}
                  account={"account1"}
                  isEnabled={false}
                  toggleEnabled={() => setFieldValue("binanceEnabled", !values.binanceEnabled)}
                />
                <ExchangeConfig
                  exchangeId={"okex"}
                  account={"account2"}
                  isEnabled={false}
                  toggleEnabled={() => setFieldValue("okexEnabled", !values.okexEnabled)}
                />
              </Flex>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
}

type ExchangeConfigProps = {
  exchangeId: string;
  account: string;
  isEnabled: boolean;
  toggleEnabled: () => void;
};
function ExchangeConfig(props: ExchangeConfigProps) {
  const navigate = useNavigate();
  const { exchangeId, account, toggleEnabled, isEnabled } = props;
  return (
    <Flex gap={2} rowGap={2} justifyContent="space-between" width="calc(100% - 20px)" my={2}>
      <Flex alignItems="center" gap={2} mt={4} width="200px" justifyContent="space-between" flexDirection="column">
        <Text fontSize="xl" fontWeight={600} textAlign="center" mt={2}>
          {exchangeId}
        </Text>
        <CButton name="参数设置" onClick={() => navigate(`/oneSide/${exchangeId}`)} />
        <CButton name="交易详情" />
      </Flex>
      <Divider orientation="vertical" height="auto" width="2px" bg='gray' />
      <Flex width="100%" flexDirection="column" justifyContent="space-around">
        <Flex alignItems="center" gap={2} mt={4} width="100%" justifyContent="space-between">
          <CField label={"交易账户"} value={account} />
          <CSwitch label={"吃买单开关"} onChange={toggleEnabled} isChecked={isEnabled} />
        </Flex>
        <CField label={"买入触发价格"} value={64000} />
        <Text>进度：60000/10000</Text>
        <Progress colorScheme="green" size="md" value={20} />
      </Flex>
    </Flex>
  );
}
