import Home from "src/page/Home";
import { Icon } from "@chakra-ui/icons";
import { MdHome } from "react-icons/md";
import { ImUsers } from "react-icons/im";
import { IoIosSettings } from "react-icons/io";
import { FaRegPlusSquare, FaScroll } from "react-icons/fa";
import Settings from "src/page/Settings";
import Layout from "./component/Layout/Layout";
import Login from "./page/Login";
import NotFound from "./page/NotFound";
import OneSidePage from "./page/Strategy/OneSidePage";
import Accounts from "./page/Accounts";
import Create from "./page/Accounts/AccountCreate";
import UserList from "./page/UserList";
import UserCreate from "./page/UserList/UserCreate";
import Roles from "./page/Roles";
import RoleCreate from "./page/Roles/RoleCreate";
import NewStrategy from "./page/NewStrategy";
import StrategyList from "./page/StrategyList";
import { CgArrangeBack } from "react-icons/cg";
import UserDetail from "./component/UserDetail/UserDetail";
import AccountEdit from "./page/Accounts/AccountEdit";
import Perpeptual from "./page/UbPerpeptual";
import CbPerpeptual from "./page/CbPerpeptual";
import { HiDocumentReport } from "react-icons/hi";
import BalanceReport from "./page/BalanceReport/BalanceReport";

const menuRoutes = [
  {
    name: "首页",
    path: "/",
    element: <Home />,
    icon: <Icon as={MdHome} boxSize="30px" />,
    iconSelected: <Icon as={MdHome} boxSize="30px" />,
  },
  {
    name: "创建新策略",
    path: "/new-strategy",
    element: <NewStrategy />,
    icon: <Icon as={FaRegPlusSquare} boxSize="30px" />,
    iconSelected: <Icon as={FaRegPlusSquare} boxSize="30px" />,
  },
  {
    name: "策略管理",
    path: "/strategies",
    element: <StrategyList />,
    icon: <Icon as={CgArrangeBack} boxSize="30px" />,
    iconSelected: <Icon as={CgArrangeBack} boxSize="30px" />,
  },
  {
    name: "合约",
    children: [
      {
        name: "U本位合约",
        path: "/perpeptual/ub",
        element: <Perpeptual />,
      },
      {
        name: "币本位合约",
        path: "/perpeptual/cb",
        element: <CbPerpeptual />,
      },
    ],
    icon: <Icon as={FaScroll} boxSize="30px" />,
    iconSelected: <Icon as={FaScroll} boxSize="30px" />,
  },
  {
    name: "用户管理",
    children: [
      {
        name: "平台账户",
        path: "/users",
        element: <UserList />,
      },
      {
        name: "交易账户",
        path: "/exchange-accounts",
        element: <Accounts />,
      },
      {
        name: "角色管理",
        path: "/role-manage",
        element: <Roles />,
      },
    ],
    icon: <Icon as={ImUsers} boxSize="30px" />,
    iconSelected: <Icon as={ImUsers} boxSize="30px" />,
  },
  {
    name: "余额报表",
    path: "/balance-report",
    element: <BalanceReport />,
    icon: <Icon as={HiDocumentReport} boxSize="30px" />,
    iconSelected: <Icon as={HiDocumentReport} boxSize="30px" />,
  },
  {
    name: "系统设置",
    path: "/system-settings",
    element: <Settings />,
    icon: <Icon as={IoIosSettings} boxSize="30px" />,
    iconSelected: <Icon as={IoIosSettings} boxSize="30px" />,
  },
];

const routes = menuRoutes.reduce((acc, item) => {
  if (item.children) {
    item.children.forEach((child) => {
      acc.push({
        path: child.path,
        element: <Layout>{child.element}</Layout>,
      });
    });
  }
  acc.push({
    path: item.path,
    element: <Layout>{item.element}</Layout>,
  });
  return acc;
}, []);

routes.push(
  {
    name: "单边行情机器人",
    path: "/oneSide/:id",
    element: <Layout>{<OneSidePage />}</Layout>,
  },
  {
    name: "添加交易账户",
    path: "/exchange-accounts/create",
    element: <Layout>{<Create />}</Layout>,
  },
  {
    name: "编辑交易账户",
    path: "/exchange-accounts/detail/:id",
    element: <Layout>{<AccountEdit />}</Layout>,
  },
  {
    name: "添加用户",
    path: "/users/create",
    element: <Layout>{<UserCreate />}</Layout>,
  },
  {
    name: "用户详情",
    path: "/user/:id",
    element: <Layout>{<UserDetail />}</Layout>,
  },
  {
    name: "添加角色",
    path: "/roles/create",
    element: <Layout>{<RoleCreate />}</Layout>,
  }
);

routes.push(
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "*",
    element: <Layout>{<NotFound />}</Layout>,
  }
);

export { routes, menuRoutes };
