import numeral from "numeral";

const jsonParse = (input: string | undefined) => {
  if (!input || input === "undefined") return undefined;
  try {
    return JSON.parse(input);
  } catch (error) {
    console.error(`Not able to parse json: ${input}`);
    return undefined;
  }
};

const formatBalance = (balance: number | string, precisison = 0.000001) => {
  const decimals = String(precisison).split(".")[1].length;
  const formatString = `0.${"0".repeat(decimals)}`;
  const result = numeral(Number(balance)).format(`0,${formatString}`);
  if (result === "NaN" || result === formatString) {
    return "0";
  }
  return result;
};

const balanceToNumber = (balance: string | number) => {
  return numeral(balance).value();
};

export { jsonParse, formatBalance, balanceToNumber };
