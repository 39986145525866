import { api, ApiResponse } from "./base";
import { OpenListingType, TradeOrderType, TradeDirection } from "./types";

export const requestOrders = async (take = 1000, skip = 0): Promise<TradeOrderType[]> => {
  const res = await api.get<ApiResponse<TradeOrderType[]>>("/orders", { params: { take, skip } });
  return res.data.data;
};
// get self orders
export const requestOrdersBySelf = async (take = 1000, skip = 0): Promise<TradeOrderType[]> => {
  const res = await api.get<ApiResponse<TradeOrderType[]>>("/orders/self", { params: { take, skip } });
  return res.data.data;
};

export const requestOrdersByAccount = async (
  exchangeAccountId: number,
  take = 1000,
  skip = 0
): Promise<TradeOrderType[]> => {
  const res = await api.get<ApiResponse<TradeOrderType[]>>(`/orders/exchangeAccounts/${exchangeAccountId}`, {
    params: { take, skip },
  });
  return res.data.data;
};

export const fetchOrdersV2 = async (exchangeAccountId: number, source?: "Manual", ): Promise<TradeOrderType[]> => {
  const rest = await api.post<ApiResponse<TradeOrderType[]>>("/v2/orders/list", { exchangeAccountId, source });
  return rest.data.data;
};

export const fetchListings = async (payload: {
  exchangeAccountId: number;
  pairId: number;
  side?: TradeDirection;
}): Promise<OpenListingType[]> => {
  const { exchangeAccountId, pairId, side } = payload;
  const rest = await api.post<ApiResponse<OpenListingType[]>>("/v2/orders/openList", {
    ...payload,
  });
  return rest.data.data;
};

export const requestCancelAllOrders = async (payload: {
  pairId: number;
  exchangeAccountId: number;
  orderIds?: string[];
  side?: TradeDirection;
}) => {
  const ret = await api.post<ApiResponse<any>>("/v2/orders/cancels", payload);
  return ret.data.data;
};
