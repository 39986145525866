import { api, ApiResponse } from "./base";
import {
  ClosePositionRequest,
  ClosePositionResponse,
  FetchOrderResponse,
  FetchPositionResponse,
  OpenPositionRequest,
  OpenPositionResponse,
  PairResponse,
  PerpBaseRequest,
  UbPositionConfig
} from "./types";

export const requestOpenPosition = async (payload: OpenPositionRequest): Promise<OpenPositionResponse> => {
  const res = await api.post<ApiResponse<OpenPositionResponse>>("/v2/contract/openPositions", payload);
  return res.data.data;
};
export const requestClosePosition = async (payload: ClosePositionRequest): Promise<ClosePositionResponse> => {
  const res = await api.post<ApiResponse<ClosePositionResponse>>("/v2/contract/closePositions", payload);
  return res.data.data;
};
export const fetchPosition = async (payload: PerpBaseRequest): Promise<FetchPositionResponse[]> => {
  const res = await api.post<ApiResponse<FetchPositionResponse[]>>("/v2/contract/fetchPositions", payload);
  return res.data.data;
};
export const fetchPerpBalance = async (payload: PerpBaseRequest): Promise<any> => {
  const res = await api.post<ApiResponse<any>>("/v2/contract/balance", payload);
  return res.data.data;
};
export const fetchPerpOrders = async (payload: PerpBaseRequest): Promise<FetchOrderResponse[]> => {
  const res = await api.post<ApiResponse<any>>("/v2/contract/openList", payload);
  return res.data.data;
};

export const fetchPairs = async () => {
  const res = await api.get<ApiResponse<PairResponse>>("/v2/contract/pairs");
  return res.data.data;
};

export const requestCancelOrder = async (
  payload: PerpBaseRequest & {
    orderId: string;
    type: string;
    symbol: string;
  }
): Promise<any> => {
  const res = await api.post<ApiResponse<any>>("/v2/contract/cancels", payload);
  return res.data.data;
};

export const requestSetTpSl = async (payload:PerpBaseRequest & { symbol: string; side: string; tpPrice: number; slPrice: number }) =>{
  const res = await api.post<ApiResponse<any>>("/v2/contract/setSlTp", payload)
  return res.data.data;
}

export const requestAdjustMargin = async (payload:PerpBaseRequest & { symbol: string; side: string; amount: number }) =>{
  const res = await api.post<ApiResponse<any>>("/v2/contract/updatePositionMargin", payload)
  return res.data.data;
}

export const requestSetLeverage = async (payload: PerpBaseRequest & { leverage: number; symbol: string }) => {
  const res = await api.post<ApiResponse<any>>("/v2/contract/setLeverage", payload);
  return res.data.data;
};

export const fetchPositionConfig = async (payload: PerpBaseRequest& { symbol: string}) => {
  const res = await api.post<ApiResponse<UbPositionConfig>>("/v2/contract/positionConfigs", payload);
  return res.data.data;
};

export const requestMarketPrice = async (payload:PerpBaseRequest & { symbol: string }) =>{
  const res = await api.post<ApiResponse<any>>("/v2/contract/getMarkPrice", payload)
  return res.data.data;
}