import { api, ApiResponse } from "./base";
import {
  CbPerpBalanceResponse,
  CbPositionConfig,
  ClosePositionRequest,
  ClosePositionResponse,
  FetchOrderResponse,
  FetchPositionResponse,
  MarginType,
  OpenPositionRequest,
  OpenPositionResponse,
  PairResponse,
  PerpBaseRequest,
  CBHistoryPositionResponse
} from "./types";

export const requestOpenPosition = async (payload: OpenPositionRequest): Promise<OpenPositionResponse> => {
  const res = await api.post<ApiResponse<OpenPositionResponse>>("/v2/delivery/openPositions", payload);
  return res.data.data;
};
export const requestClosePosition = async (payload: ClosePositionRequest): Promise<ClosePositionResponse> => {
  const res = await api.post<ApiResponse<ClosePositionResponse>>("/v2/delivery/closePositions", payload);
  return res.data.data;
};
export const fetchPosition = async (payload: PerpBaseRequest): Promise<FetchPositionResponse[]> => {
  const res = await api.post<ApiResponse<FetchPositionResponse[]>>("/v2/delivery/fetchPositions", payload);
  return res.data.data;
};
export const fetchPerpBalance = async (payload: PerpBaseRequest): Promise<CbPerpBalanceResponse[]> => {
  const res = await api.post<ApiResponse<CbPerpBalanceResponse[]>>("/v2/delivery/balance", payload);
  return res.data.data;
};
export const fetchPerpOrders = async (payload: PerpBaseRequest): Promise<FetchOrderResponse[]> => {
  const res = await api.post<ApiResponse<any>>("/v2/delivery/openList", payload);
  return res.data.data;
};

export const fetchPairs = async () => {
  const res = await api.get<ApiResponse<PairResponse>>("/v2/delivery/pairs");
  return res.data.data;
};

export const fetchPositionConfig = async (payload: PerpBaseRequest) => {
  const res = await api.post<ApiResponse<CbPositionConfig[]>>("/v2/delivery/positionConfigs", payload);
  return res.data.data;
};

export const requestSetMarginType = async (payload: PerpBaseRequest & { marginType: MarginType; symbol: string }) => {
  const res = await api.post<ApiResponse<any>>("/v2/delivery/setMarginType", payload);
  return res.data.data;
};
//

export const requestSetLeverage = async (payload: PerpBaseRequest & { leverage: number; symbol: string }) => {
  const res = await api.post<ApiResponse<any>>("/v2/delivery/setLeverage", payload);
  return res.data.data;
};

export const requestMarketPrice = async (payload:PerpBaseRequest & { symbol: string }) =>{
  const res = await api.post<ApiResponse<any>>("/v2/delivery/getMarkPrice", payload)
  return res.data.data
}

export const requestSetTpSl = async (payload:PerpBaseRequest & { symbol: string; side: string; tpPrice: number; slPrice: number }) =>{
  const res = await api.post<ApiResponse<any>>("/v2/delivery/setSlTp", payload)
  return res.data.data
}

export const requestCancelOrder = async (
  payload: PerpBaseRequest & {
    orderId: string;
    type: string;
    symbol: string;
  }
): Promise<any> => {
  const res = await api.post<ApiResponse<any>>("/v2/delivery/cancels", payload);
  return res.data.data;
};

export const requestAdjustMargin = async (payload:PerpBaseRequest & { symbol: string; side: string; amount: number }) =>{
  const res = await api.post<ApiResponse<any>>("/v2/delivery/updatePositionMargin", payload)
  return res.data.data
}

export const requestSwitchPositionSide = async (payload:PerpBaseRequest & { both: boolean}) =>{
  const res = await api.post<ApiResponse<any>>("/v2/delivery/updatePositionSide", payload)
  return res.data
}

export const fetchHistoryPosition = async (payload:PerpBaseRequest & { symbol: string}) =>{
  const res = await api.post<ApiResponse<CBHistoryPositionResponse>>("/v2/delivery/historyPosition", payload)
  return res.data.data
}