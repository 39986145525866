export const orderCols = [
  {
    key: "id",
    title: "ID",
  },
  {
    key: "orderId",
    title: "订单ID",
  },
  {
    key: "create_time",
    title: "创建时间",
  },
  {
    key: "contract",
    title: "合约",
  },
  {
    key: "symbol",
    title: "币对",
  },
  {
    key: "size",
    title: "大小",
  },
  {
    key: "side",
    title: "方向",
  },
  {
    key: "price",
    title: "价格",
  },
  {
    key: "type",
    title: "类型",
  },
];

export const REFETCH_INTERVAL = 5 * 1000;
