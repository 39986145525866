import { Flex, Text } from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import AccountSelect from "src/component/AccountSelect/AccountSelect";
import Cut from "src/component/Bot/Cut/Cut";
import OneSideConfigDetails from "src/component/Bot/OneSide/OneSideConfigDetails";
import OneSide from "src/component/Bot/OneSide/OneSide";
import ExchangeSelect from "src/component/ExchangeSelect/ExchangeSelect";

export default function OneSidePage() {
  const { id } = useParams();

  return (
    <Flex width="100%">
      <Flex width="calc(100% - 20px)" flexDirection="column">
        <Flex mt={6} justifyContent="center">
          {!!id ? <OneSideConfigDetails base={"BTC"} quote={"USDT"} /> : <OneSide base={"BTC"} quote={"USDT"} />}
        </Flex>
      </Flex>
    </Flex>
  );
}
