import { Flex, Text } from "@chakra-ui/react";
import styles from "./index.module.scss";
import CButton from "src/component/CButton/CButton";
import { Field, Formik } from "formik";
import { Form } from "react-router-dom";
import * as Yup from "yup";
import CInput from "src/component/CInput/CInput";
import { Permission } from "src/consts";

export default function RoleCreate() {
  const onSubmit = (values: any) => {
    console.log(values);
  };

  return (
    <Flex width="100%" flexDirection="column">
      <Flex width="100%" height="100%" px={4} py={4}>
        <Formik
          initialValues={{}}
          validationSchema={Yup.object({})}
          onSubmit={(values) => {
            alert(JSON.stringify(values, null, 2));
            onSubmit(values);
          }}
        >
          {({ values, setFieldValue, submitForm }) => {
            return (
              <Form className={styles.formContainer}>
                <Flex width="100%" justifyContent="space-between" height="40px" alignItems="end">
                  <Text fontSize="1.2em" fontWeight="bold">
                    创建账户
                  </Text>
                  <CButton name={"保存"} type="submit" onClick={submitForm} />
                </Flex>
                <Flex flexWrap="wrap" width="100%">
                  <Flex flexWrap="wrap" gap={2} rowGap={2} justifyContent="space-around" width="calc(100% - 20px)">
                    <Flex
                      width="100%"
                      alignItems="center"
                      gap={2}
                      mt={4}
                      justifyContent="space-between"
                      flexDirection="column"
                    >
                      <CInput classname={styles.createAccountInputField} name="name" label={"角色名称"} />
                      <Flex justifyContent="space-between" className={styles.createAccountInputField}>
                        <Text>可操作范围</Text>
                        <Flex gap={2} role="group" aria-labelledby="checkbox-group">
                          <Flex gap={2}>
                            <Field type="checkbox" name="permission" value={Permission.tradeStrategy} />
                            交易策略
                          </Flex>
                          <Flex gap={2}>
                            <Field type="checkbox" name="permission" value={Permission.manualTrade} />
                            手动交易
                          </Flex>
                          <Flex gap={2}>
                            <Field type="checkbox" name="permission" value={Permission.userManagement} />
                            用户管理
                          </Flex>
                        </Flex>
                      </Flex>
                      <Flex justifyContent="space-between" className={styles.createAccountInputField}>
                        <Text>操作权限</Text>
                        <Flex gap={2} role="group" aria-labelledby="checkbox-group">
                          <Flex gap={2}>
                            <Field type="checkbox" name="operation" value="add" />
                            添加
                          </Flex>
                          <Flex gap={2}>
                            <Field type="checkbox" name="operation" value="delete" />
                            删除
                          </Flex>
                          <Flex gap={2}>
                            <Field type="checkbox" name="operation" value="modify" />
                            修改
                          </Flex>
                          <Flex gap={2}>
                            <Field type="checkbox" name="operation" value="query" />
                            查询
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Form>
            );
          }}
        </Formik>
      </Flex>
    </Flex>
  );
}
