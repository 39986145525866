import { Box, Flex, HStack, Text, useRadioGroup } from "@chakra-ui/react";
import CRadioCard from "src/component/CRadioCard";
import VolumeForm from "./VolumeForm";
import { useState } from "react";
import SingleSideForm from "./SingleSideForm";
import EatOrderForm from "./EatOrderForm";
import LowBuyHighSellForm from "./LowBuyHighSellForm";
import AutoSellForm from "./AutoSellForm";
import DcaForm from "./DcaForm";
import ReduceGapForm from "./ReduceGapForm";
import DepthBidAndOfferForm from "./DepthBidAndOfferForm";
import { useLocation, useNavigate } from "react-router-dom";
import { StrategyKey } from "src/api/types";

type StrategyOption = {
  key: string;
  name: string;
  component?: JSX.Element;
};
const STRATEGIES: StrategyOption[] = [
  { key: StrategyKey.Volume, name: "刷量", component: <VolumeForm /> },
  { key: StrategyKey.OneSide, name: "单边行情", component: <SingleSideForm /> },
  { key: StrategyKey.Eat, name: "吃单", component: <EatOrderForm /> },
  { key: StrategyKey.LowBuyHighSell, name: "低买高卖", component: <LowBuyHighSellForm /> },
  { key: StrategyKey.AutoSell, name: "自动卖出", component: <AutoSellForm /> },
  { key: StrategyKey.Dca, name: "Dca", component: <DcaForm /> },
  { key: StrategyKey.Stuck, name: "卡单", component: <ReduceGapForm /> },
  { key: StrategyKey.BidAndOffer, name: "摆盘", component: <DepthBidAndOfferForm /> },
];
export default function NewStrategy() {
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const tab = query.get("tab");
  const defaultStrategy = STRATEGIES.find((item) => item.key === tab) || STRATEGIES[0];
  const [selectedStrategy, setSelectedStrategy] = useState<StrategyOption>(defaultStrategy);
  const handleSelectStrategy = (strategyOption: StrategyOption) => {
    query.set("tab", strategyOption.key);
    navigate(`?${query.toString()}`, { replace: true });
    setSelectedStrategy(strategyOption);
  };

  const { component } = selectedStrategy;
  return (
    <Flex width="100%" flexDirection="column">
      <Flex alignItems="center" gap={2}>
        <Text>策略类型:</Text>
        <StrategySelect
          selectedExchange={selectedStrategy}
          onChange={(strategyOption: StrategyOption) => {
            console.log("strategy option", strategyOption);
            handleSelectStrategy(strategyOption);
          }}
        />
      </Flex>
      <Box maxHeight="calc(100vh - 200px)" overflowY="scroll">
        {component || <Text mt={4}>策略开发中...</Text>}
      </Box>
    </Flex>
  );
}

function StrategySelect(props: {
  selectedExchange: StrategyOption;
  onChange: (strategyOption: StrategyOption) => void;
}) {
  const selectedExchange = props.selectedExchange;
  const options = STRATEGIES.map((exchange) => exchange.name);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "strategy",
    defaultValue: selectedExchange.name,
    onChange: (value: string) => {
      const selectedExchange = STRATEGIES.find((item) => item.name === value);
      if (!selectedExchange) {
        throw new Error(`Exchange name not found: ${value}`);
      }
      props.onChange(selectedExchange);
    },
  });

  const group = getRootProps();

  return (
    <HStack {...group}>
      {options.map((value) => {
        const radio = getRadioProps({ value });
        return (
          <CRadioCard key={value} {...radio}>
            {value}
          </CRadioCard>
        );
      })}
    </HStack>
  );
}
