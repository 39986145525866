import { Flex } from "@chakra-ui/react";
import CInput from "../CInput/CInput";

export default function CommonDcaForm(props: { styles: any }) {
  const { styles } = props;

  // priceLow7: number;
  // priceHigh7: number;
  // firstPrice7: number;
  // priceGap7: number;
  // orderNum7: number;
  // totalAmount7: number;
  // profitGap7: number;
  // stopAndSell7: number;
  // sellOrderNum7: number;
  // sellInterval7: number;

  return (
    <>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput classname={styles.inputField} label="策略名称" name="strategyName" />
      </Flex>

      <Flex>
        <CInput
          classname={styles.inputField}
          label="价格区间（最低）"
          name="priceLow7"
          type="number"
          description="低于这个价格，DCA机器人不会生效"
        />
      </Flex>
      <Flex>
        <CInput
          classname={styles.inputField}
          label="价格区间（最高）"
          name="priceHigh7"
          type="number"
          description="高于这个价格，DCA机器人不会生效"
        />
      </Flex>
      <Flex>
        <CInput
          classname={styles.inputField}
          label="首次价格"
          name="firstPrice7"
          type="number"
          description="DCA的首单价格，当卖1低于则会触发买入最小量，然后启动DCA机器人"
        />
      </Flex>
      <Flex>
        <CInput
          classname={styles.inputField}
          label="价差比例"
          name="priceGap7"
          type="number"
          description="DCA的每一笔订单的价格下滑比例，锚定首单价格来计算"
          rightIndicator="%"
        />
      </Flex>
      <Flex>
        <CInput
          classname={styles.inputField}
          label="DCA订单数量"
          name="orderNum7"
          type="number"
          description=""
        />
      </Flex>
      <Flex>
        <CInput
          classname={styles.inputField}
          label="投入总金额"
          name="totalAmount7"
          type="number"
          description=""
        />
      </Flex>
      <Flex>
        <CInput classname={styles.inputField} label="止盈比例" name="profitGap7" type="number" description="利润间隔" />
      </Flex>
      <Flex>
        <CInput
          classname={styles.inputField}
          label="卖出分成几笔订单"
          name="sellOrderNum7"
          type="number"
          description="如果开启了订单止盈独立，那么这个参数无效"
        />
      </Flex>
      <Flex>
        <CInput
          classname={styles.inputField}
          label="卖出间隔"
          name="sellInterval7"
          type="number"
          description="如果开启了订单止盈独立，那么这个参数无效"
        />
      </Flex>

       <Flex>
        <CInput classname={styles.inputField} label="重复间隔(最小值)" name="repeatIntervalMsMin" type="number" />
      </Flex>

      <Flex>
        <CInput classname={styles.inputField} label="重复间隔(最大值)" name="repeatIntervalMsMax" type="number" />
      </Flex>
    </>
  );
}
