import React from "react";
import { Box, useColorModeValue, keyframes } from "@chakra-ui/react";

const glow = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const ConnectionStatus = ({ isConnected }) => {
  const color = isConnected ? "green.500" : "red.500";
  const animation = isConnected ? `${glow} 1s infinite` : "none";

  return <Box width="10px" height="10px" borderRadius="50%" backgroundColor={color} animation={animation} />;
};

export default ConnectionStatus;
