import { useExchangeInfoStore } from "src/store";
import CSelect from "../CSelect";
import styles from "./ExchangeSelect.module.scss";
import { TradeType } from "src/api/types";

type Props = {
  tradeType: TradeType;
};

export default function ExchangeSelect(props: Props) {
  const { tradeType } = props;
  const store = useExchangeInfoStore();
  console.log(`ExchangeSelect store:`, store);
  const {
    exchanges,
    selectedSpotPair,
    selectedUbPerpPair,
    selectedCbPerpPair,
    selectedExchangeKey,
    setSelectedExchangeKey,
  } = store;
  let options = exchanges.map((exchange) => {
    return {
      value: exchange.id,
      label: exchange.name,
    };
  });

  switch (tradeType) {
    case TradeType.SPOT:
      if (!!selectedSpotPair && !!selectedSpotPair.exchanges) {
        options = options.filter((option) => {
          return selectedSpotPair.exchanges.includes(option.label);
        });
      }
      break;
    case TradeType.UB_PERP:
      if (!!selectedUbPerpPair && !!selectedUbPerpPair.exchanges) {
        options = options.filter((option) => {
          return selectedUbPerpPair.exchanges.includes(option.label);
        });
      }
      break;
    case TradeType.CB_PERP:
      if (!!selectedCbPerpPair && !!selectedCbPerpPair.exchanges) {
        options = options.filter((option) => {
          return selectedCbPerpPair.exchanges.includes(option.label);
        });
      }
      break;
  }


  console.log(`ExchangeSelect selectedExchangeKey:`, options, selectedUbPerpPair);
  


  const defaultOption = {
    value: selectedExchangeKey,
    label: selectedExchangeKey,
  };

  const handleChange = (option) => {
    const value = option.label;
    const selectedExchange = exchanges.find((item) => item.name === value);
    if (!selectedExchange) {
      throw new Error(`Exchange name not found: ${value}`);
    }
    setSelectedExchangeKey(selectedExchange.key);
  };

  return (
    <CSelect
      classname={styles.container}
      label={"交易所"}
      placeholder="选择交易所"
      options={options}
      onChange={handleChange}
      defaultValue={defaultOption}
    />
  );
}
