import { Flex } from "@chakra-ui/react";
import styles from "./index.module.scss";
import CButton from "src/component/CButton/CButton";
import CTable from "src/component/CTable/Ctable";
import { useNavigate } from "react-router-dom";
import { Permission, Operation } from "src/consts";

const items = [
  {
    id: 1,
    name: "Admin",
    permissions: [Permission.manualTrade, Permission.tradeStrategy, Permission.userManagement],
    operations: [Operation.add, Operation.delete, Operation.modify, Operation.query],
  },
  {
    id: 2,
    name: "Manager",
    permissions: [Permission.manualTrade, Permission.tradeStrategy, Permission.userManagement],
    operations: [Operation.add, Operation.delete, Operation.modify, Operation.query],
  },
  {
    id: 3,
    name: "Trader",
    permissions: [Permission.manualTrade, Permission.tradeStrategy],
    operations: [Operation.add, Operation.delete, Operation.modify, Operation.query],
  },
];
const cols = [
  { key: "name", title: "角色名称" },
  { key: "permissions", title: "可操作范围", render: (value) => <span>{value.permissions.join(",")}</span> },
  { key: "operations", title: "操作权限", render: (value) => <span>{value.operations.join(",")}</span> },
];

export default function Roles() {
  const navigate = useNavigate();
  const handleSearch = () => {
    console.log("search");
  };

  const handleAdd = () => {
    navigate("/roles/create");
  };

  return (
    <Flex width="100%" flexDirection="column">
      <Flex width="100%" justifyContent="space-between" height="40px" alignItems="end">
        <Flex className={styles.header}></Flex>
        <CButton name={"添加角色"} onClick={handleAdd} />
      </Flex>

      <Flex width="100%" maxWidth="calc(100vw - 220px)" height="100%" px={4} py={4}>
        <CTable classname={styles.tableContainer} items={items} cols={cols} showHeader />
      </Flex>
    </Flex>
  );
}
