import { Flex } from "@chakra-ui/react";
import CInput from "../CInput/CInput";

export default function CommonSingleSideForm(props: { styles: any }) {
  const { styles } = props;
  return (
    <>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput classname={styles.inputField} label="策略名称" name="strategyName" type="text" />
      </Flex>

      <Flex alignItems="center" gap={2} mt={4}>
        <CInput classname={styles.inputField} label="总交易币数量" name="totalAmount" type="number" />
      </Flex>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput classname={styles.inputField} label="触发价格(低值)" name="priceTriggerMin" type="number" />
      </Flex>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput classname={styles.inputField} label="触发价格(高值)" name="priceTriggerMax" type="number" />
      </Flex>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput classname={styles.inputField} label="每单最小数量" name="minQuantity" type="number" />
      </Flex>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput classname={styles.inputField} label="每单最大数量" name="maxQuantity" type="number" />
      </Flex>
        <Flex>
        <CInput classname={styles.inputField} label="重复间隔(最小值)" name="repeatIntervalMsMin" type="number" />
      </Flex>

      <Flex>
        <CInput classname={styles.inputField} label="重复间隔(最大值)" name="repeatIntervalMsMax" type="number" />
      </Flex>
    </>
  );
}
