import { useState } from "react";
import { useMessage } from "src/hook/useMessage";
import { useExchangeInfoStore } from "src/store";
import CField from "../CField/CField";
import { accountApi } from "src/api";
import { useQuery } from "@tanstack/react-query";
import { MultiGetBalanceResponse } from "src/api/types";
import { Box, Flex } from "@chakra-ui/react";
import { MULTI_BALANCE_DECIMAL_PLACES } from "src/consts";
import { formatBalance } from "src/utils/string";

export const MultiBalance = () => {
  const store = useExchangeInfoStore();
  const { selectedExchangeKey, selectedSpotPair: selectedPair } = store;
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(true);
  const message = useMessage();
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const selectedExchangeAccountId = useExchangeInfoStore((state) => state.selectedExchangeAccountId);
  const selectedPairId = selectedPair.id;

  const balancesQuery = useQuery({
    queryKey: ["balances", selectedPairId, selectedExchangeAccountId],
    queryFn: async () => {
      const result = await accountApi.fetchMultipleBalances({
        exchangeAccountId: Number(0),
        pairId: 0,
      });
      return result;
    },
    refetchInterval: 5000,
  });

  const balanceDatas: MultiGetBalanceResponse[] = balancesQuery.data || [];

  console.log("balanceDatas", balanceDatas);
  // all uniq balance name
  const balanceNames = balanceDatas.reduce((acc, balanceInfo) => {
    balanceInfo.balances?.forEach((balance) => {
      if (!acc.includes(balance.name)) {
        acc.push(balance.name);
      }
    });
    return acc;
  }, []);
  console.log("balanceNames", balanceNames);
  // total balance for each name
  const totalBalances = balanceNames.map((name) => {
    const total = balanceDatas.reduce((acc, balanceInfo) => {
      const balance = balanceInfo.balances?.find((b) => b.name === name);
      if (balance) {
        acc += balance.free;
      }
      return acc;
    }, 0);
    return { name, total };
  });
  console.log("totalBalances", totalBalances);

  return (
    <Flex flexDirection="column" gap={2}>
      {balancesQuery.isLoading && <div>loading...</div>}
      {balancesQuery.isSuccess && (
        <Flex justifyContent="space-between" border="1px solid grey" borderRadius="5px" gap={2} padding={2}>
          <Box fontSize="20px" fontWeight={600}>
            总资产
          </Box>
          <Flex flexDirection="column" width={400}>
            {Object.keys(totalBalances).map((key) => {
              return (
                <Flex key={`total-balance-${key}`} width="100%" justifyContent="space-between">
                  <CField label={totalBalances[key].name} value={formatBalance(totalBalances[key].total)} />
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      )}
      {balanceDatas.map((balanceInfo) => {
        return (
          <>
            <Flex
              key={`exchange-account-${balanceInfo.id}`}
              justifyContent="space-between"
              border="1px solid grey"
              borderRadius="5px"
              gap={2}
              padding={2}
            >
              <Flex flexDir="column">
                <CField label={"ID"} value={balanceInfo.id} />
                <CField label={"交易所"} value={balanceInfo.exchangeName} />
                <CField label={"昵称"} value={balanceInfo.alias} />
              </Flex>
              <Flex flexDir="column" width="400px">
                {balanceInfo.balances?.map((balance) => {
                  return (
                    <Flex key={`balance-${balance.name}`} width="100%" justifyContent="space-between">
                      <CField label={balance.name} value={formatBalance(balance.free)} />
                      <CField label="冻结" value={formatBalance(balance.lock)} />
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
          </>
        );
      })}
    </Flex>
  );
};
