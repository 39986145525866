const LOCAL_STORAGE_PREFIX = "TRADING_APP_";

const TOKEN_KEY = `${LOCAL_STORAGE_PREFIX}TOKEN`;
const USER_KEY = `${LOCAL_STORAGE_PREFIX}USER`;
const ROLES_KEY = `${LOCAL_STORAGE_PREFIX}ROLES`;

const SELECTED_EXCHANGE_KEY = `${LOCAL_STORAGE_PREFIX}SELECTED_EXCHANGE`;
const SELECTED_ACCOUNT_ID_KEY = `${LOCAL_STORAGE_PREFIX}SELECTED_ACCOUNT_ID`;
const SELECTED_PAIR_KEY = `${LOCAL_STORAGE_PREFIX}SELECTED_PAIR`;

const EXCHANGES_KEY = `${LOCAL_STORAGE_PREFIX}EXCHANGES`;
const EXCHANGE_ACCOUNTS_KEY = `${LOCAL_STORAGE_PREFIX}EXCHANGE_ACCOUNTS`;
const MY_EXCHANGE_ACCOUNTS_KEY = `${LOCAL_STORAGE_PREFIX}MY_EXCHANGE_ACCOUNTS`;
const PAIRS_KEY = `${LOCAL_STORAGE_PREFIX}PAIRS`;

// perpeptual
export const UB_PERP_PAIRS_KEY = `${LOCAL_STORAGE_PREFIX}UB_PERP_PAIRS`;
export const UB_PERP_SELECTED_PAIR_KEY = `${LOCAL_STORAGE_PREFIX}UB_PERP_SELECTED_PAIR`;

// cb perp
export const CB_PERP_PAIRS_KEY = `${LOCAL_STORAGE_PREFIX}CB_PERP_PAIRS`;
export const CB_PERP_SELECTED_PAIR_KEY = `${LOCAL_STORAGE_PREFIX}CB_PERP_SELECTED_PAIR`;

const STRATEGY_LIST_TYPE_FILTER_KEY = `${LOCAL_STORAGE_PREFIX}STRATEGY_LIST_TYPE_FILTER`;
const STRATEGY_LIST_PAIR_FILTER_KEY = `${LOCAL_STORAGE_PREFIX}STRATEGY_LIST_PAIR_FILTER`;
const STRATEGY_LIST_ACTIVE_FILTER_KEY = `${LOCAL_STORAGE_PREFIX}STRATEGY_LIST_ACTIVE_FILTER`;
const STRATEGY_LIST_SYMBOL_FILTER_KEY = `${LOCAL_STORAGE_PREFIX}STRATEGY_LIST_SYMBOL_FILTER`;

const MULTI_BALANCE_DECIMAL_PLACES = 7;
// 9999 years later
const MAX_EXPIRE_DATE = new Date(9999, 1, 1);

const TRADINGVIEW_CEX_NAME_MAP = {
  binance: "BINANCE",
  bitfinex: "BITFINEX",
  bittrex: "BITTREX",
  coinbase: "COINBASE",
  huobi: "HUOBI",
  gate: "GATEIO",
  gateio: "GATEIO",
};

enum Permission {
  tradeStrategy = "交易策略",
  manualTrade = "手动交易",
  userManagement = "用户管理",
}
enum Operation {
  add = "添加",
  delete = "删除",
  modify = "修改",
  query = "查询",
}

export {
  TOKEN_KEY,
  USER_KEY,
  ROLES_KEY,
  SELECTED_EXCHANGE_KEY,
  SELECTED_ACCOUNT_ID_KEY,
  SELECTED_PAIR_KEY,
  EXCHANGES_KEY,
  EXCHANGE_ACCOUNTS_KEY,
  PAIRS_KEY,
  MAX_EXPIRE_DATE,
  TRADINGVIEW_CEX_NAME_MAP,
  MULTI_BALANCE_DECIMAL_PLACES,
  MY_EXCHANGE_ACCOUNTS_KEY,
  STRATEGY_LIST_TYPE_FILTER_KEY,
  STRATEGY_LIST_PAIR_FILTER_KEY,
  STRATEGY_LIST_ACTIVE_FILTER_KEY,
  STRATEGY_LIST_SYMBOL_FILTER_KEY,
  Permission,
  Operation,
};
