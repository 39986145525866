import {
  useDisclosure,
  Flex,
  InputGroup,
  InputLeftElement,
  Spinner,
  Input,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Box,
  Tooltip,
} from "@chakra-ui/react";
import { Formik } from "formik";
import React, { useState } from "react";
import { Form } from "react-router-dom";
import { traderApi, multiExApi, accountApi } from "src/api";
import { PairItem, TradeDirection } from "src/api/types";
import { useMessage } from "src/hook/useMessage";
import { useExchangeInfoStore } from "src/store";
import CInput from "../CInput/CInput";
import * as Yup from "yup";
import styles from "./Limit.module.scss";
import { ErrorMessage } from "src/utils/ErrorMessage";
import { useQuery } from "@tanstack/react-query";
import CSelect from "../CSelect";
import CField from "../CField/CField";
import CButton from "../CButton/CButton";
import { QuestionIcon } from "@chakra-ui/icons";
import CSwitch from "../CSwitch/CSwitch";
import { formatDate } from "src/utils/date";
import classNames from "classnames";
import { formatBalance } from "src/utils/string";

// 拉盘
export function PumpForm(props: { pair: PairItem; onClose: () => void }) {
  return <PumpOrSmashForm pair={props.pair} side={TradeDirection.Buy} onClose={props.onClose} />;
}
export function SmashForm(props: { pair: PairItem; onClose: () => void }) {
  return <PumpOrSmashForm pair={props.pair} side={TradeDirection.Sell} onClose={props.onClose} />;
}

function PumpOrSmashForm(props: { pair: PairItem; side: TradeDirection; onClose: () => void }) {
  const store = useExchangeInfoStore();
  const message = useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const supportExQuery = useQuery({
    queryKey: ["fetchSupportedExchanges", { pairId: store.selectedSpotPair.id }],
    queryFn: () => multiExApi.fetchSupportedExchanges({ pairId: store.selectedSpotPair.id }),
  });
  console.log("supportExQuery", supportExQuery);

  if (!supportExQuery.data) {
    return null;
  }
  const supportedExchanges = supportExQuery.data || [];

  const initialValues = {
    targetPrice: "",
  };
  supportedExchanges.forEach((ex) => {
    // set enabled default to true
    initialValues[`enabled-${ex.exchangeName}`] = true;
    initialValues[`orderNum-${ex.exchangeName}`] = 1;
    initialValues[`exchangeAccountId-${ex.exchangeName}`] = ex.exchangeAccounts[0].exchangeAccountId;
  });
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        targetPrice: Yup.number().required("必填字段"),
      })}
      onSubmit={onOpen}
    >
      {({ values, setFieldValue, submitForm }) => {
        const getEnabledExchanges = () => {
          return supportedExchanges.filter((ex) => {
            return values[`enabled-${ex.exchangeName}`] === true;
          });
        };
        const handleEstimate = async (inputPrice: string, setFieldValue: any) => {
          const targetPrice = Number(inputPrice);
          if (isNaN(targetPrice)) return message.error("请输入正确的价格");
          const enabledExchanges = getEnabledExchanges();
          console.log("enabledExchanges", enabledExchanges);

          const estimateResult = await multiExApi.fetchEstimate({
            targetPrice,
            exchangeNames: enabledExchanges.map((ex) => ex.exchangeName),
            pairId: store.selectedSpotPair.id,
            side: props.side,
          });
          console.log("estimateResult", estimateResult);
          estimateResult.forEach((er) => {
            setFieldValue(`baseAmount-${er.exchangeName}`, er.baseAmount);
            setFieldValue(`pricePercentage-${er.exchangeName}`, er.pricePercentage);
            setFieldValue(`isRed-${er.exchangeName}`, er.red);
            setFieldValue(`lastUpdated-${er.exchangeName}`, er.lastUpdated);
            setFieldValue(`targetPrice-${er.exchangeName}`, er.targetPrice);
            // market price
            setFieldValue(`marketPrice-${er.exchangeName}`, er.marketPrice);
          });
        };

        const handleSubmit = async (values) => {
          if (isLoading) return;
          console.log("handleSubmit values", values);
          const enabledExchanges = getEnabledExchanges();

          const payload = {
            pairId: Number(store.selectedSpotPair.id),
            side: props.side,
            submits: enabledExchanges.map((ex) => {
              return {
                exchangeName: ex.exchangeName,
                exchangeAccountId: values[`exchangeAccountId-${ex.exchangeName}`],
                baseAmount: Number(values[`baseAmount-${ex.exchangeName}`]),
                orderNum: Number(values[`orderNum-${ex.exchangeName}`]),
                targetPrice: Number(values[`targetPrice-${ex.exchangeName}`]),
              };
            }),
          };
          console.log("pumpOrSmashV2 payload", payload);
          try {
            setIsLoading(true);
            const result = await multiExApi.submitRequest(payload);
            console.log("result", result);
            message.success("下单成功");
            props.onClose();
          } catch (error) {
            message.detailedError(<ErrorMessage error={error as any} />);
            console.error("pumpOrSmashV2 error", error);
            console.error("pumpOrSmashV2 params: ", payload);
          } finally {
            setIsLoading(false);
          }
        };
        return (
          <Box>
            <Form>
              <Flex gap={2} alignItems="center">
                <CInput
                  classname={styles.input}
                  label="目标价格"
                  name="targetPrice"
                  type="number"
                  placeholder="0"
                  onChange={(e) => setFieldValue("targetPrice", e.target.value)}
                />
                <CButton name="估算总量" onClick={() => handleEstimate(values.targetPrice, setFieldValue)} />
                <Tooltip label="根据目标价格自动估算每个交易所需要下单的币数量，并自动填入表单" aria-label="A tooltip">
                  <QuestionIcon cursor="pointer" />
                </Tooltip>
              </Flex>
              <Flex flexDirection="column" alignItems={'center'} gap={4} mt={4} maxHeight="500px" overflowY="scroll">
                {supportedExchanges.map((ex) => {
                  const exchangeSelectOptions = ex.exchangeAccounts.map((ea) => {
                    return { value: ea.exchangeAccountId, label: ea.alias };
                  });
                  const pricePercentage = values[`pricePercentage-${ex.exchangeName}`]?.toFixed(2);
                  const lastUpdatedTime = formatDate(values[`lastUpdated-${ex.exchangeName}`] || "");

                  return (
                    <ExchangeItem
                      ex={ex}
                      values={values}
                      setFieldValue={setFieldValue}
                      exchangeSelectOptions={exchangeSelectOptions}
                      pricePercentage={pricePercentage}
                      lastUpdatedTime={lastUpdatedTime}
                    />
                  );
                })}
              </Flex>

              <Flex justifyContent="center" mt={4}>
                <Flex width="auto">
                  <InputGroup>
                    {isLoading && (
                      <InputLeftElement pointerEvents="none">
                        <Spinner size="sm" color="gray.300" />
                      </InputLeftElement>
                    )}
                    <button
                      type="submit"
                      color="white"
                      disabled={isLoading}
                      onClick={submitForm}
                      className={styles.submitBtn}
                      style={{ backgroundColor: props.side === TradeDirection.Buy ? "teal" : "#f08080" }}
                    >
                      {props.side === TradeDirection.Buy ? "拉盘" : "砸盘"}
                    </button>
                  </InputGroup>
                </Flex>
              </Flex>
            </Form>

            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    确认交易
                  </AlertDialogHeader>

                  <AlertDialogBody>确定立即{props.side === TradeDirection.Buy ? "拉" : "砸"}盘吗？</AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      取消
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        handleSubmit(values);
                      }}
                      ml={3}
                    >
                      确定
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </Box>
        );
      }}
    </Formik>
  );
}

type ExchangeItemProps = {
  ex: any;
  values: any;
  setFieldValue: any;
  exchangeSelectOptions: any;
  pricePercentage: any;
  lastUpdatedTime: string;
};

function ExchangeItem(props: ExchangeItemProps) {
  const { ex, values, setFieldValue, exchangeSelectOptions, pricePercentage, lastUpdatedTime } = props;
  const store = useExchangeInfoStore();
  const [selectedIndex, setSelectedIndex] = useState(exchangeSelectOptions[0]);

  const exchangeAccountId = selectedIndex.value;
  const pairId = store.selectedSpotPair.id;

  const balanceInfoQuery = useQuery({
    queryKey: ["PumpOrSmashFormbalanceInfo", "ExchangeItemProps", selectedIndex, exchangeAccountId, pairId],
    queryFn: async () => {
      const result = await accountApi.fetchBalances({ exchangeAccountId, pairId });
      return result;
    },
    enabled: !!exchangeAccountId,
    refetchInterval: 5000,
  });

  const balanceInfo = balanceInfoQuery.data || [];

  return (
    <div key={ex.exchangeName} className={styles.pumpInfoSection}>
      <Flex alignItems="center">
        <CField label="交易所" value={ex.exchangeName} />
        <CSwitch
          label={""}
          onChange={setFieldValue}
          isChecked={values[`enabled-${ex.exchangeName}`]}
          name={`enabled-${ex.exchangeName}`}
        />
        <Flex width="400px" marginLeft="auto">
          <CSelect
            options={exchangeSelectOptions}
            label="选择账户"
            name={`exchangeAccountId-${ex.exchangeName}`}
            defaultValue={exchangeSelectOptions[0]}
            onChange={(selectedOption, index) => {
              setFieldValue(`exchangeAccountId-${ex.exchangeName}`, (selectedOption as any).value);
              setSelectedIndex(selectedOption as any);
            }}
          />
        </Flex>
      </Flex>
      <Flex alignItems="center"  gap={"80px"}>
        {balanceInfo.map((item) => {
          return (
            <Flex key={item.name} gap={"40px"}>
              <CField label={`${item.name}-可用`} value={formatBalance(item.free, item.precision)} />
              <CField label={`${item.name}-冻结`} value={formatBalance(item.lock, item.precision)} />
            </Flex>
          );
        })}
      </Flex>

      <Flex gap={2} rowGap={2} justifyContent="space-between">
        <CInput
          classname={styles.pumpInput}
          label="下单量(币)"
          name={`baseAmount-${ex.exchangeName}`}
          type="number"
          placeholder="0"
        />
        <CInput
          classname={styles.pumpInput}
          label="目标价"
          name={`targetPrice-${ex.exchangeName}`}
          type="number"
          placeholder="0"
        />
        <CInput
          classname={styles.pumpInput}
          label="总下单数"
          name={`orderNum-${ex.exchangeName}`}
          type="number"
          description="不填默认为1"
          placeholder="1"
        />
      </Flex>
      {(!!pricePercentage || !!lastUpdatedTime) && (
        <Flex gap={4}>
          <CField label="最新市价" value={`${values[`marketPrice-${ex.exchangeName}`]}`} />
          <CField
            label="价格波动"
            value={`${pricePercentage}%`}
            classname={classNames({ [styles.isRed]: values[`isRed-${ex.exchangeName}`] })}
          />
          <CField label="最后更新" value={lastUpdatedTime} />
        </Flex>
      )}
    </div>
  );
}
