import { Flex } from "@chakra-ui/react";
import { Formik } from "formik";
import { Form } from "react-router-dom";
import { strategiesApi } from "src/api";
import { AutoSellExampleType, StrategyResponse, StrategyType, UpdateStrategyParams } from "src/api/types";
import { useExchangeInfoStore } from "src/store";
import CButton from "../CButton/CButton";
import styles from "./StrategyDetail.module.scss";
import * as Yup from "yup";
import { useExchangeAccounts } from "src/hook/useExchangeAccounts";
import { useMessage } from "src/hook/useMessage";
import CommonAutoSellForm from "../StrategyForms/CommonAutoSellForm";
import { ErrorMessage } from "src/utils/ErrorMessage";

type Props = {
  strategy: StrategyResponse<AutoSellExampleType>;
  onUpdated?: () => void;
};

export default function AutoSellDetail(props: Props) {
  const { strategy, onUpdated } = props;
  const message = useMessage();

  return (
    <Formik
      initialValues={{
        strategyName: strategy.strategyName,
        repeatIntervalMsMin: strategy.repeatIntervalMsMin,
        repeatIntervalMsMax: strategy.repeatIntervalMsMax,

        timeWindows5: strategy.preference.timeWindows5,
        upPer5: strategy.preference.upPer5,
        down5Market: strategy.preference.down5Market,
        sellAmount5: strategy.preference.sellAmount5,
      }}
      validationSchema={Yup.object({
        strategyName: Yup.string().required("Required"),
        
      })}
      onSubmit={async (values) => {
        console.log("====================================");
        console.log("values", values);
        console.log("====================================");
        alert(JSON.stringify(values, null, 2));
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form className={styles.form}>
            <Flex width="calc(100% - 20px)" flexDirection="column" gap={2}>
              <CommonAutoSellForm styles={styles} />
              <Flex alignItems="center" gap={2} mt={4}>
                <CButton
                  name="保存"
                  type="submit"
                  onClick={async () => {
                    const payload: UpdateStrategyParams<AutoSellExampleType> = {
                      strategyId: strategy.id,
                      strategyName: values.strategyName,
                      repeatIntervalMsMin: values.repeatIntervalMsMin,
                      repeatIntervalMsMax: values.repeatIntervalMsMax,
                      preference: {
                        type: StrategyType.AutoSell,
                        timeWindows5: Number(values.timeWindows5),
                        upPer5: Number(values.upPer5),
                        down5Market: Number(values.down5Market),
                        sellAmount5: Number(values.sellAmount5),
                      },
                    };
                    try {
                      const result = await strategiesApi.updateStrategyV2(payload);
                      message.success("更新成功");
                      onUpdated && onUpdated();
                    } catch (error) {
                      message.detailedError(<ErrorMessage error={error} />);
                    }
                  }}
                />
              </Flex>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
}
