import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useState } from "react";
import AccountSelect from "src/component/AccountSelect/AccountSelect";
import ExchangeSelect from "src/component/ExchangeSelect/ExchangeSelect";
import Limit from "src/component/Limit/Limit";
import OpenListing from "src/component/OpenListing/OpenListing";
import PairSelect from "src/component/PairSelect/PairSelect";
import TradeOrder from "src/component/TradeOrder/TradeOrder";
import TradingViewWidget from "src/component/TradingViewWidget";
import { useExchangeInfoStore } from "src/store";
import { getPairSymbol, getTradingviewCexName } from "src/store/utils";
import { useMessage } from "src/hook/useMessage";
import { isSelectedEmpty } from "src/utils/states";
import { orderApi } from "src/api";
import { ErrorMessage } from "src/utils/ErrorMessage";
import WssOrderBook from "./WssOrderBook";
import { TradeType } from "src/api/types";

export default function Home() {
  const store = useExchangeInfoStore();
  const { selectedExchangeKey, selectedSpotPair: selectedPair } = store;
  const message = useMessage();
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const selectedExchangeAccountId = useExchangeInfoStore((state) => state.selectedExchangeAccountId);

  const handleCancelAll = async () => {
    if (!selectedPair) {
      message.error("请选择交易对");
      return;
    }
    const selectedPairId = selectedPair.id;
    if (isSelectedEmpty(selectedExchangeAccountId)) {
      message.error("请选择交易所账户");
      return;
    }
    const config = {
      pairId: Number(selectedPairId),
      exchangeAccountId: Number(selectedExchangeAccountId),
    };
    try {
      const result = await orderApi.requestCancelAllOrders(config);
      message.success("取消成功");
    } catch (error) {
      message.detailedError(<ErrorMessage error={error} />);
    }
  };
  return (
    <Flex width="100%" maxH="calc(100vh - 140px)" px={2} justifyContent="space-between" gap={12} marginY={"auto"}>
      <Flex
        width="calc(50% - 20px)"
        maxW="600px"
        flexDirection="column"
        overflow="scroll"
        gap={2}
        style={{ scrollbarWidth: "none" }}
        justifyContent="space-between"
      >
        <Flex flexDir={"column"} gap={2}>
          <Flex alignItems="center" gap={2}>
            <PairSelect tradeType={TradeType.SPOT} />
          </Flex>
          <Flex alignItems="center" gap={2}>
            <ExchangeSelect tradeType={TradeType.SPOT} />
          </Flex>
          <Flex alignItems="center" gap={2}>
            <AccountSelect />
          </Flex>
        </Flex>
        <Flex mt={6}>
          <Limit pair={selectedPair} />
        </Flex>
        <Flex width="100%" border="1px solid gray" borderRadius="10px" mt={4}>
          <Tabs variant="enclosed" index={tabIndex} onChange={handleTabsChange} width={"100%"}>
            <TabList>
              <Tab>委托订单</Tab>
              <Tab>订单历史</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Flex mt={6} overflow="scroll" style={{ scrollbarWidth: "none" }}>
                  <OpenListing handleCancelAll={handleCancelAll} />
                </Flex>
              </TabPanel>
              <TabPanel>
                <Flex mt={6} overflow="scroll" style={{ scrollbarWidth: "none" }}>
                  <TradeOrder paginated />
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
      <Flex width="calc(50% - 20px)" flexGrow={1} flexDirection="column" justifyContent={"space-between"}>
        <Flex height="400px" mb={8}>
          <TradingViewWidget
            pairName={getPairSymbol(selectedPair.base, selectedPair.quote)}
            cexName={getTradingviewCexName(selectedExchangeKey)}
          />
        </Flex>
        <WssOrderBook />
      </Flex>
    </Flex>
  );
}
