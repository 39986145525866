import { useExchangeInfoStore } from "src/store";
import CSelect from "../CSelect";
import styles from "./PairSelect.module.scss";
import { TradeType } from "src/api/types";
import { PairType } from "src/config/pairs";
import { useEffect, useState } from "react";
type Props = {
  tradeType: TradeType;
};
export default function PairSelect(props: Props) {
  const { tradeType } = props;
  const store = useExchangeInfoStore();
  const {
    spotPairs,
    ubPerpPairs,
    cbPerpPairs,

    selectedSpotPair,
    setSelectedSpotPair,

    selectedUbPerpPair,
    setSelectedUbPerpPair,

    selectedCbPerpPair,
    setSelectedCbPerpPair,
  } = store;
  const [pairs, setPairs] = useState<PairType[]>([]);
  const [selectedPair, setSelectedPair] = useState<PairType | null>(null);
  const [selectedOption, setSelectedOption] = useState(null);
  useEffect(() => {
    switch (tradeType) {
      case TradeType.SPOT:
        setPairs(spotPairs);
        setSelectedPair(selectedSpotPair);
        if (selectedSpotPair) {
          setSelectedOption({
            value: selectedSpotPair.id,
            label: `${selectedSpotPair.base}-${selectedSpotPair.quote}`,
          });
        }
        break;
      case TradeType.UB_PERP:
        setPairs(ubPerpPairs);
        setSelectedPair(selectedUbPerpPair);
        if (selectedUbPerpPair) {
          setSelectedOption({
            value: selectedUbPerpPair.id,
            label: `${selectedUbPerpPair.base}-${selectedUbPerpPair.quote}`,
          });
        }
        break;
      case TradeType.CB_PERP:
        setPairs(cbPerpPairs);
        setSelectedPair(selectedCbPerpPair);
        if (selectedCbPerpPair) {
          setSelectedOption({
            value: selectedCbPerpPair.id,
            label: `${selectedCbPerpPair.base}-${selectedCbPerpPair.quote}`,
          });
        }
        break;
    }
  }, [
    tradeType,
    spotPairs,
    ubPerpPairs,
    cbPerpPairs,
    selectedSpotPair,
    setSelectedSpotPair,
    selectedUbPerpPair,
    setSelectedUbPerpPair,
    selectedCbPerpPair,
    setSelectedCbPerpPair,
    pairs,
    selectedPair,
  ]);

  const options = pairs.map((pair) => {
    return {
      value: pair.id,
      label: `${pair.base}-${pair.quote}`,
    };
  });

  console.log(`pairs :`, store, selectedPair, selectedOption);

  const handleChange = (selectedOption) => {
    const targetPair = pairs.find((pair) => pair.id === selectedOption.value);
    console.log("handleChange targetPair:", targetPair);

    switch (tradeType) {
      case TradeType.SPOT:
        setSelectedSpotPair(targetPair);
        break;
      case TradeType.UB_PERP:
        setSelectedUbPerpPair(targetPair);
        break;
      case TradeType.CB_PERP:
        setSelectedCbPerpPair(targetPair);
        break;
    }
  };
  return (
    <CSelect
      classname={styles.container}
      label={"交易对"}
      placeholder="选择交易对"
      options={options}
      onChange={handleChange}
      value={selectedOption}
    />
  );
}
