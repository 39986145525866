import React, { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useExchangeInfoStore } from "src/store";
import { accountApi } from "src/api";
import AccountSelect from "src/component/AccountSelect/AccountSelect";
import ExchangeSelect from "src/component/ExchangeSelect/ExchangeSelect";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { TradeType } from "src/api/types";

// 注册 Chart.js 组件
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// 定义接口以确保数据结构一致性
interface Balance {
  name: string;
  free: number;
  lock: number;
  precision: number;
}

interface BalanceReportItem {
  exchangeAccountId: number;
  createdAtHour: string;
  balances: Balance[] | null;
}

interface ChartData {
  labels: string[];
  datasets: any[]; // 使用 any[] 以简化，如果需要可以进一步细化类型
}

const BalanceReport: React.FC = () => {
  const selectedExchangeAccountId = useExchangeInfoStore((state) => state.selectedExchangeAccountId);

  // 使用 useQuery 获取数据，仅在 selectedExchangeAccountId 存在时启用查询
  const balanceDataQuery = useQuery<BalanceReportItem[], Error>({
    queryKey: ["balances-report", selectedExchangeAccountId],
    queryFn: async () => {
      const result = await accountApi.fetchAccountBalanceHistory({
        exchangeAccountId: Number(selectedExchangeAccountId),
      });
      // 确保返回的数据是一个数组，即使 API 返回 null 或其他类型
      return Array.isArray(result) ? result : [];
    },
    enabled: !!selectedExchangeAccountId, // 仅在 selectedExchangeAccountId 存在时启用查询
    staleTime: 1000 * 60 * 5, // 数据5分钟内不重新获取
  });

  // 转换 query.data 为 Chart.js 所需的 data 配置
  const chartData: ChartData = useMemo(() => {
    if (!balanceDataQuery.data || balanceDataQuery.data.length === 0) {
      return { labels: [], datasets: [] };
    }

    const queryResult = balanceDataQuery.data;

    // 1. 提取 labels，确保每个项都有 createdAtHour
    const labels = queryResult
      .map((item) => item.createdAtHour)
      .filter((label): label is string => typeof label === "string");

    // 2. 获取所有唯一的 balance 名称，过滤掉 null 或 undefined
    const balanceNames = [
      ...new Set(queryResult.flatMap((item) => item.balances?.map((balance) => balance.name) || [])),
    ];

    // 如果没有 balanceNames，则返回空 datasets
    if (balanceNames.length === 0) {
      return { labels, datasets: [] };
    }

    // 3. 为每个 balance 名称分配颜色
    const colorPalette = [
      "rgb(255, 99, 132)",
      "rgb(54, 162, 235)",
      "rgb(255, 206, 86)",
      "rgb(75, 192, 192)",
      "rgb(153, 102, 255)",
      "rgb(255, 159, 64)",
      // 添加更多颜色如果需要
    ];

    // 使用 HSL 动态生成颜色以避免重复
    const generateColor = (index: number): string => {
      const hue = (index * 137.5) % 360; // 黄金角度，避免颜色过于相似
      return `hsl(${hue}, 65%, 55%)`;
    };

    const colorMap: Record<string, { borderColor: string; backgroundColor: string }> = {};
    balanceNames.forEach((name, index) => {
      const color = generateColor(index);
      colorMap[name] = {
        borderColor: color,
        backgroundColor: color.replace("hsl", "hsla").replace(")", ", 0.5)"),
      };
    });

    // 4. 构建 datasets，并为每个数据集分配唯一的 yAxisID
    const datasets = balanceNames.map((name, index) => {
      const data = queryResult.map((item) => {
        const balance = item.balances?.find((b) => b.name === name);
        return balance ? Math.round(balance.free + balance.lock) : 0;
      });

      return {
        label: name,
        data,
        borderColor: colorMap[name].borderColor,
        backgroundColor: colorMap[name].backgroundColor,
        yAxisID: `y${index}`, // 分配唯一的 yAxisID，如 "y0", "y1", "y2" 等
        fill: false, // 根据需要调整
      };
    });

    return {
      labels,
      datasets,
    };
  }, [balanceDataQuery.data]);

  const { labels, datasets } = chartData;

  // 动态生成 scales 配置
  const dynamicOptions = useMemo(() => {
    const scalesConfig: Record<string, any> = {};

    datasets.forEach((dataset, index) => {
      const axisId = dataset.yAxisID as string;

      // 如果已经定义过该轴，则跳过
      if (scalesConfig[axisId]) return;

      scalesConfig[axisId] = {
        type: "linear",
        display: true,
        position: index % 2 === 0 ? "left" : "right",
        grid: {
          drawOnChartArea: index === 0, // 仅在第一个 Y 轴上绘制网格线
        },
        title: {
          display: true,
          text: dataset.label, // 可选：为每个 Y 轴添加标题
        },
      };
    });

    return {
      responsive: true,
      interaction: {
        mode: "index" as const,
        intersect: false,
      },
      stacked: false,
      plugins: {
        legend: {
          position: "top" as const,
        },
        title: {
          display: true,
          text: "Balances Report",
        },
      },
      scales: scalesConfig,
    };
  }, [datasets]);

  // 处理加载状态
  if (balanceDataQuery.isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100vh" flexDir={"column"}>
        <Spinner size="xl" />
      </Flex>
    );
  }

  // 处理错误状态
  if (balanceDataQuery.isError) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100vh">
        <Text color="red.500">错误: {balanceDataQuery.error.message || "未知错误"}</Text>
      </Flex>
    );
  }

  // 处理无数据状态
  if (labels.length === 0 || datasets.length === 0) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        height="100vh"
        flexDir="column"
        width="calc(50% - 20px)"
        maxW="600px"
        gap={2}
      >
        <ExchangeSelect tradeType={TradeType.SPOT} />
        <AccountSelect />
      </Flex>
    );
  }

  return (
    <Flex
      alignItems="flex-start"
      flexDir={"column"}
      width={"100%"}
      p={4} // 添加一些内边距
      boxSizing="border-box"
      justifyContent="space-between"
    >
      <Flex width="calc(50% - 20px)" maxW="600px" gap={2} flexDir="column">
        <ExchangeSelect tradeType={TradeType.SPOT} />
        <AccountSelect />
      </Flex>
      <Line options={dynamicOptions} data={{ labels, datasets }} />
    </Flex>
  );
};

export default BalanceReport;
