import { Box, Text } from "@chakra-ui/react";

export const ErrorMessage = ({ error }: { error: any }) => {
  return (
    <Box>
      <Text>错误码：{error.code}</Text>
      <Text>消息：{error.message}</Text>
      <Text>原因：{error.reason}</Text>
    </Box>
  );
};
