import { Flex } from "@chakra-ui/react";
import CInput from "../CInput/CInput";

export default function CommonAutoSellForm(props: { styles: any }) {
  const { styles } = props;

  return (
    <>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput classname={styles.inputField} label="策略名称" name="strategyName" />
      </Flex>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput
          classname={styles.inputField}
          label="时间窗口（单位分）"
          name="timeWindows5"
          type="number"
          description={`例子： 输入30，表示 最近30分钟`}
        />
      </Flex>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput
          classname={styles.inputField}
          label="上涨比例"
          name="upPer5"
          type="number"
          description="例如 50 等于上涨 50%"
          rightIndicator="%"
        />
      </Flex>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput
          classname={styles.inputField}
          label="下跌比例"
          name="down5Market"
          type="number"
          description="当时间窗口内的价格走势满足（下跌比例），进行吃买单，把价格控制到当前市价的下跌百分比"
          rightIndicator="%"
        />
      </Flex>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput
          classname={styles.inputField}
          label="卖出数量"
          name="sellAmount5"
          type="number"
          description="0表示不设置。 达标的时候会计算（市价下跌比例的量和该值）的最小值去提交订单"
        />
      </Flex>
       <Flex>
        <CInput classname={styles.inputField} label="重复间隔(最小值)" name="repeatIntervalMsMin" type="number" />
      </Flex>

      <Flex>
        <CInput classname={styles.inputField} label="重复间隔(最大值)" name="repeatIntervalMsMax" type="number" />
      </Flex>
    </>
  );
}
