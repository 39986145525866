import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import styles from "./index.module.scss";
import CButton from "src/component/CButton/CButton";
import CSwitch from "src/component/CSwitch/CSwitch";
import CField from "src/component/CField/CField";
import { strategiesApi } from "src/api";
import { StrategyResponse, StrategyType } from "src/api/types";
import { useExchangeInfoStore } from "src/store";
import { useExchangeAccounts } from "src/hook/useExchangeAccounts";
import { getPairName } from "src/store/utils";
import { useMessage } from "src/hook/useMessage";
import React from "react";
import { ErrorMessage } from "src/utils/ErrorMessage";

type ExchangeConfigProps = {
  isEnabled: boolean;
  type: StrategyType;
  onOpenEdit: () => void;
  toggleEnabled: () => void;
  onOpenOrder: () => void;
  onDelete: () => void;
  onOpenStatus: () => void;
} & StrategyResponse<any>;

export function ExchangeConfig(props: ExchangeConfigProps) {
  const accounts = useExchangeAccounts();
  const exchanges = useExchangeInfoStore((state) => state.exchanges);
  const pairs = useExchangeInfoStore((state) => state.spotPairs);
  const {
    strategyName,
    id: strategyId,
    type,
    exchangeAccountId,
    pairId,
    isEnabled,
    toggleEnabled,
    onOpenOrder,
    onOpenEdit,
    onOpenStatus,
    onDelete,
    accountId,
    accountName,
  } = props;
  const pair = pairs.find((pair) => pair.id === pairId);
  const message = useMessage();

  console.log("allExchangeAccounts", accounts.myExchangeAccounts, accounts.allExchangeAccounts);
  console.log("exchanges", exchanges);

  const exchangeAccount = [...accounts.myExchangeAccounts, ...accounts.allExchangeAccounts].find(
    (account) => account.id === exchangeAccountId
  );
  const exchange = exchangeAccount
    ? exchanges.find((exchange) => exchange.id === exchangeAccount.exchangeId)
    : undefined;

  const handleDelete = async () => {
    try {
      await strategiesApi.deleteStrategy(strategyId);
      onDelete();
      message.success("删除成功");
    } catch (error) {
      message.detailedError(<ErrorMessage error={error} />);
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const getFieldClassName = () => {
    if (type !== StrategyType.SingleSide) {
      return "";
    }
    if (props.preference?.side === "buy") {
      return styles.green;
    }
    if (props.preference?.side === "sell") {
      return styles.red;
    }
    return "";
  };
  const fieldClassName = getFieldClassName();
  return (
    <>
      <Flex gap={2} rowGap={2} justifyContent="space-between" width="calc(100% - 20px)" my={2}>
        <Flex alignItems="center" gap={2} mt={4} width="200px" justifyContent="space-between" flexDirection="column">
          <Flex flexDirection="column">
            <Box fontSize="lg" fontWeight={500} textAlign="center">
              {strategyName}
            </Box>
            <Box fontSize="sm" fontWeight={500} textAlign="center">
              ID: {strategyId}
            </Box>
          </Flex>
          {/* <CButton name="参数设置" onClick={() => navigate(`/strategy/${strategyId}?isEdit=1`)} /> */}
          <CButton name="参数设置" onClick={onOpenEdit} />
          <CButton name="交易详情" onClick={onOpenOrder} />
          <CButton name="状态面板" onClick={onOpenStatus} />
        </Flex>
        <Divider orientation="vertical" height="auto" width="2px" bg="gray" />
        <Flex width="100%" flexDirection="column" justifyContent="space-around">
          <Flex alignItems="center" gap={2} mt={4} width="100%" justifyContent="space-between">
            <Flex flexDirection="column" gap={1} flexGrow={1}>
              <CField label={"策略类型"} value={type} classname={fieldClassName} />
              {type === StrategyType.SingleSide && (
                <CField label={"方向"} value={props.preference?.side} classname={fieldClassName} />
              )}
              <CField
                label={"交易对"}
                value={pair ? getPairName(pair.base, pair.quote) : "未知"}
                classname={fieldClassName}
              />
              <CField label={"交易所"} value={exchange ? exchange.name : "未知"} classname={fieldClassName} />
              <CField
                label={"交易账户"}
                value={exchangeAccount ? exchangeAccount.alias : "未知"}
                classname={fieldClassName}
              />
              <CField label={"创建人"} value={accountName || accountId} classname={fieldClassName} />
            </Flex>
            <Flex gap={2} flexDirection="column">
              <CSwitch label={"开关"} name="switch" onChange={toggleEnabled} isChecked={isEnabled} />
              <Button onClick={onOpen} colorScheme="red">
                {" "}
                删除{" "}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              确认删除
            </AlertDialogHeader>

            <AlertDialogBody>确定立即删除机器人吗？</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                取消
              </Button>
              <Button
                onClick={() => {
                  onClose();
                  handleDelete();
                }}
                ml={3}
              >
                确定
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
