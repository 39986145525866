import { Flex, Text } from "@chakra-ui/react";
import styles from "./index.module.scss";
import CButton from "src/component/CButton/CButton";
import { Formik } from "formik";
import { Form, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import CInput from "src/component/CInput/CInput";
import CSelect from "src/component/CSelect";
import { useExchangeInfoStore } from "src/store";
import { createExchangeAccount } from "src/api/exchangeInfo";
import { getPairName } from "src/store/utils";
import { adminApi } from "src/api";

export default function AccountCreate() {
  const navigate = useNavigate();
  const exchangeState = useExchangeInfoStore((state) => state.exchanges);
  console.log("exchangeState", exchangeState);

  const store = useExchangeInfoStore();
  const pairs = store.spotPairs;
  const pairOptions = pairs.map((pair) => {
    return {
      label: getPairName(pair.base, pair.quote),
      value: pair.id,
    };
  });

  const interfaceTypeOptions = exchangeState.map((item) => {
    return {
      label: item.name,
      value: String(item.id),
    };
  });

  return (
    <Flex width="100%" flexDirection="column">
      <Flex width="100%" height="100%" px={4} py={4}>
        <Formik
          initialValues={{
            accountName: "",
            accessKey: "",
            secretKey: "",
            pem: "",
            exchange: null,
            pairId: null,
          }}
          validationSchema={Yup.object({
            accountName: Yup.string().required("Required"),
            accessKey: Yup.string().required("Required"),
            secretKey: Yup.string().required("Required"),
            exchange: Yup.object().required("Required"),
            pairId: Yup.object().required("Required"),
          })}
          onSubmit={async (values) => {
            alert(JSON.stringify(values, null, 2));
            const createExchangeAccountParam = {
              exchangeId: Number(values.exchange.value),
              alias: values.accountName,
              apiKey: values.accessKey,
              pem: values.pem,
              secret: values.secretKey,
            };
            console.log("createExchangeAccountParam", createExchangeAccountParam);
            const result = await createExchangeAccount(createExchangeAccountParam);

            console.log("createExchangeAccount", result);
            const pairId = Number(values.pairId.value);
            const assignPairResult = await adminApi.assignPairToExchangeAccount(result.id, pairId);
            console.log("assignPairResult", assignPairResult);

            navigate("/exchange-accounts");
          }}
        >
          {({ values, setFieldValue, submitForm, errors }) => {
            return (
              <Form className={styles.formContainer}>
                <Flex width="100%" justifyContent="space-between" height="40px" alignItems="end">
                  <Text fontSize="1.2em" fontWeight="bold">
                    创建交易所账户
                  </Text>
                  <CButton name={"保存"} type="submit" onClick={submitForm} />
                </Flex>
                <Flex flexWrap="wrap" width="100%">
                  <Flex flexWrap="wrap" gap={2} rowGap={2} justifyContent="space-around" width="calc(100% - 20px)">
                    <Flex
                      width="100%"
                      alignItems="center"
                      gap={2}
                      mt={4}
                      justifyContent="space-between"
                      flexDirection="column"
                    >
                      <CInput required classname={styles.createAccountInputField} name="accountName" label={"昵称"} />
                      <CInput
                        required
                        classname={styles.createAccountInputField}
                        name="accessKey"
                        label={"Access_Key"}
                      />
                      <CInput
                        required
                        classname={styles.createAccountInputField}
                        name="secretKey"
                        label={"Secret_Key"}
                      />
                      <CInput classname={styles.createAccountInputField} name="pem" label={"Pem"} placeholder="可选" />
                      <CSelect
                        classname={styles.createAccountInputField}
                        name="exchange"
                        label={"交易所选择"}
                        options={interfaceTypeOptions}
                        onChange={(value) => {
                          setFieldValue("exchange", value);
                        }}
                      />
                      <CSelect
                        classname={styles.createAccountInputField}
                        name="pair"
                        label={"交易对"}
                        options={pairOptions}
                        onChange={(value) => {
                          setFieldValue("pairId", value);
                        }}
                      />
                      {/* <CInput classname={styles.createAccountInputField} name="uid" label={"Uid or Password"} /> */}
                      {/* <CSelect
                        classname={styles.createAccountInputField}
                        name="exchange"
                        label={"接口类型"}
                        options={interfaceTypeOptions}
                      />
                      <CSelect
                        classname={styles.createAccountInputField}
                        name="pair"
                        label={"交易对"}
                        options={pairOptions}
                      />
                     
                      {/* <CInput classname={styles.createAccountInputField} name="description" label={"账户描述"} /> */}
                    </Flex>
                  </Flex>
                </Flex>
              </Form>
            );
          }}
        </Formik>
      </Flex>
    </Flex>
  );
}
