import { ExchangeAccount, RoleType } from "src/api/types";
import { EXCHANGES, ExchangeType } from "src/config/exchanges";
import { PAIRS, PairType } from "src/config/pairs";
import {
  CB_PERP_PAIRS_KEY,
  CB_PERP_SELECTED_PAIR_KEY,
  EXCHANGE_ACCOUNTS_KEY,
  EXCHANGES_KEY,
  PAIRS_KEY,
  ROLES_KEY,
  SELECTED_EXCHANGE_KEY,
  SELECTED_PAIR_KEY,
  UB_PERP_PAIRS_KEY,
  UB_PERP_SELECTED_PAIR_KEY,
} from "src/consts";
import { create } from "zustand";

type ExchangeInfoType = {
  selectedExchangeKey: string;
  setSelectedExchangeKey: (exchangeKey: string) => void;

  roles: RoleType[];
  setRoles: (roles: RoleType[]) => void;

  exchanges: ExchangeType[];
  setExchanges: (exchanges: ExchangeType[]) => void;

  spotPairs: PairType[];
  setSpotPairs: (pairs: PairType[]) => void;

  selectedSpotPair: PairType;
  setSelectedSpotPair: (pair: PairType) => void;

  ubPerpPairs: PairType[];
  setUbPerpPairs: (pairs: PairType[]) => void;

  selectedUbPerpPair: PairType | null;
  setSelectedUbPerpPair: (pair: PairType) => void;

  cbPerpPairs: PairType[];
  setCbPerpPairs: (pairs: PairType[]) => void;

  selectedCbPerpPair: PairType | null;
  setSelectedCbPerpPair: (pair: PairType) => void;

  exchangeAccounts: ExchangeAccount[];
  setExchangeAccounts: (exchangeAccounts: ExchangeAccount[]) => void;

  myExchangeAccounts: ExchangeAccount[];
  setMyExchangeAccounts: (exchangeAccounts: ExchangeAccount[]) => void;

  selectedExchangeAccountId: number;
  setSelectedExchangeAccountId: (exchangeAccountId: number) => void;
};

// do we need to set default value here?
export const useExchangeInfoStore = create<ExchangeInfoType>((set) => {
  const defaultExchangeKey = localStorage.getItem(SELECTED_EXCHANGE_KEY) || EXCHANGES[0].key;
  const defaultExchangeAccounts: ExchangeAccount[] = JSON.parse(localStorage.getItem(EXCHANGE_ACCOUNTS_KEY)) || [];

  const selectPairString = localStorage.getItem(SELECTED_PAIR_KEY);
  const selectedUbPerpPairString = localStorage.getItem(UB_PERP_SELECTED_PAIR_KEY);
  const selectedCbPerpPairString = localStorage.getItem(CB_PERP_SELECTED_PAIR_KEY);

  const PAIRS_KEY_STRING = localStorage.getItem(PAIRS_KEY);
  const UB_PERP_PAIRS_KEY_STRING = localStorage.getItem(UB_PERP_PAIRS_KEY);
  const CB_PERP_PAIRS_KEY_STRING = localStorage.getItem(CB_PERP_PAIRS_KEY);

  const defaultSelectedPair = !!selectPairString ? JSON.parse(selectPairString) : PAIRS[0];
  const defaultUbSelectedPair = selectedUbPerpPairString ? JSON.parse(selectedUbPerpPairString) : null;
  const defaultCbSelectedPair = selectedCbPerpPairString ? JSON.parse(selectedCbPerpPairString) : null;

  const defaultPairs = PAIRS_KEY_STRING ? JSON.parse(PAIRS_KEY_STRING) : PAIRS;
  const defaultUbPairs = UB_PERP_PAIRS_KEY_STRING ? JSON.parse(UB_PERP_PAIRS_KEY_STRING) : [];
  const defaultCbPairs = CB_PERP_PAIRS_KEY_STRING ? JSON.parse(CB_PERP_PAIRS_KEY_STRING) : [];

  return {
    selectedExchangeKey: defaultExchangeKey,
    setSelectedExchangeKey: (exchangeKey: string) => {
      localStorage.setItem(SELECTED_EXCHANGE_KEY, exchangeKey);
      return set({ selectedExchangeKey: exchangeKey });
    },

    roles: [],
    setRoles: (roles: RoleType[]) => {
      localStorage.setItem(ROLES_KEY, JSON.stringify(roles));
      return set({ roles });
    },

    exchanges: EXCHANGES,
    setExchanges: (exchanges: ExchangeType[]) => {
      localStorage.setItem(EXCHANGES_KEY, JSON.stringify(exchanges));
      return set({ exchanges });
    },

    spotPairs: defaultPairs,
    setSpotPairs: (pairs: PairType[]) => {
      localStorage.setItem(PAIRS_KEY, JSON.stringify(pairs));
      return set({ spotPairs: pairs });
    },

    selectedSpotPair: defaultSelectedPair,
    setSelectedSpotPair: (pair: PairType) => {
      if (!pair) return;
      localStorage.setItem(SELECTED_PAIR_KEY, JSON.stringify(pair));
      return set({ selectedSpotPair: pair });
    },

    ubPerpPairs: defaultUbPairs,
    setUbPerpPairs: (pairs: PairType[]) => {
      localStorage.setItem(UB_PERP_PAIRS_KEY, JSON.stringify(pairs));
      return set({ ubPerpPairs: pairs });
    },

    selectedUbPerpPair: defaultUbSelectedPair,
    setSelectedUbPerpPair: (pair: PairType) => {
      if (!pair) return;
      localStorage.setItem(UB_PERP_SELECTED_PAIR_KEY, JSON.stringify(pair));
      return set({ selectedUbPerpPair: pair });
    },

    cbPerpPairs: defaultCbPairs,
    setCbPerpPairs: (pairs: PairType[]) => {
      localStorage.setItem(CB_PERP_PAIRS_KEY, JSON.stringify(pairs));
      return set({ cbPerpPairs: pairs });
    },

    selectedCbPerpPair: defaultCbSelectedPair,
    setSelectedCbPerpPair: (pair: PairType) => {
      if (!pair) return;
      localStorage.setItem(CB_PERP_SELECTED_PAIR_KEY, JSON.stringify(pair));
      return set({ selectedCbPerpPair: pair });
    },

    exchangeAccounts: defaultExchangeAccounts,
    setExchangeAccounts: (exchangeAccounts: ExchangeAccount[]) => {
      return set({ exchangeAccounts });
    },

    myExchangeAccounts: [],
    setMyExchangeAccounts: (exchangeAccounts: ExchangeAccount[]) => {
      return set({ myExchangeAccounts: exchangeAccounts });
    },

    selectedExchangeAccountId: -1,
    setSelectedExchangeAccountId: (exchangeAccountId: number) => {
      return set({ selectedExchangeAccountId: exchangeAccountId });
    },
  };
});
