import { Flex, Icon, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import styles from "./index.module.scss";
import CButton from "src/component/CButton/CButton";
import CTable from "src/component/CTable/Ctable";
import { useNavigate } from "react-router-dom";
import { VscSearch } from "react-icons/vsc";
import { useQuery } from "@tanstack/react-query";
import { adminApi } from "src/api";
import { AccountResponse } from "src/api/types";

const cols = [
  { key: "username", title: "用户名" },
  { key: "uid", title: "UID" },
  { key: "roles", title: "角色", render: (value) => <span>{value.roles.join(",")}</span> },
];

export default function UserList() {
  const navigate = useNavigate();
  const operationCol = (item: AccountResponse) => {
    return (
      <Flex>
        <CButton
          name={"编辑"}
          onClick={() => {
            navigate(`/user/${item.id}`);
          }}
        />
      </Flex>
    );
  };
  const userListQuery = useQuery({
    queryKey: ["userList"],
    queryFn: async () => {
      return await adminApi.fetchAccounts();
    },
  });
  const { data: userList, isSuccess } = userListQuery;
  const accountsResponse: AccountResponse[] = userList || [];
  const items = accountsResponse.map((item) => ({
    id: item.id,
    username: item.username,
    uid: item.id,
    roles: item.roles.map((role) => role.type),
  }));
  const handleSearch = () => {
    console.log("search");
  };

  const handleAdd = () => {
    navigate("/users/create");
  };

  return (
    <Flex width="100%" flexDirection="column">
      <Flex width="100%" justifyContent="space-between" height="40px" alignItems="end">
        <Flex className={styles.header}>
          <InputGroup mt={4}>
            <InputLeftElement color="gray.300" fontSize="1.2em" pl={0} onClick={handleSearch}>
              <Icon as={VscSearch} boxSize="30px" cursor="pointer" />
            </InputLeftElement>
            <Input border="none" _hover={{ border: "none" }} pl={12} placeholder="搜索账户" />
          </InputGroup>
          <CButton name={"搜索"} />
        </Flex>
        <CButton name={"添加用户"} onClick={handleAdd} />
      </Flex>

      <Flex width="100%" maxWidth="calc(100vw - 220px)" height="100%" px={4} py={4}>
        <CTable operationCol={operationCol} classname={styles.tableContainer} items={items} cols={cols} showHeader />
      </Flex>
    </Flex>
  );
}
