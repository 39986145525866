import { TableContainer, Table, Tr, Tbody, Td, Th, Thead, Tfoot } from "@chakra-ui/react";
import styles from "./Ctable.module.scss";
import classNames from "classnames";
import { Pagination } from "./Pagination";
import { useEffect, useState } from "react";

type TableProps = {
  classname?: string;
  items: Array<any>;
  cols: Array<{
    key: string;
    render?: (item: any) => JSX.Element;
    title?: string;
  }>;
  showHeader?: boolean;
  operationCol?: (item: any) => React.ReactNode;
  operationSide?: "left" | "right";
  paginated?: boolean;
  clickableKey?: string;
  clickCallback?: () => void;
};

export default function CTable(props: TableProps) {
  const { operationCol, items, paginated, clickableKey, clickCallback } = props;
  const [showItems, setShowItems] = useState(items);
  const isOperationSideOnLeft = props.operationSide === "left";
  const [page, setPage] = useState(1);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    if (paginated) {
      const start = (page - 1) * 10;
      const end = start + 10;
      setShowItems(items.slice(start, end));
    } else {
      setShowItems(items);
    }
  }, [page, items, paginated]);

  return (
    <TableContainer width="100%" className={props.classname}>
      <Table variant="simple">
        {props.showHeader && (
          <Thead>
            <Tr>
              {operationCol && <Th>操作</Th>}
              {props.cols.map((col, index) => (
                <Th padding={"15px"} key={`${JSON.stringify(col)}-${index}`}>
                  {col.title}
                </Th>
              ))}
            </Tr>
          </Thead>
        )}
        <Tbody>
          {showItems.map((item, index) => (
            <Tr
              key={`${JSON.stringify(item)}-${index}`}
              className={classNames({
                [styles.green]: item.isGreen,
                [styles.red]: item.isRed,
              })}
            >
              {operationCol && <Td>{operationCol(item)}</Td>}
              {props.cols.map((col, colIndex) => {
                const isClickable = col.key === clickableKey;
                return (
                  <Td
                    key={`${JSON.stringify(col)}-${colIndex}`}
                    className={classNames({
                      [styles.clickable]: isClickable,
                    })}
                    onClick={() => isClickable && clickCallback?.()}
                    padding={"15px"}
                  >
                    {col.render ? col.render(item) : item[col.key]}
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>
        {paginated && (
          <Tfoot>
            <Tr>
              <Td colSpan={props.cols.length + (operationCol ? 1 : 0)}>
                <Pagination total={items.length} pageSize={10} currentPage={page} onPageChange={onPageChange} />
              </Td>
            </Tr>
          </Tfoot>
        )}
      </Table>
    </TableContainer>
  );
}
