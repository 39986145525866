import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Input,
  Flex,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { cbPerpApi } from "src/api";
import { FetchPositionResponse } from "src/api/types";
import { useMessage } from "src/hook/useMessage";
import { useExchangeInfoStore } from "src/store";
import Slider from "rc-slider"; // Import Slider from rc-slider
import "rc-slider/assets/index.css"; // Import rc-slider styles

type ClosePositionAlertProps = {
  positionItem: FetchPositionResponse | null;
  isOpen: boolean;
  onClose: () => void;
  submitForm: (payload: { amount: number }) => void;
};

export default function ClosePositionAlert(props: ClosePositionAlertProps) {
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const message = useMessage();
  const store = useExchangeInfoStore();
  const { selectedExchangeKey, selectedCbPerpPair: selectedPair } = store;
  const selectedExchangeAccountId = useExchangeInfoStore((state) => state.selectedExchangeAccountId);

  const { isOpen, onClose, positionItem } = props;

  const [amount, setAmount] = React.useState<string>("");
  const [price, setPrice] = React.useState<string>("");
  const [isMarketPrice, setIsMarketPrice] = React.useState(false);

  const positionSize = positionItem?.size || 0; // Position size

  const handleClose = () => {
    setAmount("");
    setPrice("");
    setIsMarketPrice(false);
    onClose();
  };

  const handleClosePosition = async () => {
    try {
      if (!positionItem) {
        message.detailedError(<span>Position item is null</span>);
        return;
      }

      const parsedAmount = parseFloat(amount);
      const parsedPrice = isMarketPrice ? 0 : parseFloat(price);

      if (isNaN(parsedAmount) || (parsedPrice === 0 && !isMarketPrice && price !== "")) {
        message.detailedError(<span>请输入有效的数量和价格</span>);
        return;
      }

      const param = {
        exchangeAccountId: selectedExchangeAccountId,
        exchangeName: selectedExchangeKey,
        symbol: positionItem.symbol,
        closePrice: parsedPrice,
        amount: parsedAmount,
        side: positionItem.side,
      };
      console.log("handleClosePosition:", param);
      await cbPerpApi.requestClosePosition(param);
      message.success("平仓成功");
      handleClose();
    } catch (error) {
      message.detailedError(<span>{error instanceof Error ? error.message : "An error occurred"}</span>);
    }
  };

  const handleSliderChange = (value: number) => {
    setAmount(value.toString());
  };

  const marks = {
    [positionSize / 4]: "25%",
    [positionSize / 2]: "50%",
    [(positionSize * 3) / 4]: "75%",
  };

  return (
    <>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={handleClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              平仓
            </AlertDialogHeader>

            <AlertDialogBody>
              <Flex flexDir="column" gap={4}>
                <Flex justifyContent="center">仓位: {positionSize}</Flex>
                <Flex justifyContent="space-between" gap={2}>
                  <Slider
                    min={0}
                    max={positionSize} // Slider max is position size
                    value={parseFloat(amount)}
                    onChange={handleSliderChange} // Sync slider with amount
                    step={1}
                    marks={marks} // Percentage marks
                  />
                  <Input
                    placeholder="张数"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    type="number"
                    step="any"
                    width={"30%"}
                  />
                </Flex>

                <Flex alignItems="center">
                  <Text width="180px">请输入价格</Text>
                  <Input
                    placeholder="请输入价格, 不输入代表市价平仓"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    type="number"
                    step="any"
                    disabled={isMarketPrice}
                  />
                </Flex>
              </Flex>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={handleClose}>
                取消
              </Button>
              <Button onClick={handleClosePosition} ml={3} colorScheme="red">
                确定
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
