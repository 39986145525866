import React from "react";
import { HStack, Button, IconButton, Text, Input } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

type Prop = {
  total: number;
  pageSize: number;
  currentPage: number;
  onPageChange: (page: number) => void;
};
export function Pagination({ total, currentPage, onPageChange, pageSize = 10 }: Prop) {
  const totalPages = Math.ceil(total / pageSize);
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;
  const [gotoPage, setGotoPage] = React.useState("1");

  const onGotoPageChange = (page: string) => {
    let target = Number(page);
    if (isNaN(target) || target < 1) {
      target = 1;
    } else if (target > totalPages) {
      target = totalPages;
    } else {
      target = Math.floor(target);
    }
    setGotoPage(String(target));
  };

  return (
    <HStack spacing={2}>
      <IconButton aria-label={"previous"} onClick={() => onPageChange(currentPage - 1)} isDisabled={isFirstPage}>
        <ChevronLeftIcon />
      </IconButton>
      <Button onClick={() => {}} colorScheme="blue">
        {currentPage}
      </Button>
      <IconButton aria-label={"next"} onClick={() => onPageChange(currentPage + 1)} isDisabled={isLastPage}>
        <ChevronRightIcon />
      </IconButton>
      <Text display="inline-block" marginX={4} fontWeight={500} color="blue.400">
        总数: {total}{" "}
      </Text>
      <Text display="inline-block" marginX={4} fontWeight={500} color="blue.400">
        共{totalPages}页
      </Text>
      <Input value={gotoPage} onChange={(e) => onGotoPageChange(e.target.value)} type="number" w="90px" />
      <Button onClick={() => onPageChange(Number(gotoPage))}>GO</Button>
    </HStack>
  );
}
