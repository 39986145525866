import { api, ApiResponse } from "./base";
import { MuitiCreateSupportExResp, MuitiCreateEstimateResp, TradeDirection } from "./types";

//查询支持的交易所（根据交易对）
export const fetchSupportedExchanges = async (payload: { pairId: number }) => {
  const res = await api.post<ApiResponse<MuitiCreateSupportExResp>>("/v2/orders/multiExCreate/support", payload);
  return res.data.data;
};
//估算
export const fetchEstimate = async (payload: {
  targetPrice: number;
  exchangeNames: string[];
  pairId: number;
  side: TradeDirection;
}) => {
  const res = await api.post<ApiResponse<MuitiCreateEstimateResp>>("/v2/orders/multiExCreate/estimate", payload);
  return res.data.data;
};

type SumbitParam = {
  exchangeName: string;
  exchangeAccountId: number;
  orderNum: number;
  baseAmount: number;
  targetPrice: number;
};
// 提交
export const submitRequest = async (payload: { submits: SumbitParam[] }) => {
  const res = await api.post<ApiResponse<any>>("/v2/orders/multiExCreate/submits", payload);
  return res.data.data;
};
