import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Flex,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { cbPerpApi } from "src/api";
import { OrderType, TradeDirection } from "src/api/types";
import CField from "src/component/CField/CField";
import { useMessage } from "src/hook/useMessage";
import { useExchangeInfoStore } from "src/store";
import { ErrorMessage } from "src/utils/ErrorMessage";
import { isSelectedEmpty } from "src/utils/states";

type OpenPositionAlertProps = {
  price: number;
  margin: number;
  leverage: number;
  side: TradeDirection;
  orderType: OrderType;
  coinAmount: string;
  isOpen: boolean;
  onClose: () => void;
};

export default function OpenPositionAlert(props: OpenPositionAlertProps) {
  const { price, margin, leverage, side, orderType, coinAmount } = props;
  const message = useMessage();
  const store = useExchangeInfoStore();
  const { selectedExchangeKey, selectedCbPerpPair: selectedPair } = store;
  const selectedExchangeAccountId = useExchangeInfoStore((state) => state.selectedExchangeAccountId);
  const enableQuery = useMemo(() => {
    return !isSelectedEmpty(selectedExchangeAccountId) && !!selectedExchangeKey && !!selectedPair;
  }, [selectedExchangeAccountId, selectedExchangeKey, selectedPair]);

  const cancelRef = React.useRef();
  const { isOpen, onClose } = props;

  const handleOpenPosition = async () => {
    if (!enableQuery) {
      message.error("请选择交易所和交易对");
      return;
    }
    if (!margin) {
      message.error("请输入价格和数量");
      return;
    }
    try {
      const result = await cbPerpApi.requestOpenPosition({
        exchangeName: selectedExchangeKey,
        exchangeAccountId: selectedExchangeAccountId,
        symbol: selectedPair.symbol,
        side: side,
        amount: margin,
        openPrice: orderType === OrderType.LIMIT ? price : 0,
      });
      console.log("result", result);
      message.success("下单成功");
    } catch (error) {
      message.detailedError(<ErrorMessage error={error} />);
    }
  };
  return (
    <>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              开仓
            </AlertDialogHeader>

            <AlertDialogBody>
              <Flex flexDir={"column"} gap={4}>
                <CField label={"价格"} value={price} />
                <CField label={"张数"} value={margin} />
                <CField label={"币数"} value={coinAmount} />
                <CField label={"杠杆"} value={leverage} />
                <CField
                  label={"方向"}
                  value={side === TradeDirection.Buy ? "多" : "空"}
                  color={side === TradeDirection.Buy ? "#28a745" : "red"}
                />
              </Flex>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                取消
              </Button>
              <Button
                onClick={async () => {
                  await handleOpenPosition();
                  onClose();
                }}
                ml={3}
              >
                确定
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
