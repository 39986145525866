import { Flex } from "@chakra-ui/react";
import { Formik } from "formik";
import { Form } from "react-router-dom";
import { strategiesApi } from "src/api";
import { DcaExampleType, StrategyResponse, StrategyType, UpdateStrategyParams } from "src/api/types";
import { useExchangeInfoStore } from "src/store";
import CButton from "../CButton/CButton";
import styles from "./StrategyDetail.module.scss";
import * as Yup from "yup";
import { useExchangeAccounts } from "src/hook/useExchangeAccounts";
import { useMessage } from "src/hook/useMessage";
import CommonDcaForm from "../StrategyForms/CommonDcaForm";
import CSwitch from "../CSwitch/CSwitch";
import { ErrorMessage } from "src/utils/ErrorMessage";

type Props = {
  strategy: StrategyResponse<DcaExampleType>;
  onUpdated?: () => void;
};

export default function DcaDetail(props: Props) {
  const { strategy, onUpdated } = props;
  const message = useMessage();

  return (
    <Formik
      initialValues={{
        strategyName: strategy.strategyName,
        repeatIntervalMsMin: strategy.repeatIntervalMsMin,
        repeatIntervalMsMax: strategy.repeatIntervalMsMax,

        // priceLow7: number;
        // priceHigh7: number;
        // firstPrice7: number;
        // priceGap7: number;
        // orderNum7: number;
        // totalAmount7: number;
        // profitGap7: number;
        // stopAndSell7: number;
        // sellOrderNum7: number;
        // sellInterval7: number;
        priceLow7: strategy.preference.priceLow7,
        priceHigh7: strategy.preference.priceHigh7,
        firstPrice7: strategy.preference.firstPrice7,
        priceGap7: strategy.preference.priceGap7,
        orderNum7: strategy.preference.orderNum7,
        totalAmount7: strategy.preference.totalAmount7,
        profitGap7: strategy.preference.profitGap7,
        stopAndSell7: strategy.preference.stopAndSell7,
        sellOrderNum7: strategy.preference.sellOrderNum7,
        sellInterval7: strategy.preference.sellInterval7,
        profitAlone: strategy.preference.profitAlone,
      }}
      validationSchema={Yup.object({
        strategyName: Yup.string().required("Required"),
        
      })}
      onSubmit={async (values) => {
        console.log("====================================");
        console.log("values", values);
        console.log("====================================");
        alert(JSON.stringify(values, null, 2));
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form className={styles.form}>
            <Flex width="calc(100% - 20px)" flexDirection="column" gap={2}>
              <CommonDcaForm styles={styles} />
              <CSwitch
                classname={styles.select}
                label={"停止时卖出"}
                onChange={(_, value) => {
                  setFieldValue("stopAndSell7", value);
                }}
                isChecked={values.stopAndSell7}
                name="stopAndSell7"
              />
              <CSwitch
                classname={styles.select}
                label={"止盈是否独立"}
                onChange={(_, value) => {
                  setFieldValue("profitAlone", value);
                }}
                isChecked={values.profitAlone}
                name="profitAlone"
                description="每一笔DCA订单的止盈是否独立计算，如果是true，会自动卖出"
              />
              <Flex alignItems="center" gap={2} mt={4}>
                <CButton
                  name="保存"
                  type="submit"
                  onClick={async () => {
                    console.log("values", values);

                    const payload: UpdateStrategyParams<DcaExampleType> = {
                      strategyId: strategy.id,
                      strategyName: values.strategyName,
                      repeatIntervalMsMin: values.repeatIntervalMsMin,
                      repeatIntervalMsMax: values.repeatIntervalMsMax,
                      preference: {
                        priceLow7: Number(values.priceLow7),
                        priceHigh7: Number(values.priceHigh7),
                        firstPrice7: Number(values.firstPrice7),
                        priceGap7: Number(values.priceGap7),
                        orderNum7: Number(values.orderNum7),
                        totalAmount7: Number(values.totalAmount7),
                        profitGap7: Number(values.profitGap7),
                        stopAndSell7: values.stopAndSell7,
                        profitAlone: !!values.profitAlone,
                        sellOrderNum7: Number(values.sellOrderNum7),
                        sellInterval7: Number(values.sellInterval7),
                      },
                    };
                    try {
                      const result = await strategiesApi.updateStrategyV2(payload);
                      message.success("更新成功");
                      onUpdated && onUpdated();
                    } catch (error) {
                      message.detailedError(<ErrorMessage error={error} />);
                    }
                  }}
                />
              </Flex>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
}
