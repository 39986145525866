import React, { useEffect, useRef } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import { Flex, useColorMode, Text } from "@chakra-ui/react";

let tvScriptLoadingPromise: Promise<any>;

declare global {
  interface Window {
    TradingView: any;
  }
}

type Prop = {
  cexName: string;
  pairName: string;
  isPerpetual?: boolean;
};

export default function TradingViewWidget(props: Prop) {
  const { pairName: symbol, cexName, isPerpetual } = props;
  const { colorMode } = useColorMode();
  const symbolString = isPerpetual ? `${cexName.toUpperCase()}:${symbol}.P` : `${cexName.toUpperCase()}:${symbol}`;
  const onLoadScriptRef: any = useRef();

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

    return () => {
      onLoadScriptRef.current = null;
      return;
    };

    function createWidget() {
      if (document.getElementById("tradingview_85bb6") && "TradingView" in window) {
        new window.TradingView.widget({
          timezone: "Asia/Shanghai",
          autosize: true,
          symbol: symbolString,
          interval: "15",
          theme: colorMode,
          style: "1",
          locale: "en",
          enable_publishing: false,
          save_image: false,
          hide_legend: true,
          hide_top_toolbar: false,
          hide_side_toolbar: false,
          allow_symbol_change: false,
          container_id: "tradingview_85bb6",
        });
      }
    }
  }, [symbolString, colorMode]);

  return (
    <Flex width="100%" flexDirection="column" minHeight="400px">
      <Text fontWeight={500}>{symbolString}</Text>
      <div className={classNames("tradingview-widget-container", styles.container)}>
        <div id="tradingview_85bb6" style={{ height: "100%", width: "100%" }} />
      </div>
    </Flex>
  );
}
