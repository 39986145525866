import { Box, Image, Text } from "@chakra-ui/react";
import tradingIcon from "../assets/image/trading.png";

export default function NotFound() {
  return (
    <Box maxWidth="500px" margin="auto">
      <Image src={tradingIcon} alt="trading" />
      <Text textAlign="center">Under development...</Text>
    </Box>
  );
}
