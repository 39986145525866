import {
  Flex,
  Input,
  InputGroup,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  Divider,
  Tooltip,
} from "@chakra-ui/react";
import { useFormik, FormikProvider } from "formik";
import { MarginType, OrderType, TradeDirection } from "src/api/types";
import CButton from "src/component/CButton/CButton";
import styles from "./index.module.scss";
import CInput from "src/component/CInput/CInput";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import CField from "src/component/CField/CField";
import OpenPositionAlert from "./OpenPositionAlert";
import { useMessage } from "src/hook/useMessage";
import { useExchangeInfoStore } from "src/store";
import { cbPerpApi } from "src/api";
import * as Yup from "yup";
import { ErrorMessage } from "src/utils/ErrorMessage";
import { QuestionIcon } from "@chakra-ui/icons";
import Slider from "rc-slider";

export default function MarketOpenPosition(props: {
  defaultLeverage: number;
  hasPosition: boolean;
  marketPrice: string;
  marginType: MarginType;
  positionSide: boolean;
  setLeverage: Dispatch<SetStateAction<number>>;
  setMarginType: Dispatch<SetStateAction<MarginType>>;
  balance: number;
}) {
  const { defaultLeverage, hasPosition, marketPrice, marginType, setLeverage, setMarginType, positionSide, balance } =
    props;

  const message = useMessage();
  const store = useExchangeInfoStore();
  const { selectedExchangeKey, selectedCbPerpPair: selectedPair, selectedExchangeAccountId } = store;

  const [tabIndex, setTabIndex] = useState(0);
  const [tradeDirection, setTradeDirection] = useState<TradeDirection>(TradeDirection.Buy);
  const orderType = tabIndex === 0 ? OrderType.LIMIT : OrderType.MARKET;

  const [isUpdating, setIsUpdating] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null); // 防抖定时器

  const priceEachTicket = selectedPair?.base === "BTC" ? 100 : 10;

  const handleSetLeverage = (value: string) => {
    // Allow the input to be empty
    if (value === "") {
      setLeverage(0); // or setLeverage(null) if you prefer
      return;
    }

    // Only allow digits
    if (!/^\d+$/.test(value)) {
      return;
    }

    // Remove leading zeros
    const sanitizedValue = value.replace(/^0+/, "") || "0";

    // Parse the sanitized value to an integer
    const leverageNumber = parseInt(sanitizedValue, 10);

    // 2024.11.26 老板只允许开 1 倍
    if (leverageNumber !== 1) {
      setLeverage(1);
    }
  };

  const handleToggleMarginType = async () => {
    if (!marginType || !selectedPair) {
      return;
    }
    const oldMarginType = marginType;
    const newMarginType = marginType === MarginType.Isolated ? MarginType.Crossed : MarginType.Isolated;
    setMarginType(newMarginType);
    try {
      await cbPerpApi.requestSetMarginType({
        exchangeAccountId: selectedExchangeAccountId,
        exchangeName: selectedExchangeKey,
        symbol: selectedPair.symbol,
        marginType: newMarginType,
      });
      message.success("设置保证金模式成功");
    } catch (error) {
      setMarginType(oldMarginType);
      message.error("设置保证金模式失败");
    }
  };

  const handleSetTabIndex = (index: number) => {
    setTabIndex(index);
    // 切换标签时重置 openPrice
    formik.setFieldValue("openPrice", index === 0 ? "" : marketPrice);
  };

  // Open alert dialog
  const {
    isOpen: isOpenPositionAlertOpen,
    onClose: onCloseOpenPositionAlert,
    onOpen: onOpenOpenPositionAlert,
  } = useDisclosure();

  const validationSchema = Yup.object({
    openPrice:
      tabIndex === 0
        ? Yup.number().typeError("必须为数字").min(0, "必须为正数").required("开仓价格是必填项")
        : Yup.number().typeError("必须为数字").min(0, "必须为正数"),
    amount: Yup.number().typeError("必须为数字").min(1, "必须为正数").required("开仓数量是必填项"),
    coinAmount: Yup.number().typeError("必须为数字").min(0, "必须为正数"),
  }).test("amount-or-coinAmount", "必须填写开仓数量或开仓币数量", function (values) {
    const { amount, coinAmount } = values;
    // 检查是否至少填写了一个字段
    if ((!amount || amount <= 0) && (!coinAmount || coinAmount <= 0)) {
      return this.createError({
        path: "amount",
        message: "必须填写开仓张量或开仓币数量",
      });
    }

    if (coinAmount) {
      if (coinAmount > balance) {
        console.log("firsttest", coinAmount, balance);

        return this.createError({
          path: "coinAmount",
          message: "开仓量超过持币数",
        });
      }
    }

    return true;
  });

  const formik = useFormik({
    initialValues: {
      openPrice: tabIndex === 0 ? "" : marketPrice,
      amount: "",
      coinAmount: "",
    },
    validationSchema,
    onSubmit: () => {
      onOpenOpenPositionAlert();
    },
    validateOnBlur: true,
    validateOnChange: true,
  });

  const { values, handleChange, handleSubmit, validateForm, setTouched, setFieldValue } = formik;

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isUpdating) return;
    setIsUpdating(true);

    const value = e.target.value;
    setFieldValue("amount", value);

    // 防抖处理
    if (debounceTimeout) clearTimeout(debounceTimeout);
    const timeout = setTimeout(() => {
      if (value === "" || isNaN(Number(value))) {
        setFieldValue("coinAmount", "");
        setIsUpdating(false);
        return;
      }

      const amountNum = parseFloat(value);
      const marketPriceNum = parseFloat(marketPrice);

      if (!isNaN(amountNum) && marketPriceNum) {
        const calculatedCoinAmount = (amountNum * priceEachTicket) / marketPriceNum;
        setFieldValue("coinAmount", calculatedCoinAmount.toFixed(2));
      } else {
        setFieldValue("coinAmount", "");
      }

      setIsUpdating(false);
    }, 100); // 延时100ms执行防抖
    setDebounceTimeout(timeout);
  };

  const handleCoinAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isUpdating) return;
    setIsUpdating(true);

    const value = e.target.value;
    setFieldValue("coinAmount", value);

    // 防抖处理
    if (debounceTimeout) clearTimeout(debounceTimeout);
    const timeout = setTimeout(() => {
      if (value === "" || isNaN(Number(value))) {
        setFieldValue("amount", "");
        setIsUpdating(false);
        return;
      }

      const coinAmountNum = parseFloat(value);
      const marketPriceNum = parseFloat(marketPrice);
      const leverageNum = defaultLeverage;

      if (!isNaN(coinAmountNum) && marketPriceNum && leverageNum !== 0) {
        const calculatedAmount = (coinAmountNum * marketPriceNum) / priceEachTicket;
        setFieldValue("amount", Math.floor(calculatedAmount));
      } else {
        setFieldValue("amount", "");
      }

      setIsUpdating(false);
    }, 100); // 延时100ms执行防抖
    setDebounceTimeout(timeout);
  };

  const handleTogglePositionSideType = async () => {
    if (positionSide == null || !selectedPair) {
      return;
    }

    const newPositionSideType = !positionSide;

    try {
      const response = await cbPerpApi.requestSwitchPositionSide({
        exchangeAccountId: selectedExchangeAccountId,
        exchangeName: selectedExchangeKey,
        both: newPositionSideType,
      });

      if (response.code === 0) {
        message.success("设置仓位模式成功");
      } else {
        const errorMsg = response.msg || "未知错误";
        message.error(`设置仓位模式失败: ${errorMsg}`);
      }
    } catch (error) {
      message.error("设置仓位模式失败");
    }
  };

  const handleSliderChange = (value: number) => {
    // 当滑块值变化时，更新 uAmount 和 amount
    setFieldValue("coinAmount", value.toFixed(2));

    const coinAmountNum = value;
    const marketPriceNum = parseFloat(marketPrice);

    if (!isNaN(coinAmountNum) && priceEachTicket) {
      // 根据 uAmount 计算 amount
      const calculatedAmount = (coinAmountNum * marketPriceNum) / priceEachTicket;
      setFieldValue("amount", Math.floor(calculatedAmount));
    }
  };

  const marks = balance
    ? {
        [balance / 4]: "25%",
        [balance / 2]: "50%",
        [(balance * 3) / 4]: "75%",
      }
    : {};

  // 表单字段组件
  const fieldComponent = (openPriceInput: JSX.Element | null) => {
    return (
      <Flex flexDir="column" gap={2} mt={4} width="100%" justifyContent="space-between">
        <Flex justifyContent={"space-between"} width={"100%"} alignItems={"center"} flexDir={"column"} gap={4}>
          <Flex alignItems="center" width="100%" gap={2} justifyContent={"center"}>
            <Text width="fit-content" whiteSpace="nowrap">
              杠杆:
            </Text>
            <Tooltip label={"仅允许一倍开仓"} aria-label="A tooltip">
              <QuestionIcon cursor="pointer" />
            </Tooltip>
            <InputGroup size={"sm"} gap={2}>
              <Input
                className="text-input"
                onChange={(e) => handleSetLeverage(e.target.value)}
                type="number"
                min={1}
                max={1}
                step={1}
                value={defaultLeverage === 0 ? "" : defaultLeverage}
                placeholder="1"
                disabled={true}
              />
              {/* <Button onClick={handleRequestSetLeverage}>修改杠杆</Button> */}
            </InputGroup>
          </Flex>

          <Flex justifyContent={"space-between"} width={"100%"}>
            <Flex alignItems="center" width="100%" gap={2} justifyContent={"flex-start"}>
              <Text width="fit-content" whiteSpace="nowrap">
                保证金模式:
              </Text>
              <Text>全仓</Text>
              <Switch
                disabled={hasPosition}
                isChecked={marginType === MarginType.Isolated}
                onChange={handleToggleMarginType}
              />
              <Text>逐仓</Text>
            </Flex>
            <Flex alignItems="center" width="100%" gap={2} justifyContent={"flex-start"}>
              <Text width="fit-content" whiteSpace="nowrap">
                仓位模式:
                <Tooltip
                  label={"单向持仓允许每个合约只有一个方向的仓位，双向持仓支持同时持有多空仓位并进行对冲。"}
                  aria-label="A tooltip"
                >
                  <QuestionIcon cursor="pointer" />
                </Tooltip>
              </Text>
              <Text>单向</Text>
              <Switch disabled={hasPosition} isChecked={!positionSide} onChange={handleTogglePositionSideType} />
              <Text>双向</Text>
            </Flex>
          </Flex>
        </Flex>
        <Divider borderStyle="dashed" borderColor="gray.300" borderWidth="1px" my={2}></Divider>
        {openPriceInput}
        <Divider borderStyle="dashed" borderColor="gray.300" borderWidth="1px" my={2}></Divider>

        <Flex alignItems="flex-start" gap={2} justifyContent="flex-start" flexDir={"column"}>
          <CInput
            classname={styles.inputField}
            label="开仓数量"
            name="amount"
            type="number"
            onChange={handleAmountChange}
            rightIndicator="张"
            description="币本位按张开单，BTC 一张 100U，其他 10U"
          />
          <Flex justifyContent={"space-between"} width={"100%"} gap={2}>
            <CInput
              classname={styles.inputField}
              label="开仓币数"
              name="coinAmount"
              type="number"
              onChange={handleCoinAmountChange}
              value={values.coinAmount}
              rightIndicator="币"
              description="根据市价折算"
            />
            <Slider
              min={0}
              max={Number(balance)} // Slider max is position size
              value={Number(values.coinAmount)}
              onChange={handleSliderChange} // Sync slider with amount
              step={Number(balance) / 100}
              marks={marks} // Percentage marks
            />
          </Flex>
        </Flex>

        <Flex alignItems="center" gap={2} mt={4} width="100%">
          <CButton
            type="button"
            name="开多"
            colorScheme="teal"
            onClick={async () => {
              // 标记所有字段为已触碰，以显示错误
              await setTouched({
                openPrice: true,
                amount: true,
                coinAmount: true,
              });

              // 验证表单
              const formErrors = await validateForm();
              if (Object.keys(formErrors).length === 0) {
                // 直接提交表单，打开模态框
                setTradeDirection(TradeDirection.Buy);
                handleSubmit();
              }
            }}
          />
          <CButton
            type="button"
            name="开空"
            colorScheme="red"
            onClick={async () => {
              // 标记所有字段为已触碰，以显示错误
              await setTouched({
                openPrice: true,
                amount: true,
                coinAmount: true,
              });

              // 验证表单
              const formErrors = await validateForm();
              if (Object.keys(formErrors).length === 0) {
                // 直接提交表单，打开模态框
                setTradeDirection(TradeDirection.Sell);
                handleSubmit();
              }
            }}
          />
        </Flex>

        <OpenPositionAlert
          isOpen={isOpenPositionAlertOpen}
          onClose={onCloseOpenPositionAlert}
          price={orderType === OrderType.LIMIT ? Number(values.openPrice) : Number(marketPrice)}
          margin={Number(values.amount)}
          leverage={defaultLeverage}
          side={tradeDirection}
          orderType={orderType}
          coinAmount={values.coinAmount}
        />
      </Flex>
    );
  };

  return (
    <FormikProvider value={formik}>
      <form className={styles.openForm} onSubmit={handleSubmit}>
        <Flex flexDirection="column" gap={2}>
          <Tabs index={tabIndex} onChange={handleSetTabIndex}>
            <TabList>
              <Tab>限价开单</Tab>
              <Tab>市价开单</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                {fieldComponent(
                  <CInput
                    classname={styles.inputField}
                    label="开仓价格"
                    name="openPrice"
                    type="number"
                    onChange={handleChange}
                    rightIndicator="U"
                    description="设置限价开仓的价格"
                  />
                )}
              </TabPanel>
              <TabPanel>
                {fieldComponent(
                  <CInput
                    classname={styles.inputField}
                    label="开仓价格"
                    name="openPrice"
                    type="number"
                    value={marketPrice}
                    disable={true}
                    onChange={() => {}} // 禁用时不允许更改
                    rightIndicator="U"
                    description="市价开单时使用当前市场价格"
                  />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </form>
    </FormikProvider>
  );
}
