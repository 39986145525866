import { Flex, Text } from "@chakra-ui/react";
import {
  AutoSellExampleType,
  DcaExampleType,
  EatOrderExampleType,
  LowBuyHighSellExampleType,
  ReduceGapExampleType,
  SingleSideExampleType,
  StrategyResponse,
  StrategyType,
  VolumeExampleType,
} from "src/api/types";
import { useExchangeInfoStore } from "src/store";
import { getPairName } from "src/store/utils";
import { useExchangeAccounts } from "src/hook/useExchangeAccounts";
import CField from "src/component/CField/CField";
import { useMessage } from "src/hook/useMessage";
import VolumeMakingDetail from "./VolumeMakingDetail";
import SingleSideDetail from "./SingleSideDetail";
import EatOrderDetail from "./EatOrderDetail";
import LowBuyHighSellDetail from "./LowBuyHighSellDetail";
import AutoSellDetail from "./AutoSellDetail";
import DcaDetail from "./DcaDetail";
import ReduceGapDetail from "./ReduceGapDetail";

type Props = {
  strategy: StrategyResponse<
    | VolumeExampleType
    | SingleSideExampleType
    | EatOrderExampleType
    | LowBuyHighSellExampleType
    | AutoSellExampleType
    | DcaExampleType
    | ReduceGapExampleType
  >;
  onUpdated?: () => void;
};

export default function StrategyDetail(props: Props) {
  const { strategy, onUpdated } = props;
  const message = useMessage();
  const accounts = useExchangeAccounts();
  const pairs = useExchangeInfoStore((state) => state.spotPairs);
  const pair = pairs.find((pair) => pair.id === strategy.pairId);
  const exchangeAccount = accounts.allExchangeAccounts.find((account) => account.id === strategy.exchangeAccountId);

  const strategyType = strategy.type;

  const getComponent = () => {
    switch (strategyType) {
      case StrategyType.VolumeMaking:
        return <VolumeMakingDetail strategy={strategy as StrategyResponse<VolumeExampleType>} onUpdated={onUpdated} />;
      case StrategyType.SingleSide:
        return (
          <SingleSideDetail strategy={strategy as StrategyResponse<SingleSideExampleType>} onUpdated={onUpdated} />
        );
      case StrategyType.EatOrder:
        return <EatOrderDetail strategy={strategy as StrategyResponse<EatOrderExampleType>} onUpdated={onUpdated} />;
      case StrategyType.LowBuyHighSell:
        return (
          <LowBuyHighSellDetail
            strategy={strategy as StrategyResponse<LowBuyHighSellExampleType>}
            onUpdated={onUpdated}
          />
        );
      case StrategyType.AutoSell:
        return <AutoSellDetail strategy={strategy as StrategyResponse<AutoSellExampleType>} onUpdated={onUpdated} />;
      case StrategyType.Dca:
        return <DcaDetail strategy={strategy as StrategyResponse<DcaExampleType>} onUpdated={onUpdated} />;
      case StrategyType.ReduceGap:
        return <ReduceGapDetail strategy={strategy as StrategyResponse<ReduceGapExampleType>} onUpdated={onUpdated} />;
      default:
        return <Text>策略开发中...</Text>;
    }
  };
  return (
    <Flex width="100%" justifyContent="space-between">
      <Flex width="calc(100%)" flexDirection="column" gap={2} maxHeight="calc(100vh - 200px)" overflowY="scroll">
        <Flex alignItems="center" gap={2}>
          <Text>策略类型:</Text>
          <Text>{strategy.type}</Text>
        </Flex>
        <Flex alignItems="center" gap={2} mt={2}>
          <CField label={"交易对"} value={getPairName(pair?.base, pair?.quote)} />
        </Flex>
        <Flex alignItems="center" gap={2} mt={2}>
          <CField label={"交易所账户"} value={exchangeAccount?.alias} />
        </Flex>
        {getComponent()}
      </Flex>
    </Flex>
  );
}
