import { Flex } from "@chakra-ui/react";
import CInput from "../CInput/CInput";

export default function CommonReduceGapForm(props: { styles: any }) {
  const { styles } = props;

  return (
    <>
      <Flex alignItems="center" gap={2} mt={4}>
        <CInput classname={styles.inputField} label="策略名称" name="strategyName" />
      </Flex>

      <Flex>
        <CInput
          classname={styles.inputField}
          label="价差比例"
          name="diffInPrice8"
          type="number"
          description="[0%-2%]"
          rightIndicator="%"
        />
      </Flex>
      

       <Flex>
        <CInput classname={styles.inputField} label="重复间隔(最小值)" name="repeatIntervalMsMin" type="number" />
      </Flex>

      <Flex>
        <CInput classname={styles.inputField} label="重复间隔(最大值)" name="repeatIntervalMsMax" type="number" />
      </Flex>
    </>
  );
}
