import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  HStack,
  Text,
  useDisclosure,
  useRadioGroup,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { strategiesApi } from "src/api";
import { CreateStrategyParams, StrategyKey, StrategyType, VolumeExampleType } from "src/api/types";
import CButton from "src/component/CButton/CButton";
import CRadioCard from "src/component/CRadioCard";
import CSelect from "src/component/CSelect";
import { useExchangeInfoStore } from "src/store";
import { getPairName } from "src/store/utils";
import * as Yup from "yup";
import styles from "./index.module.scss";
import CInput from "src/component/CInput/CInput";
import { useState } from "react";
import { useExchangeAccounts } from "src/hook/useExchangeAccounts";
import React from "react";
import CommonVolumeForm from "src/component/StrategyForms/CommonVolumeForm";
import CSwitch from "src/component/CSwitch/CSwitch";

export default function VolumeForm() {
  const store = useExchangeInfoStore();
  const exchanges = useExchangeAccounts();
  const [selectedExchangeAccountId, setSelectedExchangeAccountId] = useState<number | null>(null);

  const exchangeAccountOptions = exchanges.myExchangeAccounts.map((account) => {
    const exchange = store.exchanges.find((exchange) => exchange.id === account.exchangeId);
    const displayName = `${exchange ? exchange.name : "unknown"}: ${account.alias}`;
    return { value: account.id, label: displayName };
  });

  const pairOptions = store.spotPairs.map((pair) => ({
    value: pair.id,
    label: getPairName(pair.base, pair.quote),
  }));

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  return (
    <Formik
      initialValues={{
        strategyName: "",
        pair: "",
        exchangeAccount: "",
        minBaseAmount: "",
        maxBaseAmount: "",
        hourlyAmountLimit: "",
        refillBuyPert: "",
        refillSellPert: "",
        refillLimit: "",
        refillHourlyLimit: "",
        // repeatIntervalMsMin: "10000",
        repeatIntervalMsMax: "20000",
        repeatIntervalMsMin: "10000",

        // buySellRand: number; // default 0.5
        // execRand: number; // default 0
        // rangeMin: number; // default 1
        // rangeMax: number; // default 1
        buySellRand: "50",
        execRand: "0",
        rangeMin: "1",
        rangeMax: "1",
        priceGap1: "",
        priceRand1: false,
      }}
      validationSchema={Yup.object({
        minBaseAmount: Yup.number().required("Required"),
        maxBaseAmount: Yup.number().required("Required"),
        hourlyAmountLimit: Yup.number().required("Required"),
        refillBuyPert: Yup.number().required("Required"),
        refillSellPert: Yup.number().required("Required"),
        refillLimit: Yup.number().required("Required"),
        refillHourlyLimit: Yup.number().required("Required"),
        pair: Yup.object().required("Required"),
        exchangeAccount: Yup.object().required("Required"),

        buySellRand: Yup.number().required("Required"),
        execRand: Yup.number().required("Required"),
        rangeMin: Yup.number().required("Required"),
        rangeMax: Yup.number().required("Required"),
        priceGap1: Yup.number().required("Required"),
      })}
      onSubmit={async (values) => {
        const payload: CreateStrategyParams<VolumeExampleType> = {
          strategyName: values.strategyName,
          pairId: Number((values.pair as any).value),
          exchangeAccountId: Number((values.exchangeAccount as any).value),
          type: StrategyType.VolumeMaking,
          repeatIntervalMsMin: Number(values.repeatIntervalMsMin),
          repeatIntervalMsMax: Number(values.repeatIntervalMsMax),

          preference: {
            type: StrategyType.VolumeMaking,
            minBaseAmount: Number(values.minBaseAmount),
            maxBaseAmount: Number(values.maxBaseAmount),
            hourlyAmountLimit: Number(values.hourlyAmountLimit),
            refillBuyPert: Number(values.refillBuyPert),
            refillSellPert: Number(values.refillSellPert),
            refillLimit: Number(values.refillLimit),
            refillHourlyLimit: Number(values.refillHourlyLimit),

            buySellRand: Number(values.buySellRand),
            execRand: Number(values.execRand),
            rangeMin: Number(values.rangeMin),
            rangeMax: Number(values.rangeMax),
            priceGap1: Number(values.priceGap1),
            priceRand1: values.priceRand1,
          },
        };
        try {
          const result = await strategiesApi.createVolumeStrategy(payload);
          window.location.href = `/strategies?tab=${StrategyKey.Volume}`;
        } catch (error) {
          alert("创建失败");
        }
      }}
    >
      {({ values, setFieldValue, submitForm }) => {
        return (
          <>
            <Form className={styles.form}>
              <Flex width="calc(100% - 20px)" flexDirection="column" gap={2}>
                <Flex alignItems="center" gap={2} mt={4}>
                  <CSelect
                    classname={styles.select}
                    label={"交易所账户"}
                    placeholder="交易所账户"
                    options={exchangeAccountOptions}
                    onChange={(value) => {
                      setFieldValue("exchangeAccount", value);
                      setSelectedExchangeAccountId((value as any).value);
                    }}
                  />
                </Flex>
                <Flex alignItems="center" gap={2} mt={2}>
                  <CSelect
                    classname={styles.select}
                    label={"交易对"}
                    placeholder="交易对"
                    options={pairOptions}
                    onChange={(value) => {
                      setFieldValue("pair", value);
                    }}
                  />
                </Flex>
                <CommonVolumeForm styles={styles} />
                <Flex alignItems="center" gap={2} mt={4} width="100%" justifyContent="space-between">
                  <CSwitch
                    label={"随机价格"}
                    onChange={setFieldValue}
                    description="开启的话，在卖1和买1之间随机价格。不开启则取中间价"
                    isChecked={values.priceRand1}
                    name="priceRand1"
                  />
                </Flex>
                <Flex alignItems="center" gap={2} mt={4}>
                  <CButton name="确认创建" onClick={onOpen} />
                </Flex>
              </Flex>
            </Form>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    确认创建
                  </AlertDialogHeader>

                  <AlertDialogBody>确定立即创建机器人吗？</AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      取消
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        submitForm();
                      }}
                      ml={3}
                    >
                      确定
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </>
        );
      }}
    </Formik>
  );
}
