import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { accountApi, userApi } from "src/api";
import { useMessage } from "src/hook/useMessage";
import { setRoles, setToken, setUser } from "src/store/utils";

export default function Login() {
  const message = useMessage();
  const navigate = useNavigate();

  function validateName(value) {
    let error;
    if (!value) {
      error = "Name is required";
    }
    return error;
  }

  function validatePassword(value) {
    let error;
    if (!value) {
      error = "Password is required";
    }
    return error;
  }

  const handleSubmit = async (values: { name: string; password: string }) => {
    try {
      const authResult = await userApi.login(values.name, values.password);
      const token = authResult.token;
      setToken(token);

      const roles = await accountApi.fetchRoles();
      setRoles(roles.map((role) => role.type));

      const me = await accountApi.fetchMe();
      setUser({ ...authResult, username: me.username });

      message.success("Login success");
    } catch (error) {
      message.error("Login failed");
      throw error;
    }
  };

  return (
    <Flex width="100vw" height="100vh" justifyContent="center" alignItems="center">
      <Formik
        initialValues={{ name: "", password: "" }}
        onSubmit={(values, actions) => {
          handleSubmit(values)
            .then(() => {
              actions.setSubmitting(false);
              navigate("/");
            })
            .catch(() => {
              actions.setSubmitting(false);
            });
        }}
      >
        {(props) => (
          <Form>
            <Field name="name" validate={validateName}>
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.name && form.touched.name}>
                  <FormLabel>User Name</FormLabel>
                  <Input {...field} placeholder="name" />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="password" validate={validatePassword}>
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.password && form.touched.password}>
                  <FormLabel>Password</FormLabel>
                  <Input {...field} placeholder="password" type="password" />
                  <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Button mt={4} colorScheme="teal" isLoading={props.isSubmitting} type="submit">
              登录
            </Button>
          </Form>
        )}
      </Formik>
    </Flex>
  );
}
