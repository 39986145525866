import { Flex, Text } from "@chakra-ui/react";
import styles from "./index.module.scss";
import CButton from "src/component/CButton/CButton";
import { Field, Formik } from "formik";
import { Form, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import CInput from "src/component/CInput/CInput";
import { adminApi } from "src/api";

export default function UserCreate() {
  const navigate = useNavigate();
  return (
    <Flex width="100%" flexDirection="column">
      <Flex width="100%" height="100%" px={4} py={4}>
        <Formik
          initialValues={{
            username: "",
            password: "",
            roleTypes: [],
          }}
          validationSchema={Yup.object({})}
          onSubmit={async (values) => {
            await adminApi.createAccount(values);
            navigate('/users')
          }}
        >
          {({ submitForm }) => {
            return (
              <Form className={styles.formContainer}>
                <Flex width="100%" justifyContent="space-between" height="40px" alignItems="end">
                  <Text fontSize="1.2em" fontWeight="bold">
                    创建账户
                  </Text>
                  <CButton name={"保存"} type="submit" onClick={submitForm} />
                </Flex>
                <Flex flexWrap="wrap" width="100%">
                  <Flex flexWrap="wrap" gap={2} rowGap={2} justifyContent="space-around" width="calc(100% - 20px)">
                    <Flex
                      width="100%"
                      alignItems="center"
                      gap={2}
                      mt={4}
                      justifyContent="space-between"
                      flexDirection="column"
                    >
                      <CInput classname={styles.createAccountInputField} name="username" label={"用户名"} />
                      <CInput classname={styles.createAccountInputField} name="password" label={"密码"} />
                      <Flex justifyContent="space-between" className={styles.createAccountInputField}>
                        <Text>交易所</Text>
                        <Flex gap={2} role="group" aria-labelledby="checkbox-group">
                          <Flex gap={2}>
                            <Field type="checkbox" name="roleTypes" value="Admin" />
                            Admin
                          </Flex>
                          <Flex gap={2}>
                            <Field type="checkbox" name="roleTypes" value="Manager" />
                            Manager
                          </Flex>
                          <Flex gap={2}>
                            <Field type="checkbox" name="roleTypes" value="Trader" />
                            Trader
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Form>
            );
          }}
        </Formik>
      </Flex>
    </Flex>
  );
}
