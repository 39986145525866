import { Flex } from "@chakra-ui/react";
import styles from "./Layout.module.scss";
import Menu from "../Menu/Menu";
import Header from "../Header/Header";
import { userApi } from "src/api";
import { useQuery } from "@tanstack/react-query";
import { getUser } from "src/store/utils";
import { useExchangeInfo } from "src/hook/useExchangeInfo";

export default function Layout(props: { children: React.ReactNode }) {
  useQuery({
    queryKey: ["token"],
    queryFn: userApi.requestToken,
  });
  useExchangeInfo();
  const userInfo = getUser();
  if (!userInfo) {
    return <Flex>"请登录后访问"</Flex>;
  }

  return (
    <Flex className={styles.container}>
      <Menu />
      <Flex width="calc(100% - 240px)" minHeight="100vh" flexDirection="column">
        {/* <Header /> */}
        <Flex width="100%" height="100%" px={4} py={4}>
          {props.children}
        </Flex>
      </Flex>
    </Flex>
  );
}
