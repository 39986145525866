import { useExchangeInfoStore } from "src/store";
import CSelect from "../CSelect";
import styles from "./AccountSelect.module.scss";
import classNames from "classnames";
import { useExchangeAccounts } from "src/hook/useExchangeAccounts";

type AccountSelectProps = {
  classname?: string;
};

export default function AccountSelect(props: AccountSelectProps) {
  const store = useExchangeInfoStore();
  const exchangeAccounts = useExchangeAccounts();
  let selectableExchangeAccounts = exchangeAccounts.myExchangeAccounts;

  if (store.selectedExchangeKey || store.exchanges) {
    const selectedExchangeId = store.exchanges.find((exchange) => exchange.key === store.selectedExchangeKey)?.id;
    if (!!selectedExchangeId) {
      selectableExchangeAccounts = selectableExchangeAccounts.filter(
        (account) => account.exchangeId === selectedExchangeId
      );
    }
  }

  const options = selectableExchangeAccounts.map((account) => {
    return {
      value: String(account.id),
      label: account.alias || String(account.id),
    };
  });

  const defaultOption = options.find((option) => option.value === String(store.selectedExchangeAccountId));

  const handleChange = (selectedOption) => {
    store.setSelectedExchangeAccountId(selectedOption.value);
  };

  return (
    <CSelect
      classname={classNames(styles.container, props.classname)}
      label={"交易账户"}
      placeholder="选择账户"
      options={options}
      onChange={handleChange}
      defaultValue={defaultOption}
    />
  );
}
